import React, {Component} from "react";
import {trans} from "../../utils";
import {Col, Nav, NavItem, Row} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import PassportChangeTab from "./PassportChangeTab";
import InsuranceChangeTab from "./InsuranceChangeTab";
import AddressChangeTab from "./AddressChangeTab";
import LettersToBank from "./LettersToBank";

export class DocumentRequests extends Component {

    constructor(props) {
        super(props);

        this.state = {
            activeKey: 0,
        };
    }

    render() {
        return <div>
            <Tab.Container id={"DocumentRequests"} defaultActiveKey={0} activeKey={this.state.activeKey}
                           onSelect={(key) => {
                               this.setState({activeKey: key});
                           }}
            >
                <Row className="clearfix">
                    <Col>
                        <Nav bsClass="nav nav-tabs nav-justified">
                            <Nav.Item>
                                <Nav.Link eventKey={0} key={0}> {trans("document_changes.passports")} </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={1} key={1}> {trans("document_changes.insurances")} </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={2} key={2}> {trans("document_changes.addresses")} </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={3} key={3}> {trans("document_changes.letters_to_bank")} </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Tab.Content animation>

                            <Tab.Pane eventKey={0}>
                                <PassportChangeTab/>
                            </Tab.Pane>

                            <Tab.Pane eventKey={1}>
                                <InsuranceChangeTab/>
                            </Tab.Pane>

                            <Tab.Pane eventKey={2}>
                                <AddressChangeTab/>
                            </Tab.Pane>

                            <Tab.Pane eventKey={3}>
                                <LettersToBank/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    }
}

export default DocumentRequests;