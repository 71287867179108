const CREATED = "CREATED";
const SMS_CONFIRMATION_SUCCESS = "SMS_CONFIRMATION_SUCCESS";
const REJECTED_BY_OPERATOR = "REJECTED_BY_OPERATOR";
const APPROVED_BY_OPERATOR = "APPROVED_BY_OPERATOR";
const REJECTED_BY_ABS = "REJECTED_BY_ABS";
const PROCESSED_BY_ABS = "PROCESSED_BY_ABS";
const PROCESSING_BY_OPERATOR = "PROCESSING_BY_OPERATOR";
const REJECTED_BY_OPERATOR_REPETITION = "REJECTED_BY_OPERATOR_REPETITION";

export default {
    CREATED,
    SMS_CONFIRMATION_SUCCESS,
    REJECTED_BY_OPERATOR,
    REJECTED_BY_OPERATOR_REPETITION,
    APPROVED_BY_OPERATOR,
    REJECTED_BY_ABS,
    PROCESSED_BY_ABS,
    PROCESSING_BY_OPERATOR,
};