import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import EOsagoStats from "./EOsagoStats";
import B2PReport from "./B2PReport";
import DocumentRequestsReport from "./DocumentRequestsReport";
import CascoStats from "./CascoStats";
import AppUsageReport from "./AppUsageReport";

class Reports extends React.Component{

    render(){
        return <div>
            <Tabs defaultActiveKey={0} id={"reports"}>
                <Tab eventKey={0} title={"ЕОСАГО"}>
                    <EOsagoStats/>
                </Tab>
                <Tab eventKey={1} title={"Best2Pay"}>
                    <B2PReport/>
                </Tab>
                <Tab eventKey={2} title={"Заявки"}>
                    <DocumentRequestsReport/>
                </Tab>

                <Tab eventKey={3} title={"КАСКО"}>
                    <CascoStats/>
                </Tab>
                <Tab eventKey={4} title={"Использование"}>
                    <AppUsageReport/>
                </Tab>
            </Tabs>
        </div>
    }
}


export default Reports;