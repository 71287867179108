/**
 * Created by gleb on 5/17/17.
 */

import React from "react";
import PropTypes from "prop-types";

import {ListGroup, ListGroupItem} from "react-bootstrap";
import {trans} from "../../utils/index";
import {dateFormatter, moneyFormatter} from "../../utils/formatters";

function listGroupItem(client, key, value = null) {
    if (value === null && (client[key] === null || client[key] === '')) {
        return;
    }
    return <ListGroup.Item key={key}>
        <b>{trans('users.' + key)}:</b>  {value === null ? client[key] : value}
    </ListGroup.Item>


}

const ClientInfo = ({fields, client, account}) => (
    <ListGroup>
        {
            [
                listGroupItem(client, "FIO"),
                listGroupItem(client, "lock_info"),
                listGroupItem(client, "BIRTH_DATE", dateFormatter(client.BIRTH_DATE)),
                listGroupItem(client, "EMAIL"),
                listGroupItem(client, 'MOBILE'),
                listGroupItem(client, 'MOBILE_REAL'),
                listGroupItem(client, "CLIENT"),
                listGroupItem(client, "passport_series"),
                listGroupItem(client, "passport_number"),
                listGroupItem(client, "REG_ADDR"),
                listGroupItem(client, "LIFE_ADDR"),
                listGroupItem(client, "VERSION"),
                listGroupItem(client, "DEVICE"),
                listGroupItem(client, "TOTAL_OVERDUE", moneyFormatter(client.TOTAL_OVERDUE)),
                listGroupItem(client, "TOTAL_PENALTY", moneyFormatter(client.TOTAL_PENALTY)),
                listGroupItem(client, "last_mobapp_login"),
                listGroupItem(client, "last_web_login"),
            ]
        }
        <ListGroup.Item key='ACC_NUMBER'>
            <b>{trans('users.ACC_NUMBER')}: </b>
            {account === null ? 'Номер аккаунта не указан'
                : account['ACC_NUMBER']}</ListGroup.Item>
    </ListGroup>
);

ClientInfo.propTypes = {
    fields: PropTypes.array.isRequired,
    client: PropTypes.object.isRequired,
};

export default ClientInfo;
