import React, {Component} from 'react';
import DocumentChangeStatus from "../constants/DocumentChangeStatus";
import {Button, FormControl, FormSelect, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import {trans} from "../utils";
import {closeChangeHandleModal, rejectChange} from "../actions/document_requests";
import DocumentRequestTypes from "../constants/DocumentRequestTypes";
import {connect} from "react-redux";
import InsuranceRejectReasons from "../constants/InsuranceRejectReasons";

class InsuranceRejectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reason: InsuranceRejectReasons.REJECTED_BY_OPERATOR_UNREADABLE,
        };

        this.type = DocumentRequestTypes.INSURANCE_TYPE;

        this.close = ::this.close;
        this.handleRejectBtnClick = ::this.handleRejectBtnClick;
    }

    close() {
        this.props.onCloseClick()
    }

    handleRejectBtnClick() {
        this.props.dispatch(rejectChange(this.type, this.props.request_id, this.props.comment, this.state.reason));
        this.props.dispatch(closeChangeHandleModal(this.type));
        this.close();
    }

    render() {
        return (
            <Modal show={true} bsSize={'lg'} onHide={this.close} dialogClassName={'reject-insurance-modal'}>
                <ModalHeader closeButton>
                    <ModalTitle>Отклонить заявку клиента</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    Выберите причину отклонения заявки.<br/>
                    <FormSelect
                        key={'reason'}
                        componentClass="select"
                        onChange={(e) => {
                            this.setState({reason: e.target.value});
                        }}
                    >
                        <option key={InsuranceRejectReasons.REJECTED_BY_OPERATOR_UNREADABLE}
                                value={InsuranceRejectReasons.REJECTED_BY_OPERATOR_UNREADABLE}>
                            {trans('insurance_reasons.' + InsuranceRejectReasons.REJECTED_BY_OPERATOR_UNREADABLE)}
                        </option>
                        <option key={InsuranceRejectReasons.REJECTED_BY_OPERATOR_ABS_PROGRAM}
                                value={InsuranceRejectReasons.REJECTED_BY_OPERATOR_ABS_PROGRAM}>
                            {trans('insurance_reasons.' + InsuranceRejectReasons.REJECTED_BY_OPERATOR_ABS_PROGRAM)}
                        </option>
                        <option key={InsuranceRejectReasons.REJECTED_BY_OPERATOR_REPETITION}
                                value={InsuranceRejectReasons.REJECTED_BY_OPERATOR_REPETITION}>
                            {trans('insurance_reasons.' + InsuranceRejectReasons.REJECTED_BY_OPERATOR_REPETITION)}
                        </option>
                        <option key={InsuranceRejectReasons.REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS}
                                value={InsuranceRejectReasons.REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS}>
                            {trans('insurance_reasons.' + InsuranceRejectReasons.REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS)}
                        </option>
                    </FormSelect>
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.close}>Закрыть</Button>
                    <Button className="btn btn-danger" onClick={this.handleRejectBtnClick}>Подтвердить
                        отклонение</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default connect()(InsuranceRejectModal);