import React, {Component} from "react";
import {connect} from "react-redux"
import DocumentChangeStatus from "../../constants/DocumentChangeStatus";
import RequestTab from "./RequestTab";
import InsuranceChangeHandleModal from "../InsuranceChangeHandleModal";
import {trans} from "../../utils";
import {closeChangeHandleModal, loadInsuranceCompanies} from "../../actions/document_requests";
import DocumentRequestTypes from "../../constants/DocumentRequestTypes";

class InsuranceChangeTab extends Component {
    constructor(props) {
        super(props);

        this.policyTypes = [{id: 'KASKO', name: 'КАСКО'}];
        this.renderModalComponent = ::this.renderModalComponent;
    }

    componentDidMount() {
        this.props.dispatch(loadInsuranceCompanies());
    }

    static getColumns() {
        return [
            {field: '#', props: {isKey: true, width: '40px'}},
            {field: 'CLIENT', props: {width: '120px'}},
            {field: 'FIO', props: {width: '250px'}},
            {field: 'CONTRACT_NUMBER', props: {width: '100px'}},
            {field: 'PRODUCT_NAME'},
            {field: 'VIN'},
            {field: 'status_text', props: {width: '150px'}},
            {field: 'created_at', props: {width: '170px', dataSort: true}},
            {field: 'created_by', props: {width: '170px', dataSort: false}},
            {field: 'approved_by', props: {width: '170px', dataSort: false}},
        ];
    }

    static getDataTransformer() {
        return item =>
            Object.assign(
                item.insurance_data,
                {
                    ...item.credit_info,
                    'request_id': item.request_id,
                    'status': item.status,
                    'status_text': trans('document_changes_status.' + item.status),
                    'created_at': item.created_at,
                    'created_by': item.created_by,
                    'approved_by': item.approved_by,
                });
    }

    renderModalComponent() {
        return (
            <InsuranceChangeHandleModal
                onCloseClick={() => this.props.dispatch(closeChangeHandleModal(DocumentRequestTypes.INSURANCE_TYPE))}
                row={this.props.row}
                companies={this.props.insuranceCompanies.data.companies}
                policyTypes={this.policyTypes}
            />
        );
    }

    render() {
        return (
            <RequestTab
                type='insurance'
                statusOptions={DocumentChangeStatus.CREATED}
                transPath={'insurance_changes.'}
                columns={InsuranceChangeTab.getColumns()}
                dataTransformer={InsuranceChangeTab.getDataTransformer()}
                modalComponent={this.renderModalComponent()}
            />
        );
    }
}

function mapStateToProps(state) {
    let insuranceModal = state.showChangeHandleModal[DocumentRequestTypes.INSURANCE_TYPE] || {row: {}};

    let insuranceCompanies = state.updateInsuranceCompanies || {
        isFetching: true,
        data: {
            companies: [],
        },
    };

    return {
        row: insuranceModal.row,
        insuranceCompanies: insuranceCompanies,
    };
}

export default connect(mapStateToProps)(InsuranceChangeTab);