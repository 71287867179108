import React from "react";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import AddExtraAgreementModal from "./AddExtraAgreementModal";

class AddExtraAgreement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            showModal: false,
            onClientDataChanged: props.onClientDataChanged,
        };
    }

    render() {
        return <div>
            <Button
                style={{marginTop: '15px'}}
                onClick={() => this.setState((prevState, props) => {
                    return {
                        ...prevState,
                        showModal: true
                    };
                })}>Добавить дополнительное соглашение</Button>

            {this.state.showModal ? <AddExtraAgreementModal
                onCloseClick={() => this.setState((prevState, props) => {
                    return {
                        ...prevState,
                        showModal: false
                    };
                })}
                row={{
                    'FIO': this.state.user.client.FIO,
                    'CLIENT': this.state.user.client.CLIENT,
                    onClientDataChanged: this.state.onClientDataChanged,
                }}
            /> : <p/>
            }
        </div>
            ;
    }


}

function mapStateToProps(state) {
    return {
        row: {},
    };
}

export default connect(mapStateToProps)(AddExtraAgreement);