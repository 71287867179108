const PASSPORT_TYPE = 'passport';
const INSURANCE_TYPE = 'insurance';
const ADDRESS_TYPE = 'address';
const LETTER_TO_BANK_TYPE = 'letter_to_bank';

export function getRequestTypes() {
    return [
        PASSPORT_TYPE,
        INSURANCE_TYPE,
        ADDRESS_TYPE,
        LETTER_TO_BANK_TYPE,
    ];
}

export default {
    PASSPORT_TYPE,
    INSURANCE_TYPE,
    ADDRESS_TYPE,
    LETTER_TO_BANK_TYPE,
}