/**
 * Created by gleb on 5/12/17.
 */

import {errorPanel, infoPanel, okPanel} from "./panels";
import {axios_post} from "../utils/axios";
import {logout} from "./bank_person";

export function sendAdvertFile(base64) {
    return sendFile(base64, 'external/ad/update', 'Рекламные данные успешно обновлены');
}

export function sendPaymentPointsFile(base64) {
    return dispatch => {
        dispatch(infoPanel('Сервер начал обновлять точки оплаты'));
        sendFile(base64, 'external/payment_points/update', 'Точки оплаты успешно обновлены')(dispatch);
    }

}

export function sendWorkdayFile(base64) {
    return sendFile(base64, 'external/workdays/update', 'Рабочие дни успешно обновлены');
}

export function sendPushFile(base64) {
    return sendFile(base64, 'external/push_distribution/update', 'Push-уведомления успешно обновлены');
}

function sendFile(base64, addr, message) {
    return dispatch => {
        axios_post(addr, {
            content: base64
        })
        .then(() => {
            dispatch(okPanel(message));
        })
        .catch(error => {
            if(error.response.status === 403){
                dispatch(logout()); //force logout on 403
            }
            dispatch(errorPanel(error));
        })
    }
}