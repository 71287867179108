/**
 * Created by gleb on 5/17/17.
 */

import React from "react";
import PropTypes from "prop-types";

import {Accordion, AccordionHeader, AccordionItem, Card, CardGroup, ListGroup, ListGroupItem} from "react-bootstrap";
import {trans} from "../../utils/index";
import {creditStatusFormatter, dateFormatter, moneyFormatter, yesNoFormatter} from "../../utils/formatters";
import AccordionBody from "react-bootstrap/AccordionBody";
import BootstrapTable from "react-bootstrap-table-next";


function listGroupItem(credit, key, value = null) {
    if (value === null && (credit[key] === null || credit[key] === '')) {
        return;
    }
    return <ListGroupItem key={key}> <b>{trans('credits.' + key)}: </b>{value === null ? credit[key] : value}
    </ListGroupItem>
}


class CreditInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCreditPanel: this.props.activeCreditPanel
        };


    }

    static getPaymentHeaderColumn(field, props) {
        let formatExtraData = props?.formatExtraData ? {
            ...this.props?.formatExtraData
            } : {};
        formatExtraData['fieldName'] = field;

        return {
            ...props,
            dataField: field,
            key: field,
            headerAlign: 'center',
            text: trans('payments.' + field),
            formatter: function (cell, row, rowIndex, extra) {
                if(row.TYPE === 'REPAYMENT_HOLIDAY'){
                    return CreditInfo.formatRepaymentHolidays(cell, row, rowIndex, extra);
                }
                if(cell === null){
                    return ' ';
                }
                return props?.formatter ? props.formatter(cell,row,rowIndex,extra) : cell;
            },
            formatExtraData: formatExtraData
        }
    }

    static renderSchedulesTable(credit) {
        const currencyFormatExtraData = {'currency': credit.CUR};
        return <BootstrapTable data={credit['payments']} keyField='counter'
                                                                                        columns={[
                                   CreditInfo.getPaymentHeaderColumn('counter', {
                                       'width': '50',
                                       'isKey': true,
                                   }),
                                   CreditInfo.getPaymentHeaderColumn('STAT_PAY', {
                                       'width': '150'
                                   }),
                                   CreditInfo.getPaymentHeaderColumn('DATE_PAY',
                                       {
                                           'formatter': dateFormatter
                                       }),
                                   CreditInfo.getPaymentHeaderColumn('AMOUNT', {
                                       'formatter': moneyFormatter,
                                       'formatExtraData': currencyFormatExtraData
                                   }),
                                   CreditInfo.getPaymentHeaderColumn('R_AM_AFTER', {
                                       'formatter': moneyFormatter,
                                       'formatExtraData': currencyFormatExtraData,
                                   }),
                                   CreditInfo.getPaymentHeaderColumn('AM_BODY', {
                                       'formatter': moneyFormatter,
                                       'formatExtraData': currencyFormatExtraData
                                   }),
                                   CreditInfo.getPaymentHeaderColumn('AM_PERC', {
                                       'formatter': moneyFormatter,
                                       'formatExtraData': currencyFormatExtraData
                                   }),
                                   CreditInfo.getPaymentHeaderColumn('OVERDUE'),
                                   CreditInfo.getPaymentHeaderColumn('OVERDUE_AMOUNT', {
                                       'formatter': moneyFormatter,
                                       'formatExtraData': currencyFormatExtraData
                                   })
                               ]}>
        </BootstrapTable>
    }

    render() {
        const {credits, table, activeCreditPanel} = this.props;

        //    if (Number(activeCreditPanel) !== Number(this.state.activeCreditPanel)) {
        //        this.setState({"activeCreditPanel": Number(activeCreditPanel)});
        //   }

        return (
            <Accordion accordion
                       onSelect={(key, event) => {
                           const keyAsString = key[0].toString();
                           this.setState({"activeCreditPanel": this.state.activeCreditPanel === keyAsString ? false : keyAsString});
                       }}
                       activeKey={this.state.activeCreditPanel}
                       alwaysOpen
                       defaultActiveKey={credits.length > 1 ? false : this.state.activeCreditPanel}
            >
                {
                    credits.map((credit, key) =>
                        (
                            <AccordionItem
                                eventKey={credit.CRED_ID}
                                key={credit.CRED_ID}
                                title={key}>
                                <AccordionHeader
                                    style={{'font-size': '22px'}}>
                                    {credit.PRODUCT_NAME + " (" + credit.CONTRACT_NUMBER + ")"}
                                </AccordionHeader>
                                <AccordionBody>

                                    <div>
                                        <ListGroup>
                                            {
                                                [
                                                    listGroupItem(credit, "SUM_DOG", moneyFormatter(credit.SUM_DOG, [], credit.CUR)),
                                                    listGroupItem(credit, "CONTRACT_NUMBER"),
                                                    listGroupItem(credit, "DATE_OP", dateFormatter(credit.DATE_OP)),
                                                    listGroupItem(credit, "DATE_MAT", dateFormatter(credit.DATE_MAT)),
                                                    listGroupItem(credit, "DATE_CLS", credit.DATE_CLS !== '' ? dateFormatter(credit.DATE_CLS) : null),
                                                    listGroupItem(credit, "PRODUCT_NAME"),
                                                    listGroupItem(credit, "COUNT_PERIOD"),
                                                    listGroupItem(credit, "ALREADY_PAID_AMOUNT", moneyFormatter(credit.SUM_DOG - credit.REMAINING_BALANCE)),
                                                    listGroupItem(credit, "REMAINING_BALANCE", moneyFormatter(credit.REMAINING_BALANCE)),
                                                    listGroupItem(credit, "STATUS", creditStatusFormatter(credit.STATUS)),
                                                    listGroupItem(credit, "OVERDUE", moneyFormatter(credit.OVERDUE)),
                                                    listGroupItem(credit, "PENALTY", moneyFormatter(credit.PENALTY)),
                                                    listGroupItem(credit, "IS_LINE", yesNoFormatter(credit.IS_LINE)),
                                                    credit.IS_LINE ? listGroupItem(credit, "credit_line_amount", moneyFormatter(credit.credit_line_amount)) : null,

                                                ]
                                            }
                                        </ListGroup>


                                        <h3>Платежи:</h3>
                                        {CreditInfo.renderSchedulesTable(credit)}
                                    </div>
                                    <br/>
                                </AccordionBody>
                            </AccordionItem>

                        )
                    )
                }
            </Accordion>
        );
    }

    static formatRepaymentHolidays(cell, row, rowIndex, extra) {
        console.log(extra.fieldName);
        switch (extra.fieldName){
            case 'counter':
                return cell;
            case 'STAT_PAY':
                return <b>Кредитные каникулы</b>;
            case 'DATE_PAY':
                return <b>C {dateFormatter(row.repayment_holiday_info.from)}</b>;
            case 'AMOUNT':
                return <b>По {dateFormatter(row.repayment_holiday_info.to)}</b>;
            default: return ' ';
        }
    }
}


CreditInfo.propTypes = {
    credits: PropTypes.array.isRequired,
};

export default CreditInfo;
