/**
 * Created by anton on 5/4/17.
 */

import {applyMiddleware, compose, legacy_createStore as createStore} from "redux";
import {thunk as thunkMiddleware} from "redux-thunk";
import rootReducer from "./reducers";
import {loadFromLocalStorage} from "./actions/bank_person";

const configureStore = () => {
    let enhancers = [
        applyMiddleware(
            thunkMiddleware,
            // createLogger()
        )
    ];
    const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
    if (devTools) {
        enhancers.push(devTools);
    }
    const store = createStore(
        rootReducer,
        undefined,
        compose(...enhancers)
    );

    return initStore(store);
};

export default configureStore;


function initStore(store) {
    store.dispatch(loadFromLocalStorage());

    return store;
}