/**
 * Created by anton on 5/2/17.
 */

import React from 'react';
import Navbar from '../components/Navbar';
import AuthModal from '../components/AuthModal';
import {connect} from 'react-redux';
import AuthContainer from './AuthContainer';
import Panels from './Panels/index';
import {Button, FormLabel, Form, FormControl, FormGroup, ModalHeader} from 'react-bootstrap';
import {Outlet, Route, Routes, useLocation} from 'react-router-dom';
import * as person from '../actions/bank_person';

import './app.scss';
import Clients from "../components/Clients";
import Client from "../components/Client";
import DocumentRequests from "../components/requests/DocumentRequests";
import OtherTabs from "../components/OtherTabs";
import Reports from "../components/Reports";
import LoadingClient from "../components/LoadingClient";


class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false
        };
        this.location = props.location;
        this.navigate = props.navigate;

        this.authorize = ::this.authorize;
    }

    authorize(e) {
        e.preventDefault();
        this.props.dispatch(person.doAuthorize({
            login: this.login,
            password: this.password
        }));
        // this.close();
    }

    render() {
        return (
            <div>
                <Navbar onAuthClick={() => this.setState({showModal: true})} navigate={this.navigate}/>
                {
                    this.state.showModal &&
                    <AuthModal onCloseClick={() => this.setState({showModal: false})}/>
                }

                <div className="container">
                    <Panels panels={this.props.panels} dispatch={this.props.dispatch}/>
                </div>

                <div className="container">
                    <AuthContainer component={
                        <Routes>
                            <Route index={true} id={0} path="/clients" element={<Clients navigate={this.navigate}/>}/>
                            <Route id={5} path="/" element={<Clients navigate={this.navigate}/>}/>
                            <Route id={1} path="/clients/:id" element={<LoadingClient isBankEmployee={true}  navigate={this.navigate}/>}/>
                            <Route id={2} path='/document_requests' element={<DocumentRequests/>}/>
                            <Route id={3} path='/misc' element={<OtherTabs/>}></Route>
                            <Route id={4} path='/reports' element={<Reports/>}></Route>
                        </Routes>
                    }
                                   login={
                                       <div className="login-form">
                                           <ModalHeader>Введите авторизационные данные</ModalHeader>
                                           <Form onSubmit={this.authorize}>
                                               <FormGroup>
                                                   <FormLabel>Логин</FormLabel>
                                                   <FormControl
                                                       type="text"
                                                       autoFocus
                                                       onChange={(e) => {
                                                           this.login = e.target.value;
                                                       }}
                                                   />
                                               </FormGroup>
                                               <FormGroup>
                                                   <FormLabel>Пароль</FormLabel>
                                                   <FormControl
                                                       type="password"
                                                       required
                                                       onChange={(e) => {
                                                           this.password = e.target.value
                                                       }}
                                                   />
                                               </FormGroup>
                                               <Button type="submit" bsStyle="primary">Авторизоваться</Button>
                                           </Form>
                                       </div>
                                   }/>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        panels: state.panels || []
    }
}

export default connect(mapStateToProps)(App);
