
export const RESET_COORDINATES = 'RESET_COORDINATES';
export const DONE_RESETTING_COORDINATES = 'DONE_RESETTING_COORDINATES';

export function resetCoordinates(id) {
    return {
        type: RESET_COORDINATES,
        id: id,
    };
}

export function doneReset(id) {
    return {
        type: DONE_RESETTING_COORDINATES,
        id: id,
    }
}