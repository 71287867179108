/**
 * Created by anton on 4/14/17.
 */

import 'babel-polyfill';

import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import Router from './Router';
import configureStore from './configureStore';
import {RouterProvider} from "react-router-dom";
import {createRoot} from "react-dom/client";

let store = configureStore();

const root = createRoot(

    document.getElementById('root')
);

root.render(<Provider store={store}>
    <RouterProvider router={Router}/>
</Provider>
)