import React from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {errorPanel} from "../actions/panels";
import moment, {now} from "moment/moment";
import {requestCertificate} from "../actions/certificate_requests";
import Datetime from "react-datetime";
import {trans} from "../utils";
import {dateFormatter, formatDateInput} from "../utils/formatters";
import {connect} from "react-redux";

class CertificateRequest extends React.Component {

    constructor(props) {
        super(props);

        const client = props.user;
        let availableCertificates = {};
        for (let i in client.available_certificates) {
            let certificate = client.available_certificates[i];
            availableCertificates[certificate.type] = certificate;
            availableCertificates[certificate.type].credits = client.credits.filter(credit => certificate.credits.includes(Number.parseInt(credit.CRED_ID))).map(credit => {
                console.log('CREDIT', credit);
                credit.form_text = `${credit.PRODUCT_NAME} (к/д ${credit.CONTRACT_NUMBER})`
                return credit;
            });
        }

        const defaultDateBeginning = client.credits[0]?.DATE_OP ?? now();
        const defaultDateEnding = client.credits[0]?.DATE_CLS ?? client.credits[0]?.DATE_MAT ?? now();

        const firstCertificate = availableCertificates?.[client.available_certificates?.[0]?.type];
        this.state = {
            form_data: {
                CLIENT: client.client.CLIENT,
                EMAIL: client.client.EMAIL,
                date_beginning: moment(defaultDateBeginning).format('DD.MM.YYYY'),
                date_ending: moment(defaultDateEnding).format('DD.MM.YYYY'),
                credit: firstCertificate?.credits?.[0]?.['CRED_ID'] ?? null,
                account: firstCertificate?.accounts?.[0]?.['ACC_ID'] ?? null,
                certificate_type_id: firstCertificate?.type,
            },
            user: client,
            showModal: false,
            chosenCertificate: firstCertificate,
            available_certificates: availableCertificates ?? [],
        };
        this.createFormField = ::this.createFormField;
        this.handleAcceptBtnClick = ::this.handleAcceptBtnClick;
        this.validateFormData = ::this.validateFormData;
        this.createDatePeriodFields = ::this.createDatePeriodFields;
    }

    componentDidMount() {
    }


    validateFormData() {
        const {
            form_data
        } = this.state;

        const props = this.props;
        const error = function (error) {
            props.dispatch(errorPanel(error));
            return false;
        };

        const certType = this.state.form_data.certificate_type_id;
        if (!certType)
            return error("Необходимо выбрать тип справки");

        const certificate = this.state.available_certificates[certType];
        if (!certificate)
            return error("Справка данного типа недоступна для клиента");

        if (certificate.credits && certificate.credits.length > 0 && !form_data.credit)
            return error("Необходимо выбрать кредит");
        if (certificate.accounts && certificate.accounts.length > 0 && !form_data.account)
            return error("Необходимо выбрать счет");

        if (certificate.date_period) {
            const dateBeginning = moment(form_data.date_beginning, "DD.MM.YYYY");
            const dateEnding = moment(form_data.date_ending, "DD.MM.YYYY");
            if (!(dateBeginning.isValid()) || !(dateEnding.isValid()) || dateBeginning.isAfter(dateEnding)) {
                return error("Некорректный период для справки");
            }
        }
        return true;
    }

    handleAcceptBtnClick(e) {
        e.target.disabled = true;
        if (this.validateFormData()) {
            this.props.dispatch(requestCertificate(this.state.form_data,
                () => e.target.disabled = false));
        }
    }

    createDatePeriodFields(start, end) {

        let form = this;

        function createDateComponent(field) {
            let fieldComponent = (<Form.Control type="text" value={form.state.form_data[field]} readOnly={true}/>);

            if (!form.state.disabled) {
                fieldComponent = (<Datetime dateFormat="DD.MM.YYYY"
                                            timeFormat={false}
                                            locale={"ru"}
                                            value={form.state.form_data[field]}
                                            closeOnSelect={true}
                                            onChange={(e) => {
                                                let date = formatDateInput(e);
                                                form.setState((state) => ({
                                                    form_data: {
                                                        ...state.form_data,
                                                        [field]: date,
                                                    },
                                                }));
                                            }
                                            }
                                            readOnly={form.state.disabled}
                />);
            }
            return fieldComponent;
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col md={3} sm={4} xs={8}>
                            <Form.Label
                                style={{marginTop: '.5rem'}}>{trans('certificate_changes.date_period')}</Form.Label>
                        </Col>
                        <Col md={3} sm={4} xs={8}>
                            {createDateComponent(start)}
                        </Col>
                        <Col md={3} sm={4} xs={8}>
                            {createDateComponent(end)}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createFormField(field, formType, readonly = true) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={3} sm={6} xs={12}>
                            <Form.Label
                                style={{marginTop: '.5rem'}}>{trans('certificate_changes.' + field)}</Form.Label>
                        </Col>
                        <Col md={15} sm={9} xs={16}>
                            <Form.Control
                                componentClass={formType}
                                type={"text"}
                                value={this.state.form_data[field]}
                                onChange={(e) => {
                                    e.persist();
                                    this.setState((state) => ({
                                        form_data: {
                                            ...state.form_data,
                                            [field]: e.target.value
                                        }
                                    }));
                                }}
                                readOnly={readonly || this.state.disabled}
                                style={{width: '26em'}}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createSelectField(field, source, keyField = 'id', valueField = 'name') {
        let value = CertificateRequest.getTextValueOfSelected(this.state.form_data[field], source, keyField, valueField);

        let fieldComponent = (<Form.Control type="text" value={value} readOnly={true}/>);

        if (!this.state.disabled) {
            fieldComponent = (<Form.Select
                key={field}
                componentClass="select"
                onChange={(e) => {
                    e.persist();
                    this.setState((state) => ({
                        form_data: {
                            ...state.form_data,
                            [field]: e.target.value
                        }
                    }));


                }}
                value={this.state.form_data[field]}
                readOnly={this.state.disabled}
                style={{width: '26em'}}
            >
                {CertificateRequest.createSelectItems(source, keyField, valueField)}
            </Form.Select>);
        }
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={3} sm={4} xs={8}>
                            <Form.Label>{trans('certificate_changes.' + field)}</Form.Label>
                        </Col>
                        <Col md={3} sm={4} xs={8}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    static createSelectItems(source, keyField, valueField) {
        if (source instanceof Array) {
            return source.map(item => {
                return (<option key={item[keyField]} value={item[keyField]}>{item[valueField]}</option>)
            });
        }
        return null;
    }

    static getTextValueOfSelected(value, source, keyField, valueField) {
        if (!(source instanceof Array) || keyField === null || valueField === null) {
            return '';
        }
        for (let item of source) {
            if (item[keyField] === value) {
                return item[valueField];
            }
        }
    }


    render() {
        const cert = this.state.available_certificates[this.state.form_data.certificate_type_id] ||
            (credits.length === 0 && accounts.length === 0);
        const credits = cert?.credits;
        const accounts = cert?.accounts;
        const certsAvailable = Object.keys(this.state.available_certificates).length > 0;
        const dates = cert?.date_period;



        return <div>
            <h1>Форма заказа справки для клиента</h1>
            <br/>
            {!certsAvailable &&
                <div>У данного клиента нет доступных типов справок для заказа.</div>
            }
            {certsAvailable && <div>
                <Container className="modal-cont">
                    <Col xs={16} sm={16} md={9}>
                        <Form horizontal ref="form">
                            <Form.Group bsSize="small">
                                {this.createFormField('EMAIL', 'input', true)}
                                {certsAvailable && this.createSelectField('certificate_type_id', Object.values(this.state.available_certificates), 'type', 'name')}
                                {accounts?.length > 0
                                    && this.createSelectField('account', accounts, 'ACC_ID', 'ACC_NUMBER')}
                                {credits?.length > 0
                                    && this.createSelectField('credit', credits, 'CRED_ID', 'form_text')}
                                {dates && this.createDatePeriodFields("date_beginning", "date_ending")}
                            </Form.Group>
                        </Form>
                    </Col>
                </Container>
                <br/><br/>
                <Button className="btn btn-success" onClick={this.handleAcceptBtnClick}>Заказать справку</Button>
            </div>
            }
        </div>;
    }
}

export default connect()(CertificateRequest);