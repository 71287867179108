import React, {Component} from "react";
import {
    Button,
    Col, FormLabel, Form, FormControl, FormGroup, Container, Modal, ModalBody, ModalFooter, ModalHeader,
    ModalTitle,
    Row
} from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import "./styles/changeDocumentModal.scss"
import PictureCarousel from "./PictureCarousel";
import {findDOMNode} from "react-dom";
import {errorPanel} from "../actions/panels";
import {dateFormatter, formatDateInput} from "../utils/formatters";
import {pick, trans} from "../utils";
import InsuranceRejectModal from "./InsuranceRejectModal";
import DocumentChangeStatus from "../constants/DocumentChangeStatus";
import {approveChange, saveChange} from "../actions/document_requests";
import DocumentRequestTypes from "../constants/DocumentRequestTypes";
import {connect} from "react-redux";
import {addInsuranceManually} from "../reducers/add_insurance_manually";
import CurrencyInput from "react-currency-input-field";

class InsuranceChangeHandleModal extends Component {

    fillables = [
        'date_ending',
        'num_polis',
        'insurer_id',
        'type',
        'comment',
        'premium'
    ];

    constructor(props) {
        super(props);
        let row = JSON.parse(JSON.stringify(this.props.row));

        const possibleCredits = props.possibleCredits;
        console.log(possibleCredits);
        this.state = {
            insurance_data: {
                ...pick(row, this.fillables),
                date_beginning: dateFormatter(row.date_beginning),
                date_ending: dateFormatter(row.date_ending),
            },
            showRejectModal: false,
            disabled: !([
                //disabled 10073

                DocumentChangeStatus.REJECTED_BY_ABS,
                DocumentChangeStatus.CREATED,
                DocumentChangeStatus.PROCESSING_BY_OPERATOR,
            ].includes(this.props.row.status)),
            isManualAdding: props.isManualAdding,
            possibleCredits: possibleCredits,
            chosenCredit: props.isManualAdding ? {
                CRED_ID: possibleCredits[0].CRED_ID,
                CONTRACT_NUMBER: possibleCredits[0].CONTRACT_NUMBER,
                VIN: possibleCredits[0].VIN,
                PRODUCT_NAME: possibleCredits[0].PRODUCT_NAME
            } : {
                CRED_ID: row.CRED_ID,
                CONTRACT_NUMBER: row.CONTRACT_NUMBER,
                VIN: row.VIN,
                PRODUCT_NAME: row.PRODUCT_NAME
            }
        };

        this.initialState = JSON.parse(JSON.stringify(this.state));
        this.type = DocumentRequestTypes.INSURANCE_TYPE;

        this.close = ::this.close;
        this.createDateField = ::this.createDateField;
        this.createFormField = ::this.createFormField;
        this.createStringField = ::this.createStringField;
        this.createTextField = ::this.createTextField;
        this.createCurrencyInputField = ::this.createCurrencyInputField;
        this.createSelectField = ::this.createSelectField;
        this.handleAcceptBtnClick = ::this.handleAcceptBtnClick;
        this.handleRejectBtnClick = ::this.handleRejectBtnClick;
        this.handleSaveBtnClick = ::this.handleSaveBtnClick;
        this.returnToInitialRow = ::this.returnToInitialRow;
        this.validateDate = ::this.validateDate;
        this.handleAddInsuranceManuallyBtnClick = ::this.handleAddInsuranceManuallyBtnClick;
    }

    componentDidMount() {
        this.form = this.refs.form;
    }

    close() {
        this.props.onCloseClick()
    }

    validateDate() {
        const {
            insurance_data
        } = this.state;
        if (insurance_data.num_polis === "") {
            this.props.dispatch(errorPanel("Номер полиса не может быть пустым"));
            return false;
        }
        if (!(moment(insurance_data.date_ending, "DD.MM.YYYY").isValid())) {
            this.props.dispatch(errorPanel("Некорректная дата"));
            return false;
        }
        return true;
    }

    constructInsuranceData() {
        return this.state.insurance_data;
    }

    handleAcceptBtnClick() {
        if (this.validateDate()) {
            this.props.dispatch(approveChange(this.type, this.props.row.request_id, this.constructInsuranceData()));
        }
        this.close();
    }

    handleAddInsuranceManuallyBtnClick() {
        if (this.validateDate()) {
            this.props.dispatch(addInsuranceManually(this.type, this.props.row.CLIENT, this.state.chosenCredit.CRED_ID, this.constructInsuranceData()));
        }
        this.close();
    }


    handleRejectBtnClick() {
        this.setState({showRejectModal: true});
    }

    handleSaveBtnClick() {
        this.props.dispatch(saveChange(this.type, this.props.row.request_id, this.constructInsuranceData()));
        this.close();
    }

    returnToInitialRow() {
        this.setState(this.initialState);
        findDOMNode(this.form).reset();
    }

    createDateField(field) {
        let fieldComponent = (<Form.Control type="text" value={this.state.insurance_data[field]} readOnly={true}/>);

        if (!this.state.disabled) {
            fieldComponent = (<Datetime dateFormat="DD.MM.YYYY"
                                        timeFormat={false}
                                        locale={"ru"}
                                        value={this.state.insurance_data[field]}
                                        closeOnSelect={true}
                                        onChange={(e) => {
                                            let date = formatDateInput(e);
                                            this.setState((state) => ({
                                                insurance_data: {
                                                    ...state.insurance_data,
                                                    [field]: date,
                                                },
                                            }));
                                        }
                                        }
                                        readOnly={this.state.disabled}
            />);
        }
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={5} sm={9} xs={16}>
                            <Form.Label>{trans('insurance_changes.' + field)}</Form.Label>
                        </Col>
                        <Col md={5} sm={9} xs={16}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createFormField(field, formType) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={3} sm={6} xs={12}>
                            <Form.Label>{trans('insurance_changes.' + field)}</Form.Label>
                        </Col>
                        <Col md={15} sm={9} xs={16}>
                            <Form.Control
                                componentClass={formType}
                                type={"text"}
                                value={this.state.insurance_data[field]}
                                onChange={(e) => {
                                    e.persist();
                                    this.setState((state) => ({
                                        insurance_data: {
                                            ...state.insurance_data,
                                            [field]: e.target.value
                                        }
                                    }));
                                }}
                                readOnly={this.state.disabled}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createCurrencyInputField(field) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={3} sm={6} xs={12}>
                            <Form.Label>{trans('insurance_changes.' + field)}</Form.Label>
                        </Col>
                        <Col md={15} sm={9} xs={16}>
                            <CurrencyInput
                                id="insurance-change-premium"
                                name="insurance-change-premium"
                                placeholder="Сумма в рублях"
                                defaultValue={this.state.insurance_data[field] ?? 0}
                                decimalsLimit={2}
                                decimalSeparator={'.'}
                                groupSeparator = ' '
                                allowNegativeValue={false}
                                allowDecimals={true}
                                disableAbbreviations={true}
                                onValueChange={(value, name) => {
                                    this.setState((state) => ({
                                        insurance_data: {
                                            ...state.insurance_data,
                                            [field]: value
                                        }
                                    }));
                                }}
                                disabled={this.state.disabled}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createStringField(field) {
        return this.createFormField(field, 'input');
    }

    createTextField(field) {
        return this.createFormField(field, 'textarea');
    }

    static createSelectItems(source, keyField, valueField) {
        if (source instanceof Array) {
            return source.map(item => {
                return (<option key={item[keyField]} value={item[keyField]}>{item[valueField]}</option>)
            });
        }
        return null;
    }

    getInfoListItem(field) {
        return (<li><b>{trans('insurance_changes.' + field)}:</b> {this.props.row[field]}</li>)
    }

    getInfoListFromChosenCredit(field) {
        return (<li><b>{trans('insurance_changes.' + field)}:</b> {this.state.chosenCredit[field]}</li>)
    }

    static getTextValueOfSelected(value, source, keyField, valueField) {
        if (!(source instanceof Array) || keyField === null || valueField === null) {
            return '';
        }
        for (let item of source) {
            if (item[keyField] === value) {
                return item[valueField];
            }
        }
    }

    createSelectField(field, source, keyField = 'id', valueField = 'name') {
        let value = InsuranceChangeHandleModal.getTextValueOfSelected(this.state.insurance_data[field], source, keyField, valueField);

        let fieldComponent = (<Form.Control type="text" value={value} readOnly={true}/>);

        if (!this.state.disabled) {
            fieldComponent = (<Form.Select
                key={field}
                componentClass="select"
                onChange={(e) => {
                    e.persist();
                    this.setState((state) => ({
                        insurance_data: {
                            ...state.insurance_data,
                            [field]: e.target.value
                        }
                    }));
                }}
                value={this.state.insurance_data[field]}
                readOnly={this.state.disabled}
            >
                {InsuranceChangeHandleModal.createSelectItems(source, keyField, valueField)}
            </Form.Select>);
        }
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={4} sm={8} xs={16}>
                            <Form.Label>{trans('insurance_changes.' + field)}</Form.Label>
                        </Col>
                        <Col md={4} sm={8} xs={16}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createFooterWithButtons() {
        if (this.state.isManualAdding) {
            return <ModalFooter>
                <Button className="btn btn-success" onClick={this.handleAddInsuranceManuallyBtnClick}
                        disabled={this.state.disabled}>Загрузить в ЦФТ</Button>
            </ModalFooter>
        }
        return <ModalFooter>
            <Button className="pull-left" onClick={this.close}>Закрыть</Button>
            <Button className="btn btn-info" onClick={this.handleSaveBtnClick}
                    disabled={this.state.disabled}>Cохранить</Button>
            <Button className="btn btn-danger" onClick={this.handleRejectBtnClick}
                    disabled={this.state.disabled}>Отклонить</Button>
            <Button className="btn btn-success" onClick={this.handleAcceptBtnClick}
                    disabled={this.state.disabled}>Подтвердить</Button>
        </ModalFooter>
    }

    render() {
        const {
            row
        } = this.props;
        return (
            <Modal show={true} onHide={this.close} bsSize={"lg"} dialogClassName={"insurance-modal"}>
                {
                    this.state.showRejectModal &&
                    <InsuranceRejectModal
                        onCloseClick={() => this.setState({showRejectModal: false})}
                        request_id={this.props.row.request_id}
                        comment={this.state.insurance_data.comment}
                    />
                }
                <ModalHeader closeButton>
                    <ModalTitle>Заявка клиента {row.FIO} на смену полиса на
                        продукт {this.state.chosenCredit.PRODUCT_NAME}(к/д {this.state.chosenCredit.CONTRACT_NUMBER})</ModalTitle>

                    {

                    }
                </ModalHeader>
                <ModalBody>
                    <Container className="modal-cont">
                        {row.status === DocumentChangeStatus.REJECTED_BY_OPERATOR &&
                        <p className={'reason-header'}>
                            <strong>
                                <p style={{color:'red'}}>Заявка отклонена оператором.</p>
                                Причина:
                            </strong> {trans('insurance_reasons.' + row.reason)}
                        </p>}
                        <Col xs={16} sm={16} md={9}>

                            <h4>Информация о кредите:</h4>
                            {
                                this.state.isManualAdding && <Form.Select onChange={(event) => {
                                    console.log(event.target.value);
                                    const credit = this.state.possibleCredits.find((credit) => credit.CRED_ID === event.target.value);
                                    this.setState({
                                        'chosenCredit' : {
                                            CRED_ID: credit.CRED_ID,
                                            CONTRACT_NUMBER: credit.CONTRACT_NUMBER,
                                            VIN: credit.VIN,
                                            PRODUCT_NAME: credit.PRODUCT_NAME
                                        }
                                    })
                                }}>
                                    {this.state.possibleCredits.map((credit) => {
                                        return <option
                                            value={credit.CRED_ID}>{credit.PRODUCT_NAME} (к/д {credit.CONTRACT_NUMBER})</option>
                                    })}
                                </Form.Select>
                            }
                            <ul>
                                {this.getInfoListItem('CLIENT')}
                                {this.getInfoListItem('FIO')}
                                {this.getInfoListFromChosenCredit('CONTRACT_NUMBER')}
                                {this.getInfoListFromChosenCredit('PRODUCT_NAME')}
                                {this.getInfoListFromChosenCredit('VIN')}
                            </ul>
                            <hr/>
                            <Form horizontal ref="form">
                                <Form.Group bsSize="small">
                                    {this.createDateField("date_beginning")}
                                    {this.createDateField("date_ending")}
                                    {this.createStringField("num_polis")}
                                    {this.createSelectField("insurer_id", this.props.companies)}
                                    {this.createSelectField("type", this.props.policyTypes)}
                                    {this.createTextField('comment')}
                                    {this.createCurrencyInputField('premium')}
                                </Form.Group>
                            </Form>
                            <Button onClick={this.returnToInitialRow} disabled={this.state.disabled}>Сброс</Button>
                        </Col>
                        {
                            this.renderPictureCarousel(row)
                        }
                    </Container>
                </ModalBody>
                {this.createFooterWithButtons()}
            </Modal>
        );
    }

    renderPictureCarousel(row) {
        if(row.insurance_photos === undefined || row.insurance_photos.length === 0){
            return null;
        }
        return <Col xs={16} sm={16} md={9} className={"align-self-center"}>
            <PictureCarousel pictures={row.insurance_photos}/>
        </Col>;
    }
}

export default connect()(InsuranceChangeHandleModal);