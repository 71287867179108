import {
    FILL_ADDRESS_SUGGESTIONS,
    REQUEST_ADDRESS_SUGGESTIONS,
    UPDATE_ADDRESS_SUGGESTIONS
} from "../actions/dadata";

const initialState = {
    data: {
        region: [],
        area: [],
        city: [],
        settlement: [],
        street: [],
        house: []
    }
};

export function updateAddressSuggestions(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ADDRESS_SUGGESTIONS:
            return {
                ...state,
                data: Object.keys(state.data).reduce(function (previous, current) {
                    if (current === action.bound)
                        previous[current] = action.data;
                    else
                        previous[current] = state.data[current];
                    return previous;
                }, {})
            };
        default:
            return state;
    }
}

export function fillAddressSuggestions(state = {}, action) {
    switch (action.type) {
        case REQUEST_ADDRESS_SUGGESTIONS:
            return {
                ...state,
                isFetching: true,
            };
        case FILL_ADDRESS_SUGGESTIONS:
            return {
                ...state,
                isFetching: false,
                data: action.data,
            };
        default:
            return state;
    }
}