import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Form, ModalHeader, Modalheader} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import Datetime from "react-datetime";
import moment from "moment";
import {errorPanel, infoPanel} from "../actions/panels";
import {axios} from "../utils/axios";
import fileSaver from "file-saver";
import {Link} from "react-router-dom";
import {tab} from "@testing-library/user-event/dist/tab";

class ReportComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date_start: moment("2018-01-01"),
            date_end: moment(),
            request_id: null,
            timer_id: null,
        };
        this.__dateTimeChangeHandler = ::this.__dateTimeChangeHandler;
        this.downloadReport = ::this.downloadReport;
        this.getStats = ::this.getStats;
        this.generateFileName = ::this.generateFileName;
        this.generateLink = ::this.generateLink;
        this.renderButtonIfNeeded = ::this.renderButtonIfNeeded;
        this.renderStatsIfNeeded = ::this.renderStatsIfNeeded;
        this.checkDates = ::this.checkDates;
        this.beginRequestStatusMonitoring = ::this.beginRequestStatusMonitoring;
        this.checkState = ::this.checkState;
        this.addReportToQueue = ::this.addReportToQueue;
        this.getButtonText = ::this.getButtonText;

    }

    __dateTimeChangeHandler(value, field) {
        if (value instanceof moment) {
            this.setState({
                [field]: value
            });
        }
    }

    static defaultProps = {
        withStats: false,
        showReportButton: true,
        tables: [],
        reportLink: null,
        loadStatsFunction: (b, e) => {
        },
    };

    generateLink(begin, end) {
        return this.props.route + `?date_begin=${begin.format("YYYY-MM-DD")}&date_end=${end.format("YYYY-MM-DD")}`;
    }

    generateDownloadLink() {
        return `external/reports/download?request_id=${this.state.request_id}`;
    }

    generateFileName(begin, end) {
        return this.props.fileName + `-from-${begin.format("YYYY-MM-DD")}-to-${end.format("YYYY-MM-DD")}.xlsx`;
    }

    checkDates() {
        if (this.state.date_end instanceof moment && this.state.date_start instanceof moment) {
            if (this.state.date_start > this.state.date_end) {
                this.props.dispatch(errorPanel("Дата начала периода не должна быть позже даты конца периода"));
                return false;
            }
            return true;
        } else {
            this.props.dispatch(errorPanel("Неправильный интервал дат"));
            return false;
        }
    }

    downloadReport() {
        if (this.checkDates()) {
            const filename = this.generateFileName(this.state.date_start, this.state.date_end);
            axios(this.generateDownloadLink(), true)
                .then((res) => {
                    let blob = new Blob([res.data]);
                    fileSaver.saveAs(blob, filename);
                }, (err) => {
                    this.props.dispatch(errorPanel("Ошибка загрузки отчёта."));
                });
        }
    }

    addReportToQueue() {
        if (!this.checkDates()) {
            return;
        }
        if (this.state.timer_id !== null) {
            return;
        }

        this.props.dispatch(infoPanel("Начата генерация отчёта. Подождите, пожалуйста."));
        axios(this.generateLink(this.state.date_start, this.state.date_end), false)
            .then((res) => {
                if (res.status !== 200 || !res.data.request_id) {
                    this.props.dispatch(errorPanel("Ошибка генерации отчёта."));
                    return;
                }
                this.setState({
                    request_id: res.data.request_id
                });
                this.beginRequestStatusMonitoring();
            }, (err) => {
                this.props.dispatch(errorPanel("Ошибка генерации отчёта."));
            });

    }

    getStats() {
        if (this.checkDates()) {
            this.props.dispatch(this.props.loadStatsFunction(this.state.date_start, this.state.date_end))
        }
    }

    renderStatsIfNeeded() {
        let result = this.props.tables.map(table => {
            let headers = table.headers.map(function (header) {
                    return {
                        dataField: header.field,
                        text: header.name,
                        ...header.props,
                    }
                }
            );
            return (
                <div>
                    <br/>
                    <h3>{table.name}</h3>
                    {table.data  &&
                    <BootstrapTable data={table.data} columns={headers} keyField={table.keyField}/>
                    }
                </div>
            );
        });



        return this.props.withStats ? result : null;
    }

    renderButtonIfNeeded() {
        return this.props.withStats && this.props.showReportButton ?
            <Button
                disabled={!this.props.reportLink || this.state.timer_id !== null}
                componentClass={Link}
                onClick={this.addReportToQueue}
            >
                {this.state.timer_id === null ? "Загрузить отчёт" : "Отчёт генерируется..."}
            </Button> : null;
    }

    getButtonText() {
        if (this.state.timer_id !== null) {
            return "Идёт генерация отчёта...";
        }
        if (this.props.withStats) {
            return "Получить результат";
        }
        return "Загрузить отчёт";
    }

    render() {
        return <div className="search-block">
            <ModalHeader>Выберите период для сбора статистики</ModalHeader>
            <Form inline={true}
                  onSubmit={event => {
                      event.preventDefault();
                      if (this.props.withStats) {
                          this.getStats();
                      } else {
                          this.addReportToQueue();
                      }
                  }}
            >
                <Datetime dateFormat="DD.MM.YYYY"
                          timeFormat={false}
                          viewMode='years'
                          locale="ru"
                          utc={true}
                          onChange={(value) => this.__dateTimeChangeHandler(value, 'date_start')}
                          defaultValue={moment("2018-01-01")}
                          inputProps={{
                              'placeholder': 'Дата начала',
                          }}
                />
                <Datetime dateFormat="DD.MM.YYYY"
                          timeFormat={false}
                          viewMode='years'
                          locale="ru"
                          utc={true}
                          onChange={(value) => this.__dateTimeChangeHandler(value, 'date_end')}
                          defaultValue={moment()}
                          inputProps={{
                              'placeholder': 'Дата конца',
                          }}
                />
                <Button type='submit' disabled={this.state.timer_id !== null}>
                    {this.getButtonText()}
                </Button>
            </Form>
            {
                this.renderStatsIfNeeded()
            }
            {
                this.renderButtonIfNeeded()
            }
        </div>;
    }

    stopChecks() {
        clearTimeout(this.state.timer_id);
        this.setState({timer_id: null});

    }

    checkState() {
        axios('external/reports/status?request_id=' + this.state.request_id).then((res) => {
            switch (res.data.status) {
                case 'READY':
                    this.stopChecks();
                    this.props.dispatch(infoPanel("Ваш отчёт готов. Начинается загрузка файла."));
                    this.downloadReport();
                    break;
                case "ERROR":
                    this.stopChecks();
                    this.props.dispatch(infoPanel("Ошибка при генерации отчёта"));
                    break;
                default:

            }
        }).catch((err) => {
            this.stopChecks();
            this.props.dispatch(infoPanel("Ошибка при получении статуса отчёта"));
        });
    }

    beginRequestStatusMonitoring() {
        if (this.state.timer_id !== null) {
            return;
        }
        const timerId = setInterval(this.checkState, 5000);
        this.setState({
            'timer_id': timerId
        });
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(ReportComponent);