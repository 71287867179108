import {axios} from "../utils/axios";
import {errorPanel} from "./panels";
import {logout} from "./bank_person";

export const UPDATE_EOSAGO_STATS = 'UPDATE_EOSAGO_STATS';
export const UPDATE_CASCO_STATS = 'UPDATE_CASCO_STATS';

export function updateEosagoStats(data, reportLink) {
    return {
        type: UPDATE_EOSAGO_STATS,
        data, reportLink
    };
}

export function updateCascoStats(data, reportLink) {
    return {
        type: UPDATE_CASCO_STATS,
        data, reportLink
    };
}

export function loadCascoStats(dateBegin, dateEnd) {
    return dispatch => {
        axios('external/stats/casco', false, {
            date_begin: dateBegin.format('YYYY-MM-DD'),
            date_end: dateEnd.format('YYYY-MM-DD'),
        })
            .then(response => {
                dispatch(updateCascoStats(response.data, response.data.report_link));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel("Произошла ошибка при загрузке статистики КАСКО"));
            })
    }
}

export function loadEosagoStats(dateBegin, dateEnd) {
    return dispatch => {
        axios('external/stats/eosago', false, {
            date_begin: dateBegin.format('YYYY-MM-DD'),
            date_end: dateEnd.format('YYYY-MM-DD')
        })
            .then(response => {
                dispatch(updateEosagoStats(response.data, response.data.report_link));
            }).catch(error => {
            if (error.response.status === 403) {
                dispatch(logout()); //force logout on 403
            }
            dispatch(errorPanel("Произошла ошибка при загрузке статистики ОСАГО"));
        });
    }
}