/* eslint-disable no-undef */
/**
 * Created by anton on 5/5/17.
 */

import axiosLib from "axios";

export const BASE_POINT = process.env.INTERIM_API_ENDPOINT;
const VALIDATION_ERROR = 422;
export const STATUS_RESPONSE_TOO_BIG = 499;

// Add a response interceptor
axiosLib.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response) {
        if (error.response.status === VALIDATION_ERROR) {
            let str = 'Validation error: ';
            error.response.data.content.forEach(val => {
                str += val + ' ';
            });
            error.response.data.error = str;
        }
        if (error.response.status === STATUS_RESPONSE_TOO_BIG) {
            return error.response;
        }
    }
    return Promise.reject(error);
});

export function axios(url, binary = false, params = {}) {
    url = BASE_POINT + url;
    console.log('AXIOS GET: ' + url);


    let options = {};
    if(binary){
        options.responseType = "arraybuffer";
    }

    if (localStorage.getItem('user_data')) {
        return axiosLib(url, {
            headers: {
                'X-Intranet-Token': JSON.parse(localStorage.getItem('user_data')).token,
            },
            params: params,
            ...options
        });
    } else {
        return axiosLib(url);
    }
}

export function axios_post(url, data, headers = {}) {
    url = BASE_POINT + url;
    console.log('AXIOS POST: ' + url);
    if (localStorage.getItem('user_data')) {
        return axiosLib.post(url, data, {
            headers: {
                ...headers,
                'X-Intranet-Token': JSON.parse(localStorage.getItem('user_data')).token
            }
        });
    } else {
        return axiosLib.post(url, data, {headers: headers});
    }
}