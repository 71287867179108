import React, {Component} from 'react';
import {connect} from 'react-redux';
import RequestTab from "./RequestTab";
import DocumentChangeStatus from "../../constants/DocumentChangeStatus";
import {trans} from "../../utils";
import PassportChangeHandleModal from "../PassportChangeHandleModal";
import {closeChangeHandleModal} from "../../actions/document_requests";
import DocumentRequestTypes from "../../constants/DocumentRequestTypes";

class PassportChangeTab extends Component {
    constructor(props) {
        super(props);

        this.renderModalComponent = ::this.renderModalComponent;
    }

    static getColumns() {
        return [
            {field: '#', props: {isKey: true, width: '40px'}},
            {field: 'client_id', props: {width: '120px'}},
            {field: 'FIO', props: {width: '250px'}},
            {field: 'serial'},
            {field: 'number'},
            {field: 'status_text', props: {width: '150px'}},
            {field: 'created_at', props: {width: '170px', dataSort: true}},
            {field: 'operator_uid', props: {width: '170px', dataSort: true}},
        ]
    }

    static getDataTransformer() {
        return item =>
            Object.assign(
                item.passport_data,
                {
                    'client_id': item.client_id,
                    'request_id': item.request_id,
                    'FIO': item.FIO,
                    'status': item.status,
                    'status_text': trans('document_changes_status.' + item.status),
                    'created_at': item.created_at
                });
    }

    renderModalComponent() {
        return (
            <PassportChangeHandleModal
                onCloseClick={() => this.props.dispatch(closeChangeHandleModal(DocumentRequestTypes.PASSPORT_TYPE))}
                row={this.props.row}
            />)
    }

    render() {
        return (
            <RequestTab
                type='passport'
                statusOptions={DocumentChangeStatus.SMS_CONFIRMATION_SUCCESS}
                transPath={'passport_changes.'}
                columns={PassportChangeTab.getColumns()}
                dataTransformer={PassportChangeTab.getDataTransformer()}
                modalComponent={this.renderModalComponent()}
            />
        );
    }
}

function mapStateToProps(state) {
    let passportModal = state.showChangeHandleModal[DocumentRequestTypes.PASSPORT_TYPE] || {row: {}};

    return {
        row: passportModal.row,
    };
}

export default connect(mapStateToProps)(PassportChangeTab);
