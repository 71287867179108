const REJECTED_BY_OPERATOR_UNREADABLE = "REJECTED_BY_OPERATOR_UNREADABLE";
const REJECTED_BY_OPERATOR_ABS_PROGRAM = "REJECTED_BY_OPERATOR_ABS_PROGRAM";
const REJECTED_BY_OPERATOR_REPETITION = "REJECTED_BY_OPERATOR_REPETITION";
const REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS = "REJECTED_BY_OPERATOR_RULES_AND_CONDITIONS";

export default {
    REJECTED_BY_OPERATOR_UNREADABLE,
    REJECTED_BY_OPERATOR_ABS_PROGRAM,
    REJECTED_BY_OPERATOR_REPETITION,
    REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS,
}