const STATUS_DELIVERED = 0;
const STATUS_NOT_DELIVERED = 1;
const STATUS_UNKNOWN = 2;
const STATUS_NOT_SENT = 3;

export default {
    STATUS_DELIVERED,
    STATUS_NOT_DELIVERED,
    STATUS_UNKNOWN,
    STATUS_NOT_SENT
}