/**
 * Created by anton on 5/4/17.
 */

import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import history from "history/browser";
import {
    Dropdown,
    Nav,
    Navbar as BaseNavbar,
    NavbarBrand,
    NavDropdown,
    NavItem
} from "react-bootstrap";
import * as person_actions from "../actions/bank_person";
import {BANK_EMPLOYEE, CALL_CENTER_OPERATOR} from "../actions/bank_person";
import PropTypes from "prop-types";
import {CiLogout} from "react-icons/ci";


class Navbar extends React.Component {

    constructor(props) {
        super(props);

        this.handleSelect = ::this.handleSelect;
        this.handlePerson = ::this.handlePerson;
        this.isAuthorized = ::this.isAuthorized;
        this.isBankEmployee = ::this.isBankEmployee;
        this.isCallCenterOperator = ::this.isCallCenterOperator;
        this.navigate = props.navigate;
    }

    handleSelect(eventKey) {
        if (!!eventKey) {
            this.navigate(eventKey);
        }
    }

    isAuthorized(){
        return !!this.props.person
    }

    isBankEmployee() {
        return this.isAuthorized() && this.props.person.role === BANK_EMPLOYEE;
    }

    isCallCenterOperator() {
        return this.isAuthorized() && this.props.person.role === CALL_CENTER_OPERATOR;
    }

    handlePerson(event) {
        switch (event) {
            case 'logout':
                this.props.dispatch(person_actions.doLogout());
                break;
            case 'authorize':
                this.props.onAuthClick();
                break;
            default:
                this.handleSelect(event);
                break;
        }
    }

    render() {
        const {person, isAuthorizing} = this.props;

        return (
            <BaseNavbar>
                <BaseNavbar.Text>
                    <NavbarBrand>
                        <Link to={'/clients'}>
                            {this.isBankEmployee() && "Панель администратора"}
                            {this.isCallCenterOperator() && "Панель оператора"} Авто Финанс Банк
                        </Link>
                    </NavbarBrand>
                </BaseNavbar.Text>

                {
                    (this.isCallCenterOperator() || this.isBankEmployee()) && <Nav onSelect={this.handleSelect}>
                        <Nav.Link eventKey="/clients" >Клиенты</Nav.Link>
                        <Nav.Link eventKey="/document_requests">Заявки</Nav.Link>
                    </Nav>
                }

               {
                    this.isBankEmployee() && <Nav    onSelect={this.handleSelect}>
                        <Nav.Link eventKey="/reports">Отчёты</Nav.Link>
                        {/*<Nav.Link eventKey="/misc">Дополнительно</Nav.Link>*/}
                    </Nav>
               }



                <Nav pullRight onSelect={this.handlePerson}>
                    {
                        person
                            ? <NavDropdown title={person.fullName} id="PersonDropdown">
                                <NavDropdown.Item eventKey="logout">Выйти
                                    <CiLogout glyph="log-out"/>&nbsp;
                            </NavDropdown.Item>
                        </NavDropdown>
                            :
                            isAuthorizing
                                ? <Nav.Item disabled>Авторизуемся</Nav.Item>
                                : <Nav.Item eventKey="authorize">Авторизоваться</Nav.Item>
                    }
                </Nav>
            </BaseNavbar>
        )
    }
}

Navbar.propTypes = {
    onAuthClick: PropTypes.func.isRequired
};

export default connect(state => {
    const auth_person = state.authorizedPerson || {};
    return {
        person: auth_person.person,
        isAuthorizing: auth_person.isAuthorizing
    }
})(Navbar);