import React, {Component} from 'react';
import {connect} from 'react-redux';
import RequestTab from "./RequestTab";
import DocumentChangeStatus from "../../constants/DocumentChangeStatus";
import {humanReadableFileSize, trans} from "../../utils";
import PassportChangeHandleModal from "../PassportChangeHandleModal";
import {closeChangeHandleModal} from "../../actions/document_requests";
import DocumentRequestTypes from "../../constants/DocumentRequestTypes";
import {dateFormatter, datetimeFormatter} from "../../utils/formatters";

class LettersToBankTab extends Component {
    constructor(props) {
        super(props);

        this.renderModalComponent = ::this.renderModalComponent;
    }

    static letterToBankFileFormatter(cell, row, rowIndex, formatterExtra) {
        return <div>
            {
                cell.map((file, i) => {
                    let name = file.name;
                    if (name.length > 30) {
                        name = name.substring(0, 12) + '...' + name.substring(name.length - 6, name.length);
                    }
                    return <p key={i}>
                        <a href={file.link}>
                            <p>{name} ({humanReadableFileSize(file.length)})</p>
                        </a>
                    </p>
                })
            }
        </div>;
    }

    static getColumns() {
        return [
            {field: 'id', props: {isKey: true, headerStyle: {width: '40px'}}},
            {field: 'CLIENT_INFO', props: {                    headerStyle: {width: '40px', "word-wrap": "anywhere"}
                }},
            //{field: 'FIO', props: {  headerStyle: {width: '40px', 'word-wrap' : 'break-word'}}},
            {field: 'signed_at', props: {formatter: datetimeFormatter, headerStyle: {width: '50px', 'word-wrap' : 'anywhere'}}},
            {field: 'topic', props: {headerStyle: {width: '100px', 'word-wrap' : 'anywhere'}}},
            {
                field: 'text', props: {
                    headerStyle: {width: '300px'}
                }
            },
            {field: 'files', props: {formatter: LettersToBankTab.letterToBankFileFormatter,
                    headerStyle: {width: '100px', 'word-wrap' : 'anywhere'}}},
        ]
    }

    static getDataTransformer() {
        return item => {
            return {
                ...item,
                'status': 'NONE',
                'CLIENT_INFO': item.CLIENT + "  (" + item.FIO + ")"
            }
        };

    }

    renderModalComponent() {
        return null;
    }

    render() {
        return (
            <RequestTab
                type='letter_to_bank'
                statusOptions={DocumentChangeStatus.SMS_CONFIRMATION_SUCCESS}
                transPath={'letter_to_bank.'}
                columns={LettersToBankTab.getColumns()}
                dataTransformer={LettersToBankTab.getDataTransformer()}
                modalComponent={null}
            />
        );
    }


}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(LettersToBankTab);
