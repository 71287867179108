/**
 * Created by anton on 4/28/17.
 */

import {errorPanel} from "./panels";
import {axios, STATUS_RESPONSE_TOO_BIG} from "../utils/axios";
import history from "history/browser";
import recentSearchStorage from "../utils/SearchHistoryStorage";
import {getQueryString} from "../utils";
import {logout} from "./bank_person";

export const INVALIDATE_CLIENT_LIST = 'INVALIDATE_CLIENT_LIST';

export function invalidateClientList() {
    return {
        type: INVALIDATE_CLIENT_LIST
    }
}

export const REQUEST_CLIENTS = 'REQUEST_CLIENTS';

export function requestClients(query = '') {
    return {
        type: REQUEST_CLIENTS,
        query
    }
}

export const RECEIVE_CLIENTS = 'RECEIVE_CLIENTS';

export function receiveClients(data) {
    return {
        type: RECEIVE_CLIENTS,
        data: data.clients
    }
}

export const REQUEST_ERROR = 'REQUEST_ERROR';


export function requestError(error) {
    return {
        type: REQUEST_ERROR,
        error
    }
}

export const REQUEST_ERROR_TOKEN_403 = 'REQUEST_ERROR_TOKEN_403';

export function requestErrorToken403(){
    return {
        type: REQUEST_ERROR_TOKEN_403,
    }
}

export const UPDATE_SEARCH_HISTORY = 'UPDATE_SEARCH_HISTORY';

export function updateSearchHistory() {
    return {
        type: UPDATE_SEARCH_HISTORY
    }
}


export const SOURCE_SEARCH_BOX = 'SEARCH_BOX';
export const SOURCE_SEARCH_HISTORY = 'SEARCH_HISTORY';

export function fetchClients(query = '', source, navigateHook) {
    return dispatch => {
        dispatch(requestClients(query));

        axios(getQueryString('external/clients?', query))
            .then(response => {
                dispatch(receiveClients(response.data));

                if (response.status === STATUS_RESPONSE_TOO_BIG) {
                    dispatch(errorPanel('Найдено более одной записи. Необходимо уточнить параметры поиска'));
                    navigateHook(`/clients`);
                }
                else {
                    if (response.data.clients) {
                        if (response.data.clients.length === 0) {
                            dispatch(errorPanel('Клиент не найден'));
                            navigateHook(`/clients`);
                        } else {
                            //history.push(`/clients/${response.data.clients.client.CLIENT}`);
                            window.history
                                .pushState({}, undefined, `/clients/${response.data.clients.client.CLIENT}`);
                            navigateHook(`/clients/${response.data.clients.client.CLIENT}`);
                            if (source === SOURCE_SEARCH_BOX) {
                                recentSearchStorage.push(response.data.clients.client);
                                dispatch(updateSearchHistory());
                            }
                        }
                    }
                }
            })
            .catch(err => {
                if(err.response !== undefined && err.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(requestError(err));
                dispatch(errorPanel(err));
            })
    }
}