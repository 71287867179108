import {UPDATE_CASCO_STATS, UPDATE_EOSAGO_STATS} from "../actions/stats";

export function updateEosagoStats(state = {}, action){
    switch(action.type){
        case UPDATE_EOSAGO_STATS:
            return {
                data: action.data,
                reportLink: action.reportLink
            };
        default:
            return state;
    }
}

export function updateCascoStats(state = {}, action) {
    switch (action.type) {
        case UPDATE_CASCO_STATS:
            return {
                data: action.data,
                reportLink: action.reportLink
            };
        default:
            return state;
    }
}