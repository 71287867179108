/* eslint-disable no-unused-vars */
import {trans} from '../utils/index';
/**
 * Created by gleb on 5/10/17.
 */

const PRIMARY = 'primary';
const SUCCESS = 'success';
const INFO    = 'info';
const WARNING = 'warning';
const DANGER  = 'danger';

export const ERROR_PANEL = 'ERROR_PANEL';
export function errorPanel(error) {
    const message = error.response
        ? error.response.data?.error || error.response.data?.content?.[0] || 'Server error'
        : error.message || error;

    return {
        type: ERROR_PANEL,
        payload: {
            panelHeader: 'Ошибка',
            panelBody: trans(`errors.${message}`),
            panelType: DANGER,
            panelTime: (new Date()),
            error
        }
    }
}

export const OK_PANEL = 'ERROR_PANEL';
export function okPanel(message) {
    return {
        type: OK_PANEL,
        payload: {
            panelHeader: 'Успех',
            panelBody: message,
            panelType: SUCCESS,
            panelTime: (new Date()),
        }
    }
}

export const INFO_PANEL = 'INFO_PANEL';
export function infoPanel(message) {
    return {
        type: INFO_PANEL,
        payload: {
            panelHeader: 'Информация',
            panelBody: message,
            panelType: INFO,
            panelTime: (new Date()),
        }
    }
}

export const REMOVE_PANEL = 'REMOVE_PANEL';
export function removePanel(index) {
    return {
        type: REMOVE_PANEL,
        payload: index
    }
}

export const REMOVE_ALL_PANELS = 'REMOVE_ALL_PANELS';
export function removeAllPanels() {
    return {
        type: REMOVE_ALL_PANELS
    }
}