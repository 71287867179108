/**
 * Created by anton on 5/2/17.
 */

import {combineReducers} from 'redux';
import {clientsByQuery} from './clients';
import {authorizedPerson} from './bank_person';
import {panels} from './panels';
import {updateCascoStats, updateEosagoStats} from './stats';
import {updateChanges, deliverChanges, showChangeHandleModal, updateInsuranceCompanies} from "./document_requests";
import {updateAddressSuggestions, fillAddressSuggestions} from "./dadata";
import {resetImage} from './interactive_image';

export default combineReducers({
    clientsByQuery,
    authorizedPerson,
    panels,
    updateCascoStats,
    updateEosagoStats,
    updateChanges,
    deliverChanges,
    showChangeHandleModal,
    updateInsuranceCompanies,
    updateAddressSuggestions,
    fillAddressSuggestions,
    resetImage
});