import React, {Component} from "react";
import Datetime from "react-datetime";
import {
    Button, Col, Container, Form, FormControl, FormGroup, FormLabel, Modal, ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle, Row
} from "react-bootstrap";
import Autosuggest from "react-autosuggest";

import "./styles/changeDocumentModal.scss";
import moment from "moment";
import {findDOMNode} from "react-dom";
import {getSuggestions} from "../actions/dadata";
import {connect} from "react-redux";
import {getProperty, trans} from "../utils";
import {errorPanel} from "../actions/panels";
import PictureCarousel from "./PictureCarousel";
import {dateFormatter, formatDateInput} from "../utils/formatters";
import DocumentChangeStatus from "../constants/DocumentChangeStatus";
import PassportRejectModal from "./PassportRejectModal";
import DocumentRequestTypes from "../constants/DocumentRequestTypes";
import {approveChange, saveChange} from "../actions/document_requests";

const bounds = [
    "region",
    "area",
    "city",
    "settlement",
    "street",
    "house"
];

class PassportChangeHandleModal extends Component {

    static prepareAddress(row) {
        let address = row.address;
        if (address === null) {
            address = {};
            for (let bound of bounds) {
                address[bound] = {
                    'text_value': '',
                    'fias_code': '',
                }
            }
            address.flat = '';
            address.index = '';
        } else {
            for (let bound in address) {
                if (address.hasOwnProperty(bound)) {
                    let boundObj = row.address[bound];
                    if (boundObj === null) {
                        address[bound] = '';
                    } else if (typeof boundObj === 'object') {
                        for (let field in boundObj) {
                            if (boundObj.hasOwnProperty(field)) {
                                if (boundObj[field] === null) {
                                    boundObj[field] = '';
                                }
                            }
                        }
                    }
                }
            }
        }
        return address;
    }

    constructor(props) {
        super(props);
        let addressState = {};
        let readyInput = {};
        let needSuggestions = {};
        let address = PassportChangeHandleModal.prepareAddress(this.props.row);

        for (let bound of bounds) {
            addressState[bound] = Object.assign({}, address[bound]);
            readyInput[bound] = true;
            needSuggestions[bound] = true;
        }

        let row = JSON.parse(JSON.stringify(this.props.row));
        row.address = address;

        this.state = {
            row: {
                ...row,
                issued_at: dateFormatter(this.props.row.issued_at)
            },
            ready_input: readyInput,
            need_suggestions: needSuggestions,
            address: addressState,
            disabled: !([
                //disabled 10073
                DocumentChangeStatus.REJECTED_BY_ABS,
                DocumentChangeStatus.SMS_CONFIRMATION_SUCCESS,
                DocumentChangeStatus.PROCESSING_BY_OPERATOR,
            ].includes(this.props.row.status)),
            showRejectModal: false,
            putSuggested: true,
        };

        this.initialState = JSON.parse(JSON.stringify(this.state));
        this.type = DocumentRequestTypes.PASSPORT_TYPE;

        this.close = ::this.close;
        this.handleRejectBtnClick = ::this.handleRejectBtnClick;
        this.handleAcceptBtnClick = ::this.handleAcceptBtnClick;
        this.handleSaveBtnClick = ::this.handleSaveBtnClick;
        this.constructObject = ::this.constructObject;

        this.createFormField = ::this.createFormField;
        this.createStringField = ::this.createStringField;
        this.createTextField = ::this.createTextField;
        this.createDateField = ::this.createDateField;
        this.createAddressField = ::this.createAddressField;
        this.createBlockedFormField = ::this.createBlockedFormField;

        this.returnToInitialRow = ::this.returnToInitialRow;
        this.getMinFias = ::this.getMinFias;
        this.showSuggestions = ::this.showSuggestions;
        this.loadSuggestions = ::this.loadSuggestions;
        this.onChange = ::this.onChange;
        this.onSuggestionsClearRequested = ::this.onSuggestionsClearRequested;
        this.onSuggestionsFetchRequested = ::this.onSuggestionsFetchRequested;
        this.setReadyInput = ::this.setReadyInput;
        this.setNeedSuggestions = ::this.setNeedSuggestions;
        this.getFilledUnitsAbove = ::this.getFilledUnitsAbove;
        this.guessAddress = ::this.guessAddress;
        this.emptyAddress = ::this.emptyAddress;
        this.findFirstDefinedProperty = ::this.findFirstDefinedProperty;
        this.validateRow = ::this.validateRow;

    }

    componentDidMount() {
        this.form = this.refs.form;
    }

    componentDidUpdate() {
        if (this.props.fillAddress.length !== 0 && !this.props.fetchingSuggestions && !this.state.putSuggested) {
            this.setState({address: this.props.fillAddress[0].address, putSuggested: true});
        }
    }

    /**
     * Button handlers
     */

    close() {
        this.props.onCloseClick()
    }

    validateRow() {

        const {
            row
        } = this.state;
        if (row.serial.length !== 4) {
            this.props.dispatch(errorPanel("Cерия паспорта должен иметь 4 цифры"));
            return false;
        }
        if (row.number.length !== 6) {
            this.props.dispatch(errorPanel("Номер паспорта должен иметь 6 цифр"));
            return false;
        }
        if (!(moment(row.issued_at, 'DD.MM.YYYY').isValid())) {
            this.props.dispatch(errorPanel("Некорректная дата"));
            return false;
        }
        if (!this.state.address.street.fias_code && this.state.address.street.text_value) {
            this.props.dispatch(errorPanel("Некорректно указана улица"));
            return false;
        }
        if (!(this.getMinFias())) {
            this.props.dispatch(errorPanel("ФИАС не заполнен (некорректный адрес)"));
            return false;
        }
        return true;
    }

    handleAcceptBtnClick = () => {
        if (this.validateRow()) {
            this.props.dispatch(approveChange(this.type, this.state.row.request_id, this.constructObject()));
        }
        this.close()
    };

    handleRejectBtnClick = () => {
        this.setState({showRejectModal: true});
    };

    handleSaveBtnClick = () => {
        this.props.dispatch(saveChange(this.type, this.state.row.request_id, this.constructObject()));
        this.close()
    };

    constructObject() {
        let newRow = Object.assign({}, this.state.row, {address: {...this.state.row.address, ...this.state.address}});
        // noinspection JSUnusedLocalSymbols
        let {first_page_photo, registration_page_photo, ...rest} = newRow;
        return rest;
    }

    returnToInitialRow = () => {
        let addressState = {};
        let readyInput = {};
        let needSuggestions = {};
        for (let bound of bounds) {
            addressState[bound] = Object.assign({}, this.props.row.address[bound]);
            readyInput[bound] = true;
            needSuggestions[bound] = true;
        }
        this.setState((state) => ({
            ...state,
            ...this.initialState,
            address: addressState,
            readyInput: readyInput,
            needSuggestions: needSuggestions,
        }));
        findDOMNode(this.form).reset();
    };

    getMinFias() {
        return this.state.address.street.fias_code ||
            this.state.address.city.fias_code ||
            this.state.address.settlement.fias_code;
    }

    /**
     * Suggestion functions
     */

    setReadyInput(bound, value) {
        this.setState((state) => {
            let readyInput = Object.assign({}, state.ready_input);
            readyInput[bound] = value;
            return {ready_input: readyInput};
        });
    }

    setNeedSuggestions(bound, value) {
        this.setState((state) => {
            let needSuggestions = Object.assign({}, state.need_suggestions);
            needSuggestions[bound] = value;
            return {need_suggestions: needSuggestions};
        });
    }

    showSuggestions = () => {
        return this.props.suggestions;
    };

    renderSuggestion = suggestion => (
        <a className={"dropdown-item"} type={"button"}>
            {suggestion.value}
        </a>
    );

    loadSuggestions = (bound, value) => {
        this.props.dispatch(
            getSuggestions(bound, this.getFilledUnitsAbove(bound, value), false,
                () => this.setNeedSuggestions(bound, true))
        );
    };

    emptyBoundsBelow(bound) {
        let newAddress = Object.assign({}, this.state.address);
        let reachedGivenBound = false;
        for (const b of bounds) {
            if (reachedGivenBound) {
                newAddress[b].text_value = '';
                newAddress[b].fias_code = '';
                this.setReadyInput(b, false);
            }
            if (b === bound) {
                reachedGivenBound = true;
            }
        }
        this.setState({
            address: newAddress
        });
    }

    getFilledUnitsAbove(bound, value) {
        let query = {};
        for (let b of bounds) {
            if (b === bound) {
                break;
            }
            query[b] = this.state.address[b].fias_code;
        }
        query[bound] = value;
        return query;
    }

    onChange = (bound) => (event, {newValue, method}) => {

        if (["down", "up"].indexOf(method) !== -1) {
            return;
        }

        if (method === 'type') {
            this.setReadyInput(bound, false);
            clearTimeout(this.timeoutCall);
            this.timeoutCall = setTimeout(() => {
                this.loadSuggestions(bound, newValue)
            }, 500);
        }
        if (['enter', 'click'].indexOf(method) !== -1) {
            this.setReadyInput(bound, true);
        }

        this.emptyBoundsBelow(bound);

        let newAddress = Object.assign({}, this.state.address);
        if (newValue === Object(newValue)) {
            for (let b of bounds) {
                newAddress[b] = newValue.address[b];
                if (newAddress[b].text_value) {
                    this.setReadyInput(b, true);
                }
            }
        } else {
            newAddress[bound].text_value = newValue;
            newAddress[bound].fias_code = '';
        }
        this.setState({
            address: newAddress,
        });
    };

    onSuggestionsFetchRequested = (bound) => () => {
        this.setNeedSuggestions(bound, true);
    };

    onSuggestionsClearRequested = (bound) => () => {
        clearTimeout(this.timeoutCall);
        this.setNeedSuggestions(bound, false);
    };

    onSuggestionSelected = (bound) => () => {
        this.setReadyInput(bound, true);
    };

    guessAddress() {
        this.setState({putSuggested: false});
        let minBound = null;
        let checkCondition = (bound) => {
            let value = this.state.address[bound].text_value;
            return value !== null && value.trim() !== '';
        };
        for (let bound of bounds) {
            if (checkCondition(bound)) {
                minBound = bound;
            }
        }
        if (minBound === null) {
            this.props.dispatch(getSuggestions('house', {house: ''}, true));
            return;
        }
        let addressUnits = [];
        for (let bound of bounds) {
            if (checkCondition(bound)) {
                addressUnits.unshift(this.state.address[bound].text_value);
            }
        }
        let query = {};
        query[minBound] = addressUnits.join(', ');
        this.props.dispatch(getSuggestions(minBound, query, true));
    }

    emptyAddress() {
        let newAddress = Object.assign({}, this.state.address);
        for (let bound of bounds) {
            newAddress[bound] = {
                text_value: '',
                fias_code: '',
            };
            this.setReadyInput(bound, false);
            this.setNeedSuggestions(bound, false);
        }
        this.setState({address: newAddress});
    }

    /**
     * Field renderers
     */

    createFormField(field, onChange, formType) {
        let property = getProperty(field, this.state.row);
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={4} sm={3} xs={12}>
                            <FormLabel>{trans('passport_changes.' + field)}</FormLabel>
                        </Col>
                        <Col md={8} sm={9} xs={12}>
                            <Form.Control
                                componentClass={formType}
                                type={"text"}
                                defaultValue={property ? property : ''}
                                onChange={(e) => {
                                    e.persist();
                                    if (onChange) return onChange(e);
                                    this.setState((state) => ({
                                        row: {
                                            ...state.row,
                                            [field]: e.target.value
                                        }
                                    }));
                                }}
                                readOnly={this.state.disabled}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createStringField(field, onChange = null) {
        return this.createFormField(field, onChange, 'input');
    }

    createTextField(field, onChange = null) {
        return this.createFormField(field, onChange, 'textarea');
    }

    findFirstDefinedProperty(fields) {
        if (!Array.isArray(fields))
            return fields;
        for (const field of fields) {
            if (getProperty(field, this.state) !== null) {
                return field;
            }
        }
        return null;
    }

    createBlockedFormField(field) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={2} sm={3} xs={12}>
                            <FormLabel>{trans('passport_changes.' + field)}</FormLabel>
                        </Col>
                        <Col md={4} sm={9} xs={12}>
                            <FormControl
                                type="text"
                                value={getProperty(field, this.state)}
                                readOnly={true}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createDateField(field) {
        let fieldComponent = (<FormControl type="text" value={this.state.row[field]} readOnly={true}/>);
        if (!this.state.disabled) {
            fieldComponent = (<Datetime dateFormat="DD.MM.YYYY"
                                        timeFormat={false}
                                        locale={"ru"}
                                        value={this.state.row[field]}
                                        closeOnSelect={true}
                                        onChange={(e) => {
                                            let date = formatDateInput(e);
                                            this.setState((state) => ({
                                                row: {
                                                    ...state.row,
                                                    [field]: date
                                                }
                                            }));
                                        }}
            />);
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col md={2} sm={3} xs={12}>
                            <FormLabel>{trans('passport_changes.' + field)}</FormLabel>
                        </Col>
                        <Col md={4} sm={9} xs={12}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createAddressField(bound) {
        let val = this.state.address[bound].text_value;
        const inputProps = {
            value: val ? val : '',
            onChange: this.onChange(bound)
        };

        const theme = {
            container: 'autosuggest',
            input: 'form-control',
            suggestionsContainer: 'dropdown',
            suggestionsList: 'dropdown-menu show'
        };

        let fieldComponent = (<FormControl type="text" value={val} readOnly={true}/>);
        if (!this.state.disabled) {
            fieldComponent = (<Autosuggest
                id={bound}
                theme={theme}
                suggestions={
                    this.props.suggestions[bound] &&
                    this.state.need_suggestions[bound] &&
                    !this.state.ready_input[bound] ? this.props.suggestions[bound] : []
                }
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested(bound)}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested(bound)}
                onSuggestionSelected={this.onSuggestionSelected(bound)}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                readOnly={this.state.disabled}
            />);
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col md={2} sm={3} xs={12}>
                            <FormLabel>{trans('passport_changes.address.' + bound)}</FormLabel>
                        </Col>
                        <Col md={4} sm={9} xs={12}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    render() {
        const {
            row,
        } = this.state;

        const {
            last_name,
            first_name,
            patronymic
        } = this.initialState.row;

        return (
            <Modal show={true} onHide={this.close} bsSize="xl" dialogClassName={"passport-modal"} size="xl">
                {
                    this.state.showRejectModal &&
                    <PassportRejectModal
                        onCloseClick={() => this.setState({showRejectModal: false})}
                        request_id={this.props.row.request_id}
                        comment={this.state.row.comment}
                    />
                }
                <Modal.Header closeButton>
                    <Modal.Title>Заявка клиента {[last_name, first_name, patronymic].join(" ")}</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    {row.status === DocumentChangeStatus.REJECTED_BY_OPERATOR &&
                        <div className={'reason-header'}><strong style={{color: 'red'}}>Заявка отклонена
                            оператором.<br/>Комментарий
                            оператора: {row.reason}</strong>
                        </div>
                    }
                    <Container className="modal-cont">
                        <Row>
                            <Col sm={24} md={12} xs={24}>
                                <Form horizontal ref="form">
                                    <FormGroup bsSize="small">
                                        {this.createStringField("last_name")}
                                        {this.createStringField("first_name")}
                                        {this.createStringField("patronymic")}
                                        {this.createStringField("serial")}
                                        {this.createStringField("number")}
                                        {this.createStringField("issued_by")}
                                        {this.createDateField("issued_at")}
                                        {this.createStringField("issue_dep")}
                                    </FormGroup>
                                    <FormGroup bsSize={"small"}>
                                        {this.createAddressField("region")}
                                        {this.createAddressField("area")}
                                        {this.createAddressField("city")}
                                        {this.createAddressField("settlement")}
                                        {this.createAddressField("street")}
                                        {this.createAddressField("house")}
                                        {this.createStringField("address.flat", (e) => {
                                            this.setState((state) => ({
                                                row: {
                                                    ...state.row,
                                                    address: {
                                                        ...state.row.address,
                                                        flat: e.target.value
                                                    }
                                                }
                                            }))
                                        })}
                                        {this.createStringField("address.index", (e) => {
                                            this.setState((state) => ({
                                                row: {
                                                    ...state.row,
                                                    address: {
                                                        ...state.row.address,
                                                        index: e.target.value
                                                    }
                                                }
                                            }))
                                        })}
                                        {this.createBlockedFormField(
                                            this.findFirstDefinedProperty([
                                                "address.street.fias_code",
                                                "address.city.fias_code",
                                                "address.settlement.fias_code"
                                            ]))
                                        }
                                        {this.createTextField('comment')}
                                    </FormGroup>
                                </Form>
                                <Button className={"address-request-btn"} onClick={this.returnToInitialRow}
                                        disabled={this.state.disabled}>Сброс</Button>
                                <Button className={"address-request-btn"} onClick={this.guessAddress}
                                        disabled={this.state.disabled}>Заполнить
                                    адрес</Button>
                                <Button className={"address-request-btn"} onClick={this.emptyAddress}
                                        disabled={this.state.disabled}>Очистить
                                    адрес</Button>
                            </Col>


                        </Row>
                        <Row sm={24} md={12} xs={24} className={"align-self-center"}>
                            <div>

                            </div>
                        </Row>
                    </Container>
                    <br/>
                    <PictureCarousel
                        pictures={[row.first_page_photo, row.registration_page_photo]}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="pull-left " onClick={this.close}>Закрыть</Button>
                    <Button className="btn btn-info" onClick={this.handleSaveBtnClick}
                            disabled={this.state.disabled}>Cохранить</Button>
                    <Button className="btn btn-danger" onClick={this.handleRejectBtnClick}
                            disabled={this.state.disabled}>Отклонить</Button>
                    <Button className="btn btn-success" onClick={this.handleAcceptBtnClick}
                            disabled={this.state.disabled}>Подтвердить</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    let fillAddress = state.fillAddressSuggestions.data || [];

    let isFetching = state.fillAddressSuggestions === null ? false : state.fillAddressSuggestions.isFetching;
    return {
        fetchingSuggestions: isFetching,
        suggestions: state.updateAddressSuggestions.data || [{}],
        fillAddress: fillAddress,
    };
}

export default connect(mapStateToProps)(PassportChangeHandleModal);
