/**
 * Created by anton on 5/8/17.
 */

import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, Tab, Tabs} from "react-bootstrap";
import {connect} from "react-redux";
import ClientInfo from "../containers/Client/ClientInfo";
import BootstrapTable from 'react-bootstrap-table-next';
import CreditInfo from "../containers/Client/CreditInfo";
import {humanReadableFileSize, trans} from "../utils/index";

import {
    dateFormatter,
    datetimeFormatter,
    getDatetimeOrNullFormatter,
    moneyFormatter,
    yesNoFormatter
} from "../utils/formatters";

import "./styles/client.scss";
import MessageStatus from "../constants/MessageStatus";
import {Link} from "react-router-dom";
import AddInsuranceManually from "./AddInsuranceManually";
import CertificateRequest from "./CertificateRequest";
import AddExtraAgreement from "./AddExtraAgreement";
import RevokeExtraAgreement from "./RevokeExtraAgreement";
import ExtraAgreementMetadata from "./ExtraAgreementMetadata";

class Client extends React.Component {

    constructor(props) {
        super(props);

        const {data} = props;
        this.state = {
            user: data,
            showOperationsModal: false,
            currentOperations: [],
            activeCreditPanel: false,
            prolongationSchedules: [],
            onClientDataChanged: props.onClientDataChanged
        };

        this.isBankEmployee = props.isBankEmployee;
        this.closeModal = ::this.closeModal.bind(this);
        this.openModal = ::this.openModal.bind(this);
        this.operationDescription = ::this.operationDescription;
        this.creditDescription = ::this.creditDescription;
        this.prolongationCreditDescription = ::this.prolongationCreditDescription;
    }

    static getHeaderColumn(field, type, props) {
        return {
            dataField: field,
            key: field,
            ...props,
            headerAlign: 'center',
            text: trans(type + field)
        }
    }

    static getPaymentHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'payments.', props);
    }

    static getProlongationScheduleHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'credit_prolongation_schedules.', props);
    }

    static getOperationsHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'operations.', props);
    }

    static getCertificatesHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'certificates.', props);
    }

    static getLetterToBankHeaderColumn(field, props) {

        return {
            dataField: field,
            key: field,
            ...props,
            headerAlign: 'center',
            text: trans('letters_to_bank.' + field)
        };
    }

    static getPDPRequestsHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'pdp.', props);
    }

    static getPaymentOperationColumn(field, props) {
        return this.getHeaderColumn(field, 'mobile_payments.', props);
    }

    static getPDPConditionsHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'pdp_conditions.', props);
    }

    static getInsurancesHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'insurances.', props);
    }

    static getMessagesHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'messages.', props);
    }

    static getCardInsurancesHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'card_insurances.', props);
    }

    static getExtraAgreementsHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'extra_agreements.', props);
    }


    static getCreditProlongationsHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'credit_prolongations.', props);
    }

    static getLoanLimitHeaderColumn(field, props) {
        return this.getHeaderColumn(field, 'loan_limit.', props);
    }


    static trClassFormat(rowData, rowIndex) {
        switch (rowData.status) {
            case MessageStatus.STATUS_NOT_DELIVERED:
                return 'tr-message-status-not-delivered';
            case MessageStatus.STATUS_NOT_SENT:
                return 'tr-message-status-not-sent';
            case MessageStatus.STATUS_UNKNOWN:
                return 'tr-message-status-unknown';
            default:
                return '';
        }
    }

    closeModal() {
        this.setState({showOperationsModal: false})
    }

    openModal(row) {
        this.setState({
            showOperationsModal: true,
            currentOperations: row.operations
        })
    }

    operationDescription(cell, row) {
        const paymentType = trans('operations.payment');

        return (
            <div className="button-inspect">
                {row.in_progress ? trans('operations.best2pay_payment') : cell}
                {(row.operation_type === paymentType && row.operations.length > 1) ?
                    <Button
                        type="button"
                        onClick={() => {
                            this.openModal(row)
                        }}>
                        Подробнее
                    </Button> : <div/>
                }
            </div>
        )
    }

    creditDescription(cell, row, rowIndex, formatterExtra) {
        const credit = formatterExtra.find((credit) => Number(credit.CRED_ID) === Number(cell));
        if (credit === undefined || credit === null) {
            return <div>No credit</div>
        }
        return (
            <div>
                <p>{credit.PRODUCT_NAME + ' (' + credit.CONTRACT_NUMBER + ')'}</p>

                <Button type="button"
                        onClick={() => {
                            this.setState({
                                "activeKey": 1,
                                "activeCreditPanel": Number(credit.CRED_ID)
                            });
                        }}
                >Перейти</Button>
            </div>
        );
    }

    prolongationCreditDescription(cell, row, rowIndex, formatterExtra) {
        const [credits, prolongationCredits] = formatterExtra;
        const credit = credits.find((credit) => Number(credit.CRED_ID) === Number(cell));
        const prolongationCredit = prolongationCredits[row['counter'] - 1];

        if (credit === undefined || credit === null) {
            return <div/>
        }
        return (
            <div>
                {credit.PRODUCT_NAME + ' (' + credit.CONTRACT_NUMBER + ')'}
                <Button type="button"
                        onClick={() => {
                            this.setState({
                                "prolongationSchedules": prolongationCredit.schedules
                            });
                        }}
                >Показать график</Button>
            </div>
        );

    }

    letterToBankFileFormatter(cell, row, rowIndex, formatterExtra) {
        return <div>
            {
                cell.map((file, i) => {
                    return <p key={i}>
                        <a href={file.link}>
                            <p>{file.name} ({humanReadableFileSize(file.length)})</p>
                        </a>
                    </p>
                })
            }
        </div>;
    }

    static linkFormatter(cell, row, rowIndex, formatterExtra) {
        return (
            <a href={cell} componentClass={Link} target='_blank'>Прочитать</a>
        )
    }

    static linkFormatterLabel(label) {
        return function(cell, row, rowIndex, formatterExtra){
            return (<a href={cell} target='_blank'>{label}</a>)
        };
    }


    render() {
        this.state.user = this.props.clientData; // for data updates after any changes

        const fields = [
            'FIO',
            'BIRTH_DATE',
            'EMAIL',
            'MOBILE',
            'CLIENT',
            'passport_number',
            'passport_series',
            'REG_ADDR',
            'LIFE_ADDR',
            'VERSION',
            'DEVICE'
        ];
        const operationTypeSearchOptions = [
            trans('operations.income'),
            trans('operations.payment')
        ];

        const {
            client,
            account,
            credits,
            operations,
            certificates,
            pdp,
            pdp_conditions,
            insurances,
            payment_operations,
            autopayments,
            messages,
            card_insurances,
            credit_prolongations,
            loan_limit,
            credit_prolongation_banners,
            letters_to_bank,
            extra_agreements,
            errors,
        } = this.state.user;

        return (
            <div>
                <Tabs defaultActiveKey={0} id="ClientFullInfo" activeKey={this.state.activeKey}
                      onSelect={(key, event) => {
                          this.setState({"activeKey": key});

                      }}
                >
                    <Tab eventKey={0} key={0} title="Информация о клиенте">
                        <ClientInfo
                            account={account} client={client} fields={fields}/>
                    </Tab>

                    <Tab ref="table_credits" eventKey={1} key={1} title="Информация о кредитах клиента">
                        <CreditInfo credits={credits} activeCreditPanel={Number(this.state.activeCreditPanel)} kl/>
                    </Tab>

                    <Tab ref="table1" eventKey={2} key={2} title="Выписка клиента">

                        <div style={{
                            textAlign: "center"
                        }}>
                            <b>Текущий баланс: </b>
                            {moneyFormatter(account.ACC_BALANCE, [], account.CUR)}
                        </div>
                        {
                            (errors.indexOf('EMPTY_R_AM_AFTER') !== -1) && <div style={{
                                textAlign: "center"
                            }}>
                                <b style={{'color':'red'}}>Ошибка построения выписки: не заполнено поле R_AM_AFTER в графике платежей. Необходимо
                                    исправить график платежей в ЦФТ</b>
                            </div>
                        }

                        <br/>
                        <BootstrapTable data={operations} keyField='counter' columns={[
                            Client.getOperationsHeaderColumn('counter', {
                                'width': '50',
                                'isKey': true
                            }),
                            Client.getOperationsHeaderColumn('operation_type', {
                                'width': '150',
                                'formatter': (x) => x,
                                /*   'filterValue': function (cell, row) {
                                       return operationTypeSearchOptions.indexOf(cell)
                                   },
                                   'filter': selectFilter({
                                       type: 'SelectFilter',
                                       placeholder: "Все операции",
                                       filterValue: parseFloat,

                                       options: operationTypeSearchOptions,
                                       condition: "like"

                                   }),*/
                            }),
                            Client.getOperationsHeaderColumn('amount', {
                                'width': '200',
                                'formatter': function (cell, row) {
                                    return moneyFormatter(cell, row, row.operations[0].AMOUNT_CUR)
                                },
                                /* 'filterValue': parseFloat,

                                 'filter': numberFilter({
                                     type: 'NumberFilter',

                                     placeholder: 'Введите сумму операции',
                                     numberComparators: ['=', '>', '<'],
                                     delay: 100
                                 }),*/
                            }),
                            Client.getOperationsHeaderColumn('description', {
                                'formatter': this.operationDescription
                            }),
                            Client.getOperationsHeaderColumn('date', {
                                'width': '150',
                                'formatter': dateFormatter,
                                /* 'filter': customFilter({
                                     'type': 'CustomFilter',
                                     'getElement': createStatementDateFilter,
                                     'placeholder': 'Фильтр по дате',
                                     'customFilterParameters': {
                                         'lowerBound': operations.length > 0 && operations[operations.length - 1].date,
                                         'upperBound': operations.length > 0 && operations[0].date
                                     }
                                 })*/
                            }),
                            Client.getOperationsHeaderColumn('SALDO_OUT', {
                                'width': '200',
                                'formatter': function (cell, row) {
                                    return moneyFormatter(cell, row, row.operations[0].AMOUNT_CUR)
                                },
                                /*'filterValue': parseFloat,
                                'filter': numberFilter({
                                    'type': 'NumberFilter',

                                    'placeholder': 'Введите исходящий остаток',
                                    'numberComparators': ['=', '>', '<'],

                                })*/
                            })

                        ]}/>
                    </Tab>

                    <Tab ref="tab_kasko" eventKey={5} key={5} title="КАСКО">
                        <BootstrapTable data={insurances} keyField='CRED_ID' columns={[
                            Client.getInsurancesHeaderColumn("CRED_ID", {
                                'isKey': true,
                                'formatter': this.creditDescription,
                                'formatExtraData': credits
                            }),
                            Client.getInsurancesHeaderColumn("DATE_EXP", {}),
                            Client.getInsurancesHeaderColumn("INSUR_NAME", {}),
                            Client.getInsurancesHeaderColumn("INSUR_NUMBER", {})
                        ]}>
                        </BootstrapTable>
                    </Tab>

                    <Tab ref="table2" eventKey={3} key={3} title="Запросы справок">
                        <BootstrapTable keyField='counter'
                                        data={certificates}
                                        trClassName={(rowData, rowIndex) => {
                                            if (rowData.abs_status) {
                                                return 'tr-message-status-not-delivered';
                                            }
                                            return '';
                                        }}
                                        columns={[
                                            Client.getCertificatesHeaderColumn('counter', {
                                                'width': '50',
                                                'isKey': true
                                            }),
                                            Client.getCertificatesHeaderColumn('request_date', {
                                                'formatter': dateFormatter,
                                                'width': '150'
                                            }),
                                            Client.getCertificatesHeaderColumn('gathered_name'),
                                            Client.getCertificatesHeaderColumn('destination'),
                                            Client.getCertificatesHeaderColumn('gathered_status'),
                                            Client.getCertificatesHeaderColumn('created_by'),
                                        ]
                                        }

                        >
                        </BootstrapTable>
                    </Tab>

                    <Tab ref="table2" eventKey={13} key={13} title="Запросы в банк">
                        <BootstrapTable keyField='id'
                                        data={letters_to_bank}
                                        columns={[
                                            Client.getLetterToBankHeaderColumn('id', {
                                                'width': '100',
                                                'isKey': true
                                            }),
                                            Client.getLetterToBankHeaderColumn('signed_at', {
                                                'formatter': datetimeFormatter,
                                            }),
                                            Client.getLetterToBankHeaderColumn('topic'),
                                            Client.getLetterToBankHeaderColumn('text', {
                                                'width': '500'
                                            }),
                                            Client.getLetterToBankHeaderColumn('files', {
                                                'formatter': this.letterToBankFileFormatter
                                            })
                                        ]
                                        }
                        >
                        </BootstrapTable>
                    </Tab>

                    {
                        <Tab ref="table3" eventKey={4} key={4} title="Досрочное погашение">
                            <h3> Условия погашения</h3>
                            <BootstrapTable data={pdp_conditions} keyField='CRED_ID' columns={[
                                Client.getPDPConditionsHeaderColumn("CRED_ID", {
                                    'isKey': true,
                                    'formatter': this.creditDescription,
                                    'formatExtraData': credits
                                }),
                                Client.getPDPConditionsHeaderColumn("DATE_PDP"),
                                Client.getPDPConditionsHeaderColumn("SM_PDP", {
                                    'formatter': moneyFormatter,
                                }),
                                Client.getPDPConditionsHeaderColumn("REASON")
                            ]}>
                            </BootstrapTable>

                            <br/>
                            <br/>
                            <h3>Запросы на погашение</h3>
                            <BootstrapTable data={pdp} keyField={'counter'} columns={
                                [
                                    Client.getPDPRequestsHeaderColumn('counter', {
                                        'width': '50',
                                        'isKey': true
                                    }),

                                    Client.getPDPRequestsHeaderColumn('CRED_ID', {
                                        'formatter': this.creditDescription,
                                        'formatExtraData': credits
                                    }),
                                    Client.getPDPRequestsHeaderColumn('TYPE_OPER', {
                                        'width': '100'
                                    }),
                                    Client.getPDPRequestsHeaderColumn('SUM_OP', {
                                        'width': '100',
                                        'formatter': moneyFormatter

                                    }),
                                    Client.getPDPRequestsHeaderColumn('request_date',
                                        {
                                            'formatter': dateFormatter
                                        }),
                                    Client.getPDPRequestsHeaderColumn('DATE_OP',
                                        {
                                            'formatter': dateFormatter
                                        }),
                                    Client.getPDPRequestsHeaderColumn('PAR_GR'),
                                    Client.getPDPRequestsHeaderColumn('status'),
                                    Client.getPDPRequestsHeaderColumn('REASON',
                                        {
                                            'width': '300'
                                        })
                                ]
                            }>
                            </BootstrapTable>
                        </Tab>}

                    <Tab title="Операции B2P" eventKey={6} key={6}>
                        <BootstrapTable data={payment_operations} keyField='counter' columns={[
                            Client.getPaymentOperationColumn('counter', {
                                'isKey': true,
                                'width': 50
                            }),
                            Client.getPaymentOperationColumn('amount', {
                                'formatter': moneyFormatter
                            }),
                            Client.getPaymentOperationColumn('fee_amount', {
                                'formatter': moneyFormatter
                            }),
                            Client.getPaymentOperationColumn('description', {
                                'width': 400
                            }),
                            Client.getPaymentOperationColumn("source"),
                            Client.getPaymentOperationColumn("pan"),
                            Client.getPaymentOperationColumn('datetime',
                                {
                                    'formatter': datetimeFormatter
                                })]}>
                        </BootstrapTable>
                    </Tab>

                    <Tab title="Платежи B2P" eventKey={12} key={12}>
                        <BootstrapTable data={autopayments} keyField='counter' columns={[
                            Client.getPaymentOperationColumn('counter', {
                                'isKey': true,
                                'width': 50
                            }),
                            Client.getPaymentOperationColumn('amount', {
                                'formatter': moneyFormatter
                            }),
                            Client.getPaymentOperationColumn('fee_amount', {
                                'formatter': moneyFormatter
                            }),
                            Client.getPaymentOperationColumn('is_repeatable', {
                                'formatter': yesNoFormatter
                            }),
                            Client.getPaymentOperationColumn('date_begin', {
                                'formatter': dateFormatter
                            }),
                            Client.getPaymentOperationColumn('day_of_month'),
                            Client.getPaymentOperationColumn('date_end', {
                                'formatter': dateFormatter
                            }),
                            Client.getPaymentOperationColumn("phase"),
                            Client.getPaymentOperationColumn("source"),
                            Client.getPaymentOperationColumn("pan")
                        ]}>
                        </BootstrapTable>
                    </Tab>

                    <Tab title="SMS-cообщения" eventKey={7} key={7}>
                        <BootstrapTable data={messages} keyField='counter' trClassName={Client.trClassFormat} columns={[
                            Client.getMessagesHeaderColumn('counter', {
                                'isKey': true,
                                'width': 50
                            }),
                            Client.getMessagesHeaderColumn("target", {
                                'width': 120
                            }),
                            Client.getMessagesHeaderColumn("content"),
                            Client.getMessagesHeaderColumn("status_text", {
                                'width': 250
                            }),
                            Client.getMessagesHeaderColumn("created_at", {
                                'width': 150,
                                'formatter': datetimeFormatter
                            })
                        ]}>
                        </BootstrapTable>
                    </Tab>
                    <Tab title="Защита карт" eventKey={9} key={9}>
                        <BootstrapTable data={card_insurances} keyField='counter' trClassName={(rowData, rowIndex) => {
                            if (!rowData.is_active) {
                                return 'tr-card-insurance-inactive';
                            }
                            return '';
                        }} columns={[
                            Client.getCardInsurancesHeaderColumn('counter', {
                                'isKey': true,
                                'width': 50
                            }),
                            Client.getCardInsurancesHeaderColumn("insurer"),
                            Client.getCardInsurancesHeaderColumn("policy_id", {
                                'width': 120
                            }),
                            Client.getCardInsurancesHeaderColumn("status", {
                                'width': 120
                            }),
                            Client.getCardInsurancesHeaderColumn("created_at", {
                                'formatter': dateFormatter
                            }),
                            Client.getCardInsurancesHeaderColumn("begins_at", {
                                'formatter': dateFormatter
                            }),
                            Client.getCardInsurancesHeaderColumn("ends_at", {
                                'width': 150,
                                'formatter': dateFormatter
                            }),
                            Client.getCardInsurancesHeaderColumn("link", {
                                'width': 150,
                                'formatter': Client.linkFormatter
                            })
                        ]}>

                        </BootstrapTable>
                    </Tab>

                    <Tab title="Дополнительные соглашения" eventKey={8} key={8}>
                        <BootstrapTable data={credit_prolongations} keyField='counter'
                                        trClassName={Client.trClassFormat} columns={
                            [
                                Client.getCreditProlongationsHeaderColumn('counter', {
                                    'isKey': true,
                                    'width': 50
                                }),
                                Client.getCreditProlongationsHeaderColumn("CRED_ID", {
                                    'width': 120,
                                    'formatter': this.creditDescription,
                                    'formatExtraData': credits
                                }),
                                Client.getCreditProlongationsHeaderColumn("description"),
                                Client.getCreditProlongationsHeaderColumn("type"),
                                Client.getCreditProlongationsHeaderColumn("signed_at", {
                                    'width': 250,
                                    'formatter': datetimeFormatter
                                }),
                                Client.getCreditProlongationsHeaderColumn("agreement_link", {
                                    'width': 150,
                                    'formatter': Client.linkFormatter
                                })
                            ]}>
                        </BootstrapTable>
                    </Tab>

                    <Tab title="Пролонгация/реструктуризация" eventKey={10} key={10}>
                        <BootstrapTable data={credit_prolongation_banners} keyField='counter'
                                        trClassName={Client.trClassFormat} columns={
                            [
                                Client.getCreditProlongationsHeaderColumn('counter', {
                                    'isKey': true,
                                    'width': 50
                                }),
                                Client.getCreditProlongationsHeaderColumn("CRED_ID", {
                                    'width': 250,
                                    'formatter': this.prolongationCreditDescription,
                                    'formatExtraData': [credits, credit_prolongation_banners],
                                }),
                                Client.getCreditProlongationsHeaderColumn("type"),
                                Client.getCreditProlongationsHeaderColumn("from", {
                                    'formatter': datetimeFormatter
                                }),
                                Client.getCreditProlongationsHeaderColumn("to", {
                                    'formatter': datetimeFormatter
                                }),
                                Client.getCreditProlongationsHeaderColumn("clicked_at", {
                                    'formatter': getDatetimeOrNullFormatter('Нет'),
                                }),
                                Client.getCreditProlongationsHeaderColumn("status", {})
                            ]}>
                        </BootstrapTable>


                        <BootstrapTable data={this.state.prolongationSchedules} keyField='key' columns={
                            [
                                Client.getProlongationScheduleHeaderColumn('key', {
                                    'width': '50',
                                    'isKey': true
                                }),
                                Client.getProlongationScheduleHeaderColumn('status', {
                                    'width': '150'
                                }),
                                Client.getProlongationScheduleHeaderColumn('date',
                                    {
                                        'formatter': dateFormatter
                                    }),
                                Client.getProlongationScheduleHeaderColumn('total_amount', {
                                    'formatter': moneyFormatter,
                                    'formatExtraData': 643
                                }),
                                Client.getProlongationScheduleHeaderColumn('saldo_out', {
                                    'formatter': moneyFormatter,
                                    'formatExtraData': 643,
                                }),
                                Client.getProlongationScheduleHeaderColumn('body', {
                                    'formatter': moneyFormatter,
                                    'formatExtraData': 643
                                }),
                                Client.getProlongationScheduleHeaderColumn('percents', {
                                    'formatter': moneyFormatter,
                                    'formatExtraData': 643
                                }),
                                Client.getProlongationScheduleHeaderColumn('overdue', {
                                    'formatter': (cell, row) => cell ? "Просрочен" : ""
                                }),
                                Client.getProlongationScheduleHeaderColumn('overdue_amount', {
                                    'formatter': moneyFormatter,
                                    'formatExtraData': 643
                                })
                            ]
                        }>

                        </BootstrapTable>

                    </Tab>

                    <Tab title="Кредитный лимит" eventKey={11} key={11}>
                        <BootstrapTable data={loan_limit} keyField='counter' trClassName={Client.trClassFormat}
                                        columns={
                                            [
                                                Client.getLoanLimitHeaderColumn('counter', {
                                                    'isKey': true,
                                                    'width': 50
                                                }),
                                                Client.getLoanLimitHeaderColumn("CRED_ID", {
                                                    'width': 120,
                                                    'formatter': this.creditDescription,
                                                    'formatExtraData': credits
                                                }),
                                                Client.getLoanLimitHeaderColumn("product"),
                                                Client.getLoanLimitHeaderColumn("loan_amount", {
                                                    'formatter': moneyFormatter
                                                }),
                                                Client.getLoanLimitHeaderColumn("b2p_amount", {
                                                    'formatter': moneyFormatter
                                                }),
                                                Client.getLoanLimitHeaderColumn("loan_limit_snapshot", {
                                                    'formatter': moneyFormatter
                                                }),
                                                Client.getLoanLimitHeaderColumn("status"),
                                                Client.getLoanLimitHeaderColumn("cft_error"),

                                                Client.getLoanLimitHeaderColumn("sms_confirmed_at", {
                                                    'formatter': datetimeFormatter
                                                }),
                                                Client.getLoanLimitHeaderColumn("payment_plan_pdf_link", {
                                                    'formatter': Client.linkFormatter
                                                })
                                            ]}>
                        </BootstrapTable>
                    </Tab>
                    <Tab title={'Создать заявку'} eventKey={14} key={14}>
                        <AddInsuranceManually user={this.state.user}/>
                    </Tab>
                    <Tab title={'Заказать справку'} eventKey={15} key={15}>
                        <CertificateRequest user={this.state.user}/>
                    </Tab>
                    {this.isBankEmployee &&
                    <Tab title={'Загрузка ДС'} eventKey={16} key={16}>
                        <AddExtraAgreement user={this.state.user}
                                           onClientDataChanged={this.state.onClientDataChanged}/>
                        <BootstrapTable data={extra_agreements}
                                        keyField='id'
                                        columns={[
                                            Client.getExtraAgreementsHeaderColumn('id', {
                                                'isKey': true,
                                                'width': 50
                                            }),
                                            Client.getExtraAgreementsHeaderColumn("name", {
                                                'width': 80,
                                                'formatter': (cell, row) => {
                                                    return (<a href={row.file_link} target='_blank'>{cell}</a>)
                                                }
                                            }),
                                            Client.getExtraAgreementsHeaderColumn("creator_operator_id", {
                                                'width': 120,
                                                'formatter': (cell, row) => "Оператор " + cell
                                            }),
                                            Client.getExtraAgreementsHeaderColumn("created_at", {
                                                'width': 150,
                                                'formatter': dateFormatter
                                            }),
                                            Client.getExtraAgreementsHeaderColumn("status_description", {
                                                'width': 150,
                                                'formatter': (cell, row) => {
                                                    return (row.signed_at) ?
                                                        (<ExtraAgreementMetadata extra_agreement={row}/>): cell;
                                                }
                                            }),
                                            Client.getExtraAgreementsHeaderColumn("revoke", {
                                                'width': 150,
                                                'formatter': (cell, row) => {
                                                    return (row.can_be_revoked) ?
                                                            (<RevokeExtraAgreement
                                                                CLIENT={this.state.user.client.CLIENT}
                                                                extra_agreement={row}
                                                                onClientDataChanged={this.state.onClientDataChanged}/>): '';
                                                }
                                            }),
                                        ]}>

                        </BootstrapTable>
                    </Tab>
                    }
                </Tabs>


                <Modal show={this.state.showOperationsModal} onHide={this.closeModal}>
                    <ModalHeader closeButton>
                        <ModalTitle>Информация об операции</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <BootstrapTable data={this.state.currentOperations} keyField='counter'
                                        columns={
                                            [
                                                Client.getOperationsHeaderColumn('counter', {
                                                    'width': '50',
                                                    'isKey': true
                                                }),
                                                Client.getOperationsHeaderColumn('AMOUNT', {
                                                    'width': '80',
                                                    'formatter': function (cell, row) {
                                                        return moneyFormatter(cell, row, row.AMOUNT_CUR)
                                                    }
                                                }),
                                                Client.getOperationsHeaderColumn('DATE_DOC', {
                                                    'width': '150',
                                                    'formatter': dateFormatter,
                                                }),
                                                Client.getOperationsHeaderColumn('NAZN')

                                            ]
                                        }
                        >
                        </BootstrapTable>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.closeModal}>Закрыть</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
            ;
    }
}

export default connect(state => {
    return {
        data: state.clientsByQuery.items
    }
})(Client);
