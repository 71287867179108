/**
 * Created by gleb on 5/16/17.
 */
import React from 'react';
import {sendPaymentPointsFile} from '../actions/send_files';
import {connect} from 'react-redux';
import {FileUploadContainer} from '../containers/FileUploadContainter';

class PaymentPoint extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            file: null
        };
    }

    render() {
        return <FileUploadContainer
            header="Форма обновления точек оплаты"
            dispatchHandler={
                content => this.props.dispatch(sendPaymentPointsFile(content))
            }
            onChangeHandler={
                ref => this.setState({file:ref.target.files[0]})
            }
            file={this.state.file}
        />;
    }
}
export default connect(() => {
    return {}
}) (PaymentPoint);