/**
 * Created by anton on 5/4/17.
 */

import React from 'react';
import App from './containers/App';
import Clients from './components/Clients'
import { Router as BaseRouter} from 'react-router';
import history from "history/browser";
import LoadingClient from './components/LoadingClient';
import Reports from "./components/Reports";
import OtherTabs from "./components/OtherTabs";
import DocumentRequests from "./components/requests/DocumentRequests";
import {BrowserRouter, createBrowserRouter, Route, Routes} from "react-router-dom";
import AppFunctionComponent from "./containers/AppFunctionComponent";

const routes = [{
    path: '*',
    element: <AppFunctionComponent/>,
    children: [
        {path: 'clients/:id', element: <LoadingClient key={1}/>, key: 1},
        {path: 'clients', element: <Clients/>, key: 2},
        {path: 'document_requests', element: <DocumentRequests/>, key: 3},
        {path: 'misc', element: <OtherTabs/>, key: 4},
        {path: 'reports', element: <Reports/>, key: 5},
    ],
}];

const Router =  createBrowserRouter(routes);

export default Router;