/**
 * Created by gleb on 5/16/17.
 */
import React from 'react';
import {sendWorkdayFile} from '../actions/send_files';
import {connect} from 'react-redux';
import {FileUploadContainer} from '../containers/FileUploadContainter';

class Workday extends React.Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            file: null
        };
    }

    render() {
        return <FileUploadContainer
            header="Форма добавления рабочих дней"
            dispatchHandler={
                content => this.props.dispatch(sendWorkdayFile(content))
            }
            onChangeHandler={
                ref => this.setState({file:ref.target.files[0]})
            }
            file={this.state.file}
        />;
    }
}
export default connect(() => {
    return {}
}) (Workday);