const STORAGE_SIZE = 15;


class SearchHistoryStorage {
    constructor(localStorageKey) {
        this.key = localStorageKey;
    }


    getRecentSearches() {
        try {
            let item = localStorage.getItem(this.key);

            let json = JSON.parse(item);
            return json === null ? [] : json;
        }
        catch (e) {
            return [];
        }
    }

    push(user) {
        let storage = this.getRecentSearches();
        let data = {
            'FIO': user.FIO,
            'CLIENT': user.CLIENT,
        };
        storage.splice(STORAGE_SIZE);//remove elements on positions [STORAGE_SIZE:]

        storage.splice(0, 0, data); //push the new element
        let stringified = JSON.stringify(storage);
        localStorage.setItem(this.key, stringified);
    }

    clear() {
        localStorage.removeItem(this.key);
    }
}

const searchHistoryStorage = new SearchHistoryStorage('rnbank.search_history');

export default searchHistoryStorage ;