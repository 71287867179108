import React, {Component} from "react";
import {connect} from "react-redux";
import ReportComponent from "./ReportComponent";

class B2PReport extends Component {
    render() {
        return <ReportComponent
            route={'external/reports/payments'}
            fileName={'payments'}
        />
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(B2PReport);