import React, {Component} from "react";
import {Image} from "react-bootstrap";
import {connect} from "react-redux";
import {doneReset} from "../actions/interactive_image";

class InteractiveImage extends Component {
    defaultMatrixData = [1, 0, 0, 1, 0, 0];

    constructor(props) {
        super(props);

        this.state = {
            dimensions: {
                width: 0,
                height: 0,
            },
            dragData: {
                dx: 0,
                dy: 0,
                x: 0,
                y: 0,
            },
            dragging: false,
            matrixData: this.defaultMatrixData.slice(0),
        };

        this.onLoad = ::this.onLoad;
    }

    onLoad(obj) {
        this.props.onLoad(obj);
        let {target: img} = obj;
        this.setState({
            dimensions: {
                width: img.naturalWidth,
                height: img.naturalHeight
            }
        });
    }

    angleCoordinates = (x, y) => {
        let newX = x;
        let newY = y;
        switch (this.props.angle) {
            case 0:
                break;
            case 90:
                newX = y;
                newY = -x;
                break;
            case 180:
                newX = -x;
                newY = -y;
                break;
            case 270:
                newX = -y;
                newY = x;
                break;
        }
        return {
            newX,
            newY,
        };
    };

    onMouseDown = (e) => {
        console.log('onMouseDown');
        let {matrixData} = this.state;
        let offsetX = matrixData[4];
        let offsetY = matrixData[5];
        let {newX, newY} = this.angleCoordinates(e.pageX, e.pageY);
        let newDragData = {
            dx: offsetX,
            dy: offsetY,
            x: newX,
            y: newY,
        };
        this.setState({
            dragData: newDragData,
            dragging: true,
        });
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.preventDefault();
    };

    onMouseUp = () => {
        console.log('onMouseUp');

        this.setState({
            dragging: false,
        });
    };

    onMouseMove = (e) => {
        console.log('onMouseMove');

        if (this.state.dragging) {
            let matrixData = this.getNewMatrixData(e.pageX, e.pageY);
            this.setState({
                matrixData,
            });
        }
    };

    getNewMatrixData = (x, y) => {
        let {dragData, matrixData} = this.state;
        let {newX, newY} = this.angleCoordinates(x, y);
        let deltaX = dragData.x - newX;
        let deltaY = dragData.y - newY;
        matrixData[4] = dragData.dx - deltaX;
        matrixData[5] = dragData.dy - deltaY;
        return matrixData;
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.reset && nextProps.reset[this.props.id]) {
            this.setState({
                matrixData: this.defaultMatrixData.slice(0),
            });
            this.props.dispatch(doneReset(this.props.id));
        }
    }

    render() {
        let {width, height} = this.state.dimensions;
        let style = {
            transform: `matrix(${this.state.matrixData.toString()})`
        };
        if (width !== 0) {
            let xDimension = this.props.angle % 180 === 0 ? width : height;
            let yDimension = this.props.angle % 180 === 0 ? height : width;
            style.maxWidth = xDimension * this.props.zoom;
            style.maxHeight = yDimension * this.props.zoom;
        }
        return <div
            style={{
                overflow: 'hidden',
            }}
        >
            <div
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseMove={this.onMouseMove}
                style={{
                    ...this.props.style,
                    cursor: this.state.dragging ? 'pointer': '',
                }}
            >
                <Image
                    onLoad={this.onLoad}
                    style={style}
                    src={this.props.src}
                />
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    let reset = state.resetImage;
    return {
        reset: reset,
    };
}

export default connect(mapStateToProps)(InteractiveImage);
