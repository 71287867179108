import {axios} from "../utils/axios";
import {getQueryString} from "../utils";
import {errorPanel} from "./panels";
import {logout} from "./bank_person";

export const REQUEST_ADDRESS_SUGGESTIONS = 'REQUEST_ADDRESS_SUGGESTIONS';
export const FILL_ADDRESS_SUGGESTIONS = 'FILL_ADDRESS_SUGGESTIONS';
export const UPDATE_ADDRESS_SUGGESTIONS = "UPDATE_ADDRESS_SUGGESTIONS";

export function requestAddressSuggestions(suggest_unit, query, fill, callback) {
    return {
        type: REQUEST_ADDRESS_SUGGESTIONS,
        suggest_unit,
        query,
        fill,
        callback
    }
}

export function updateAddressSuggestions(bound, data) {
    return {
        type: UPDATE_ADDRESS_SUGGESTIONS,
        bound,
        data
    };
}

export function fillAddressSuggestions(data) {
    return {
        type: FILL_ADDRESS_SUGGESTIONS,
        data
    }
}

export function getSuggestions(suggest_unit, query = {}, fill = false, callback = () => {
}) {
    query.suggest_unit = suggest_unit;
    return dispatch => {
        if (fill) {
            dispatch(requestAddressSuggestions(suggest_unit, query, fill, callback));
        }
        axios(getQueryString('external/documents/address/suggest?', query))
            .then(response => {
                if (fill) {
                    dispatch(fillAddressSuggestions(response.data.suggestions));
                } else {
                    dispatch(updateAddressSuggestions(suggest_unit, response.data.suggestions));
                }
                callback();
            })
            .catch(error => {
                    if (error.response.status === 403) {
                        dispatch(logout()); //force logout on 403
                    }
                    dispatch(errorPanel("Произошла ошибка"))
                }
            );
    }
}