import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";

class ExtraAgreementMetadata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            extra_agreement: props.extra_agreement,
            showModal: false,
        };
        this.getModal = ::this.getModal;
        this.close = ::this.close;
    }

    close() {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                showModal: false
            };
        });
    }

    getModal() {
        return (
            <Modal show={true} bsSize={'lg'} onHide={this.close} dialogClassName={'reject-passport-modal'}>
                <ModalHeader closeButton>
                    <ModalTitle>Метаданные "{this.state.extra_agreement.name}"</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div style={{whiteSpace: "pre-wrap", wordBreak: "break-word"}}>
                    {this.state.extra_agreement.check_chain}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.close}>Закрыть</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const modal = this;

        return <div>
            <a href="#" target='_blank'
                onClick={(e) =>
                {
                    e.preventDefault();
                    modal.setState((prevState, props) => {
                    return {
                        ...prevState,
                        showModal: true
                    };
                })}}>{this.state.extra_agreement.status_description}</a>

            {this.state.showModal ? this.getModal() : <p/>}
        </div>
            ;
    }
}

function mapStateToProps(state) {
    return {
        row: {},
    };
}

export default connect(mapStateToProps)(ExtraAgreementMetadata);