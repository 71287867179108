import React, {Component} from "react";
import {connect} from "react-redux";
import ReportComponent from "./ReportComponent";
import {loadEosagoStats} from "../actions/stats";
import {intervalFormatter} from "../utils";

class EOsagoStats extends Component {

    constructor(props) {
        super(props);
        this.generateTables = ::this.generateTables;
    }

    generateTables() {
        let dataTable = {
            name: 'Статистика статусов расчёта',
            headers: [
                {field: 'insurer', name: 'СК', props: {isKey: true}},
                {field: 'status', name: 'Статус'},
                {field: 'count', name: 'Количество'}
            ],
            data: this.props.data,
            keyField :"insurer"
        };

        let averageTable = {
            name: 'Статистика времени расчёта',
            headers: [
                {field: 'insurer', name: 'СК', props: {isKey: true}},
                {field: 'count', name: 'Количество'},
                {field: 'premium_calc', name: 'Среднее время расчёта премии', props: {dataFormat: intervalFormatter}},
                {field: 'total_calc', name: 'Среднее время полного расчёта', props: {dataFormat: intervalFormatter}},
            ],
            data: this.props.averageIntervals,
            keyField :"insurer"
        };

        return [dataTable, averageTable];
    }

    render() {
        return <ReportComponent
            withStats={true}
            reportLink={this.props.reportLink}
            tables={this.generateTables()}
            route={'external/reports/eosago'}
            fileName={'eosago'}
            loadStatsFunction={
                (date_begin, date_end) => {
                    return loadEosagoStats(date_begin, date_end);
                }
            }
        />
    }
}

function mapStateToProps(state) {
    if (!state.updateEosagoStats.data) {
        return {
            data: [],
            reportLink: ""
        }
    }
    return {
        data: state.updateEosagoStats.data.data,
        averageIntervals: state.updateEosagoStats.data.average_intervals,
        reportLink: state.updateEosagoStats.reportLink
    }
}

export default connect(mapStateToProps)(EOsagoStats);