/**
 * Created by gleb on 5/17/17.
 */
import DocumentChangeStatus from "../constants/DocumentChangeStatus";
import InsuranceRejectReasons from "../constants/InsuranceRejectReasons";

const translation = {
    users: {
        'FIO': 'Полное имя пользователя',
        'lock_info': 'Статус личного кабинета',
        'BIRTH_DATE': 'Дата рождения',
        'EMAIL': 'Email',
        'MOBILE': 'Телефон',
        'MOBILE_REAL': 'Дополнительный телефон',
        'CLIENT': 'Логин',
        'REG_ADDR': 'Адрес регистрации',
        'LIFE_ADDR': 'Адрес места жительства',
        'ACC_NUMBER': 'Реквизиты счета',
        'VERSION': 'Версия приложения',
        'DEVICE': 'Устройство пользователя',
        'TOTAL_OVERDUE': 'Суммарная просроченная задолженность',
        'TOTAL_PENALTY': 'Суммарная пеня',
        'last_mobapp_login': 'Последний вход в МП',
        'last_web_login': 'Последний вход в ИБ',
        'passport_series': 'Серия паспорта РФ',
        'passport_number': 'Номер паспорта РФ'
    },
    creditsStatus: {
        'WORK': 'Открыт',
        'CLOSE': 'Закрыт'
    },
    credits: {
        'SUM_DOG': 'Сумма договора',
        'CONTRACT_NUMBER': 'Номер кредитного договора',
        'DATE_OP': 'Дата оформления договора',
        'DATE_CLS': 'Дата закрытия договора',
        'PRODUCT_NAME': 'Наименование продукта кредита',
        'DATE_MAT': 'Плановая дата закрытия договора',
        'COUNT_PERIOD': 'Срок кредита в месяцах',
        'ALREADY_PAID_AMOUNT': 'Уже выплачено',
        'REMAINING_BALANCE': 'Осталось выплатить',
        'STATUS': 'Статус кредита',
        'IS_LINE': 'Кредитная линия',
        'credit_line_amount': 'Кредитный лимит',
        'PENALTY': 'Пеня',
        'OVERDUE': 'Просроченная задолженность',
    },
    payments: {
        'STAT_PAY': 'Статус платежа',
        'DATE_PAY': 'Дата платежа ',
        'AMOUNT': 'Размер платежа',
        'R_AM_AFTER': 'Остаток по кредиту',
        'AM_BODY': 'Сумма основного платежа',
        'AM_PERC': 'Сумма процентов',
        'OVERDUE': 'Статус просроченного платежа',
        'OVERDUE_AMOUNT': 'Сумма просроченного платежа',
    },
    operations: {
        'operation_type': 'Тип операции',
        'description': 'Текстовое описание операции',
        'NAZN': 'Текстовое описание операции',
        'amount': 'Сумма операции',
        'AMOUNT': 'Сумма операции',
        'date': 'Дата операции',
        'DATE_DOC': 'Дата операции',
        'income': 'Пополнение',
        'SALDO_OUT': 'Исходящий остаток',
        'payment': 'Платеж',
        'best2pay_payment': 'Пополнение через платежную систему Best2Pay (в процессе)'
    },
    certificates: {
        'gathered_name': 'Информация о справке',
        'request_date': 'Дата запроса справки',
        'abs_status': 'Причина отказа из АБС',
        'destination': 'Способ доставки',
        'gathered_status': 'Статус',
        'created_by': 'Инициатор заявки',
    },
    errors: {
        'Server error': 'Ошибка сервера',
        'Invalid Excel file': 'Невалидный excel-файл',
        'Network Error': 'Ошибка сети',
        'E-mail is not confirmed in CFT': 'Email не подтверждён в ЦФТ',
        'Certificate is unavailable': 'Заказ справки c данными параметрами недоступен',
        'Incorrect file type': 'Неправильный формат файла',
        'Validation error: null ': 'Ошибка валидации данных',
    },
    fields: {
        'CLIENT': 'Логин',
        'CRED_ID': 'Номер кредитного договора',
    },
    pdp: {
        'TYPE_OPER': 'Тип погашения',
        'SUM_OP': 'Сумма погашения',
        'DATE_OP': 'Дата погашения',
        'PAR_GR': 'Цель частичного погашения',
        'PAR_GR0': 'Уменьшить сумму платежа',
        'PAR_GR1': 'Сократить срок кредита',
        'TYPE_OPER1': 'Полное',
        'TYPE_OPER2': 'Частичное',
        'request_date': 'Дата создания заявки',
        'status': 'Статус заявки',
        'REASON': 'Причина отказа',
        'CRED_ID': 'Кредит'

    },
    pdp_conditions: {
        'SM_PDP': 'Сумма досрочного погашения',
        'REASON': 'Причина отказа',
        'DATE_PDP': 'Возможная дата погашения',
        'CRED_ID': 'Кредит'
    },
    validation: {
        'BIRTH_DATE': 'Некорректная дата рождения',
        'ERROR_DATE': 'Некорректная дата ошибки'
    },
    letters_to_bank: {
        'id': 'Номер запроса',
        'signed_at': 'Дата обращения',
        'topic': 'Тема обращения',
        'text': 'Текст обращения',
        'files': 'Прикрепленные файлы',
    },
    insurances: {
        'DATE_EXP': 'Дата окончания страхового полиса',
        'INSUR_NUMBER': 'Номер полиса',
        'INSUR_NAME': 'Страховая компания',
        'CRED_ID': 'Кредит'
    },
    mobile_payments: {
        'description': 'Описание платежа',
        'amount': 'Сумма платежа',
        'fee_amount': 'Комиссия',
        'is_repeatable': 'Регулярный',
        'datetime': 'Дата и время платежа',
        'phase': 'Состояние',
        'pan': 'Номер карты',
        'date_begin': 'Дата начала',
        'date_end': 'Дата окончания',
        'day_of_month': 'День списания',
        'source': 'Оформлен через',
    },
    messages: {
        'target': 'Номер',
        'content': 'Текст сообщения',
        'status_text': 'Статус сообщения',
        'error_code': 'Код ошибки',
        'created_at': 'Дата отправки',
    },
    document_changes: {
        'passports': 'Паспорта',
        'insurances': 'Полисы',
        'addresses': 'Адреса',
        'passportHeader': 'Заявки на смену паспорта',
        'insuranceHeader': 'Заявки на смену страхового полиса',
        'addressHeader': 'Заявки на смену адреса',
        'letters_to_bank': 'Запросы в Банк',
        'letter_to_bankHeader': 'Запросы в Банк',
    },
    passport_changes: {
        'client_id': 'Логин',
        'FIO': 'ФИО клиента',
        'last_name': 'Фамилия',
        'first_name': 'Имя',
        'patronymic': 'Отчество',
        'serial': 'Серия паспорта',
        'number': 'Номер паспорта',
        'status_text': 'Статус',
        'issued_at': 'Дата выдачи',
        'issued_by': 'Выдан',
        'issue_dep': 'Код подразделения',
        'address.flat': 'Квартира',
        'address.region': 'Регион',
        'address.area': 'Район',
        'address.city': 'Город',
        'address.settlement': 'Населённый пункт',
        'address.street': 'Улица',
        'address.house': 'Дом',
        'address.street.fias_code': 'ФИАС ID',
        'address.city.fias_code': 'ФИАС ID',
        'address.settlement.fias_code': 'ФИАС ID',
        'address.index': 'Почтовый индекс',
        'created_at': 'Дата заявки',
        'comment': 'Комментарий оператора',
    },
    insurance_changes: {
        'CONTRACT_NUMBER': 'Номер кредитного договора',
        'PRODUCT_NAME': 'Продукт кредита',
        'CLIENT': 'Логин',
        'FIO': 'ФИО клиента',
        'VIN': 'VIN-код',
        'status_text': 'Статус',
        'date_beginning': 'Дата начала действия полиса',
        'date_ending': 'Дата окончания действия полиса',
        'num_polis': 'Номер страхового полиса',
        'insurer_id': 'Страховая компания',
        'type': 'Тип полиса',
        'created_at': 'Дата заявки',
        'comment': 'Комментарий оператора',
        'premium': 'Страховая премия',
        'created_by': 'Создан',
        'approved_by': 'Одобрен'
    },
    address_changes: {
        'CLIENT': 'Логин',
        'FIO': 'ФИО клиента',
        'status_text': 'Статус',
        'address_text': 'Адрес',
        'address.flat': 'Квартира',
        'address.region': 'Регион',
        'address.area': 'Район',
        'address.city': 'Город',
        'address.settlement': 'Населённый пункт',
        'address.street': 'Улица',
        'address.house': 'Дом',
        'address.street.fias_code': 'ФИАС ID',
        'address.city.fias_code': 'ФИАС ID',
        'address.settlement.fias_code': 'ФИАС ID',
        'row.address.index': 'Почтовый индекс',
        'created_at': 'Дата заявки',
        'comment': 'Комментарий оператора',
    },
    letter_to_bank : {
        'id': 'Номер запроса',
        'signed_at': 'Дата обращения',
        'topic': 'Тема обращения',
        'text': 'Текст обращения',
        'files': 'Прикрепленные файлы',
        'CLIENT' : 'ID клиента',
        'FIO' : 'ФИО',
        'CLIENT_INFO' : 'ID клиента (ФИО)'
    },
    document_changes_status: {
        [DocumentChangeStatus.CREATED]: 'Не обработано',
        [DocumentChangeStatus.APPROVED_BY_OPERATOR]: 'Загружен в ЦФТ',
        [DocumentChangeStatus.REJECTED_BY_OPERATOR]: 'Отказ',
        [DocumentChangeStatus.PROCESSED_BY_ABS]: 'Обработано ЦФТ',
        [DocumentChangeStatus.REJECTED_BY_ABS]: 'Ошибка от ЦФТ: нажмите, чтобы посмотреть',
        [DocumentChangeStatus.SMS_CONFIRMATION_SUCCESS]: 'Не обработано',
        [DocumentChangeStatus.PROCESSING_BY_OPERATOR]: 'В процессе',
        [DocumentChangeStatus.REJECTED_BY_OPERATOR_REPETITION]: 'Отказ: повторный полис',
        [DocumentChangeStatus.REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS]: 'Отказ: не соответствует условиям Банка'
    },
    requests_filters: {
        'CLIENT': 'Логин',
        'FIO': 'ФИО',
        'status': 'Статус заявки',
        'afterDate': 'Дата начала',
        'untilDate': 'Дата конца',
    },
    document_changes_errors: {
        'update_address_changes': 'Произошла ошибка при загрузке адресов',
        'update_passport_changes': 'Произошла ошибка при загрузке паспортов',
        'update_insurance_changes': 'Произошла ошибка при загрузке полисов',
        'update_letter_to_bank_changes': 'Произошла ошибка при загрузке запросов в Банк',
    },
    insurance_reasons: {
        [InsuranceRejectReasons.REJECTED_BY_OPERATOR_ABS_PROGRAM]: 'Программа АФБ',
        [InsuranceRejectReasons.REJECTED_BY_OPERATOR_UNREADABLE]: 'Нечитаемый полис',
        [InsuranceRejectReasons.REJECTED_BY_OPERATOR_REPETITION]: 'Повторный полис',
        [InsuranceRejectReasons.REJECTED_BY_OPERATOR_TERMS_AND_CONDITIONS]: 'Не соответствует условиям Банка',
    },
    card_insurances: {
        'insurer': 'Страховая компания',
        'policy_id': 'Номер полиса',
        'signed_at': 'Дата подписания',
        'begins_at': 'Действует c',
        'ends_at': 'Действует по',
        'link': 'Соглашение',
        'created_at': 'Дата создания',
        'status': 'Статус полиса'
    },
    credit_prolongations: {
        'CRED_ID': 'Кредит',
        'description': 'Описание',
        'type': 'Тип',
        'signed_at': 'Дата подписания',
        'agreement_link': 'Текст соглашения',
        'from': 'Предлагается с',
        'to': 'Предлагается по',
        'clicked_at': 'Клик по баннеру',
        'status': 'Статус соглашения',
    },
    'loan_limit': {
        'CRED_ID': 'Кредит',
        'sms_confirmed_at': 'Прохождение SMS-верификации',
        'payment_plan_pdf_link': 'График платежей',
        'loan_limit_snapshot': 'КЛ на дату использования',
        'loan_amount': 'За счёт кредита',
        'b2p_amount': 'За счёт собственных средств',
        'product': 'Продукт',
        'status': 'Статус',
        'cft_error': 'Ошибка от ЦФТ',
    },
    credit_prolongation_schedules: {
        'key': '#',
        'status': 'Статус платежа',
        'date': 'Дата',
        'total_amount': 'Размер платежа',
        'saldo_out': 'Остаток по кредиту',
        'body': 'Сумма основного платежа',
        'percents': 'Сумма процентов',
        'overdue': 'Статус просроченного платежа',
        'overdue_amount': 'Сумма просроченного платежа'
    },
    certificate_changes: {
        'CLIENT': 'Логин',
        'FIO': 'ФИО',
        'EMAIL': 'E-mail',
        'date_beginning': 'Дата начала',
        'date_ending': 'Дата окончания',
        'date_period': 'За период',
        'email': 'Е-mail',
        'credit': 'По кредиту',
        'account': 'По счёту',
        'certificate_type_id': 'Тип справки',
    },
    extra_agreements: {
        'id': '#',
        'name': 'Название',
        'file_link': 'Посмотреть файл',
        'creator_operator_id': 'Создатель',
        'created_at': 'Дата создания',
        'status_description': 'Статус',
        'revoke': 'Отозвать',
        'check_chain': 'Метаданные',
        'is_required_to_sign': 'Блокировать доступ до подписания',
        'is_expirable': 'Ограничить срок показа',
        'expires_at': 'Дата окончания показа',
        'file': 'Документ',
    },
};

function getTranslatedValue(obj, field) {
    if (field === 'counter') {
        return '#';
    }
    return obj.hasOwnProperty(field) ? obj[field] : field;
}

export function trans(path) {
    const splited = path.split('.');
    let field = '';
    for (let i = 1; i < splited.length; i++) {
        field += splited[i] + (i === (splited.length - 1) ? '' : '.');
    }
    const group = splited[0];
    return getTranslatedValue(translation[group], field);
}

export function validateTextField(value, field) {
    let error = '';
    switch (field) {
        case 'CLIENT':
            break;
        case 'EMAIL':
            if (value !== '' && !value.match(/.+@.+\..+/)) {
                error = 'E-mail должен быть в формате mail@example.com'
            }
            break;
        case 'ACC_NUMBER':
            if (value !== '' && !value.match(/^(\d){12}$/)) {
                error = 'Значение должно быть последними 12 цифрами номера банковского счёта пользователя'
            }
            break;
        case 'FIO':
        case 'CONTRACT_NUMBER':
            break;
        default:
            break
    }
    return error;
}


export function getCurrencyDescription(currencyCode) {
    switch (currencyCode) {
        case "EUR":
        case "978":
            return "евро";
        case "USD":
        case "840":
            return "$";
        default:
            return "руб.";
    }
}

export function setProperty(propertyName, object, value) {
    let parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this;

    for (i = 0; i < length - 1; i++) {
        property = property[parts[i]];
    }

    property[parts[length - 1]] = value;
}

export function getProperty(propertyName, object) {
    let parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this;

    for (i = 0; i < length; i++) {
        property = property[parts[i]];
    }

    return property;
}

export function getQueryString(url, query) {
    return url + Object.keys(query)
        .map((key) => {
            return query[key] ? `${key}=${encodeURIComponent(query[key])}` : null;
        })
        .filter((value) => value !== null)
        .join('&');
}

export function pick(obj, keys) {
    return keys.map(k => k in obj ? {[k]: obj[k]} : {}).reduce((res, o) => Object.assign(res, o), {});
}

export function intervalFormatter(value) {
    if (value === null) {
        return null;
    }
    return `${Math.trunc(value / 60)}:${(value % 60 < 10 ? "0" : "") + Number(value % 60).toFixed(3)}`
}

export function humanReadableFileSize(bytes) {
    const prefixes = ['байт', 'кб', 'мб', 'гб'];
    let size = bytes;
    let prefixId = 0;
    while (size > 1000) {
        size /= 1000;
        prefixId++;
    }
    return size.toFixed(3) + " " + prefixes[prefixId];
}