/**
 * Created by anton on 5/12/17.
 */

import React from 'react';
import {Card} from 'react-bootstrap';
import {removePanel} from '../../actions/panels';
import PropTypes from 'prop-types';
import {CSSTransition} from 'react-transition-group';

import './styles.scss';
import {CiCircleRemove} from "react-icons/ci";

const FADE_TIME = 10000;

const Panels = ({panels, dispatch}) => (
    <div>
       
       
            {
                (panels.map((item, index) => {
                        setTimeout(() => {
                            dispatch(removePanel(index));
                        }, FADE_TIME);
                        return <div className="panel-wrapper" key={index}>
                            <Card>
                                <Card.Header>
                                        <div className="pull-right">
                                            <CiCircleRemove glyph="remove" onClick={() => dispatch(removePanel(index))} size={'20px'} color={'red'}/>
                                            <span>{item.panelHeader + ', ' + item.panelTime.toLocaleTimeString()}</span>
                                        </div>
                                </Card.Header>
                                <Card.Body>
                                    <div>{item.panelBody}</div>
                                </Card.Body>
                            </Card>
                        </div>
                    }
                ))
            }
    </div>
);

Panels.propTypes = {
    panels: PropTypes.array.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default Panels;