import React, {Component} from "react";
import {connect} from "react-redux";
import ReportComponent from "./ReportComponent";

class DocumentRequestsReport extends Component {
    render() {
        return <ReportComponent
            route={'external/reports/document_changes'}
            fileName={'document-requests'}
        />
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(DocumentRequestsReport);