import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Button,
    Col,
    FormLabel,
    Form,
    FormControl,
    FormGroup,
    Container,
    Modal, ModalBody, ModalFooter,
    ModalHeader,
    ModalTitle,
    Row
} from "react-bootstrap";
import DocumentChangeStatus from "../constants/DocumentChangeStatus";
import {errorPanel} from "../actions/panels";
import {getSuggestions} from "../actions/dadata";
import {findDOMNode} from "react-dom";
import Autosuggest from "react-autosuggest";
import {getProperty, trans} from "../utils";
import './styles/changeDocumentModal.scss';
import {approveChange, rejectChange, saveChange} from "../actions/document_requests";
import DocumentRequestTypes from "../constants/DocumentRequestTypes";

const bounds = [
    "region",
    "area",
    "city",
    "settlement",
    "street",
    "house"
];

class AddressChangeHandleModal extends Component {
    constructor(props) {
        super(props);
        let addressState = {};
        let readyInput = {};
        let needSuggestions = {};
        for (let bound of bounds) {
            addressState[bound] = Object.assign({}, this.props.row.address[bound]);
            readyInput[bound] = true;
            needSuggestions[bound] = true;
        }

        let row = JSON.parse(JSON.stringify(this.props.row));

        this.state = {
            row: row,
            readyInput: readyInput,
            needSuggestions: needSuggestions,
            address: addressState,
            disabled: !([
           //     DocumentChangeStatus.REJECTED_BY_ABS,
            //    DocumentChangeStatus.CREATED,
            //    DocumentChangeStatus.PROCESSING_BY_OPERATOR,
            ].includes(this.props.row.status)),
            putSuggested: true,
        };

        this.type = DocumentRequestTypes.ADDRESS_TYPE;

        this.close = ::this.close;
        this.handleRejectBtnClick = ::this.handleRejectBtnClick;
        this.handleAcceptBtnClick = ::this.handleAcceptBtnClick;
        this.handleSaveBtnClick = ::this.handleSaveBtnClick;
        this.constructObject = ::this.constructObject;

        this.createFormField = ::this.createFormField;
        this.createStringField = ::this.createStringField;
        this.createTextField = ::this.createTextField;
        this.createAddressField = ::this.createAddressField;
        this.createBlockedFormField = ::this.createBlockedFormField;

        this.returnToInitialRow = ::this.returnToInitialRow;
        this.getMinFias = ::this.getMinFias;
        this.showSuggestions = ::this.showSuggestions;
        this.loadSuggestions = ::this.loadSuggestions;
        this.onChange = ::this.onChange;
        this.onSuggestionsClearRequested = ::this.onSuggestionsClearRequested;
        this.onSuggestionsFetchRequested = ::this.onSuggestionsFetchRequested;
        this.setReadyInput = ::this.setReadyInput;
        this.setNeedSuggestions = ::this.setNeedSuggestions;
        this.getFilledUnitsAbove = ::this.getFilledUnitsAbove;
        this.guessAddress = ::this.guessAddress;
        this.emptyAddress = ::this.emptyAddress;
        this.findFirstDefinedProperty = ::this.findFirstDefinedProperty;
        this.validateRow = ::this.validateRow;
    }

    componentDidMount() {
        this.form = this.refs.form;
    }

    componentDidUpdate() {
        if (this.props.fillAddress.length !== 0 && !this.props.fetchingSuggestions && !this.state.putSuggested) {
            this.setState({address: this.props.fillAddress[0].address, putSuggested: true});
        }
    }

    close() {
        this.props.onCloseClick()
    }

    validateRow() {
        if (this.state.address.street.text_value && !this.state.address.street.fias_code) {
            this.props.dispatch(errorPanel("Некорректно указана улица"));
            return false;
        }
        const minFias = this.getMinFias();
        if (!minFias) {
            this.props.dispatch(errorPanel("ФИАС не заполнен (некорректный адрес)"));
            return false;
        }
        return true;
    }

    handleAcceptBtnClick = () => {
        if (this.validateRow()) {
            this.props.dispatch(approveChange(this.type, this.props.row.request_id, this.constructObject()));
        }
        this.close()
    };

    handleRejectBtnClick = () => {
        this.props.dispatch(rejectChange(this.type, this.props.row.request_id, this.state.row.comment));
        this.close();
    };

    handleSaveBtnClick = () => {
        this.props.dispatch(saveChange(this.type, this.props.row.request_id, this.constructObject()));
        this.close();
    };

    constructObject() {
        return {
            address: {
                ...this.state.address,
                flat: this.state.row.address.flat,
                index: this.state.row.address.index,
            },
            comment: this.state.row.comment,
        };
    }

    returnToInitialRow = () => {
        let addressState = {};
        let readyInput = {};
        let needSuggestions = {};
        for (let bound of bounds) {
            addressState[bound] = Object.assign({}, this.props.row.address[bound]);
            readyInput[bound] = true;
            needSuggestions[bound] = true;
        }
        this.setState((state) => ({
            ...state,
            address: addressState,
            readyInput: readyInput,
            needSuggestions: needSuggestions,
        }));
        findDOMNode(this.form).reset();
    };

    getMinFias() {
        return this.state.address.street.fias_code ||
            this.state.address.city.fias_code ||
            this.state.address.settlement.fias_code;
    }

    setReadyInput(bound, value) {
        this.setState((state) => {
            let readyInput = Object.assign({}, state.readyInput);
            readyInput[bound] = value;
            return {readyInput: readyInput};
        });
    }

    setNeedSuggestions(bound, value) {
        this.setState((state) => {
            let needSuggestions = Object.assign({}, state.needSuggestions);
            needSuggestions[bound] = value;
            return {needSuggestions: needSuggestions};
        });
    }

    showSuggestions = () => {
        return this.props.suggestions;
    };

    renderSuggestion = suggestion => (
        <a className={"dropdown-item"} type={"button"}>
            {suggestion.value}
        </a>
    );

    loadSuggestions = (bound, value) => {
        this.props.dispatch(
            getSuggestions(bound, this.getFilledUnitsAbove(bound, value), false,
                () => this.setNeedSuggestions(bound, true))
        );
    };

    emptyBoundsBelow(bound) {
        let newAddress = Object.assign({}, this.state.address);
        let reachedGivenBound = false;
        for (const b of bounds) {
            if (reachedGivenBound) {
                newAddress[b].text_value = '';
                newAddress[b].fias_code = '';
                this.setReadyInput(b, false);
            }
            if (b === bound) {
                reachedGivenBound = true;
            }
        }
        this.setState({
            address: newAddress
        });
    }

    getFilledUnitsAbove(bound, value) {
        let query = {};
        for (let b of bounds) {
            if (b === bound) {
                break;
            }
            query[b] = this.state.address[b].fias_code;
        }
        query[bound] = value;
        return query;
    }

    onChange = (bound) => (event, {newValue, method}) => {

        if (["down", "up"].indexOf(method) !== -1) {
            return;
        }

        if (method === 'type') {
            this.setReadyInput(bound, false);
            clearTimeout(this.timeoutCall);
            this.timeoutCall = setTimeout(() => {
                this.loadSuggestions(bound, newValue)
            }, 500);
        }
        if (['enter', 'click'].indexOf(method) !== -1) {
            this.setReadyInput(bound, true);
        }

        this.emptyBoundsBelow(bound);

        let newAddress = Object.assign({}, this.state.address);
        if (newValue === Object(newValue)) {
            for (let b of bounds) {
                newAddress[b] = newValue.address[b];
                if (newAddress[b].text_value) {
                    this.setReadyInput(b, true);
                }
            }
        } else {
            newAddress[bound].text_value = newValue;
            newAddress[bound].fias_code = '';
        }
        this.setState({
            address: newAddress,
        });
    };

    onSuggestionsFetchRequested = (bound) => () => {
        this.setNeedSuggestions(bound, true);
    };

    onSuggestionsClearRequested = (bound) => () => {
        clearTimeout(this.timeoutCall);
        this.setNeedSuggestions(bound, false);
    };

    onSuggestionSelected = (bound) => () => {
        this.setReadyInput(bound, true);
    };

    guessAddress() {
        this.setState({putSuggested: false});
        let minBound = null;
        let checkCondition = (bound) => {
            let value = this.state.address[bound].text_value;
            return value !== null && value.trim() !== '';
        };
        for (let bound of bounds) {
            if (checkCondition(bound)) {
                minBound = bound;
            }
        }
        if (minBound === null) {
            this.props.dispatch(getSuggestions('house', {house: ''}, true));
            return;
        }
        let addressUnits = [];
        for (let bound of bounds) {
            if (checkCondition(bound)) {
                addressUnits.unshift(this.state.address[bound].text_value);
            }
        }
        let query = {};
        query[minBound] = addressUnits.join(', ');
        this.props.dispatch(getSuggestions(minBound, query, true));
    }

    emptyAddress() {
        let newAddress = Object.assign({}, this.state.address);
        for (let bound of bounds) {
            newAddress[bound] = {
                text_value: '',
                fias_code: '',
            };
            this.setReadyInput(bound, false);
            this.setNeedSuggestions(bound, false);
        }
        this.setState({address: newAddress});
    }

    createFormField(field, onChange, formType) {
        let property = getProperty(field, this.state.row);
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={6} sm={5} xs={18}>
                            <FormLabel>{trans('passport_changes.' + field)}</FormLabel>
                        </Col>
                        <Col md={10} sm={15} xs={18}>
                            <FormControl
                                componentClass={formType}
                                type={"text"}
                                defaultValue={property ? property : ''}
                                onChange={(e) => {
                                    e.persist();
                                    if (onChange) return onChange(e);
                                    this.setState((state) => ({
                                        row: {
                                            ...state.row,
                                            [field]: e.target.value
                                        }
                                    }));
                                }}
                                readOnly={this.state.disabled}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createStringField(field, onChange = null) {
        return this.createFormField(field, onChange, 'input');
    }

    createTextField(field, onChange = null) {
        return this.createFormField(field, onChange, 'textarea');
    }

    findFirstDefinedProperty(fields) {
        if (!Array.isArray(fields))
            return fields;
        for (const field of fields) {
            if (getProperty(field, this.state) !== null) {
                return field;
            }
        }
        return null;
    }

    createBlockedFormField(field) {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={4} sm={5} xs={16}>
                            <FormLabel>{trans('passport_changes.' + field)}</FormLabel>
                        </Col>
                        <Col md={6} sm={12} xs={16}>
                            <FormControl
                                type="text"
                                value={getProperty(field, this.state)}
                                readOnly={true}
                            />
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createAddressField(bound) {
        let val = this.state.address[bound].text_value;
        const inputProps = {
            value: val ? val : '',
            onChange: this.onChange(bound)
        };

        const theme = {
            container: 'autosuggest',
            input: 'form-control',
            suggestionsContainer: 'dropdown',
            suggestionsList: 'dropdown-menu show'
        };
        let fieldComponent = (<FormControl type="text" value={val} readOnly={true}/>);
        if (!this.state.disabled) {
            fieldComponent = (<Autosuggest
                id={bound}
                theme={theme}
                suggestions={
                    this.props.suggestions[bound] &&
                    this.state.needSuggestions[bound] &&
                    !this.state.readyInput[bound] ? this.props.suggestions[bound] : []
                }
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested(bound)}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested(bound)}
                onSuggestionSelected={this.onSuggestionSelected(bound)}
                getSuggestionValue={suggestion => suggestion}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
            />);
        }

        return (
            <div>
                <Container>
                    <Row>
                        <Col md={4} sm={6} xs={15}>
                            <FormLabel>{trans('address_changes.address.' + bound)}</FormLabel>
                        </Col>
                        <Col md={4} sm={9} xs={12}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    render() {
        const {
            row,
        } = this.props;

        return (<Modal show={true} onHide={this.close} bsSize="lg" dialogClassName={"address-modal"}>
            <ModalHeader closeButton>
                <ModalTitle>Заявка клиента {row.FIO}</ModalTitle>
                {
                    row.status === DocumentChangeStatus.REJECTED_BY_OPERATOR &&
                    <div className={'reason-header'}><strong>Заявка отклонена оператором.</strong></div>
                }
            </ModalHeader>
            <ModalBody>
                <Container className="modal-cont">
                    <Row>
                        <Col sm={12} md={6} xs={12}>
                            <Form horizontal ref="form">
                                <FormGroup bsSize={"small"}>
                                    {this.createAddressField("region")}
                                    {this.createAddressField("area")}
                                    {this.createAddressField("city")}
                                    {this.createAddressField("settlement")}
                                    {this.createAddressField("street")}
                                    {this.createAddressField("house")}
                                    {this.createStringField("address.flat", (e) => {
                                        this.setState((state) => ({
                                            row: {
                                                ...state.row,
                                                address: {
                                                    ...state.row.address,
                                                    flat: e.target.value
                                                }
                                            }
                                        }))
                                    })}
                                    {this.createStringField("address.index", (e) => {
                                        this.setState((state) => ({
                                            row: {
                                                ...state.row,
                                                address: {
                                                    ...state.row.address,
                                                    index: e.target.value
                                                }
                                            }
                                        }))
                                    })}
                                    {this.createBlockedFormField(
                                        this.findFirstDefinedProperty([
                                            "address.street.fias_code",
                                            "address.city.fias_code",
                                            "address.settlement.fias_code"
                                        ]))
                                    }
                                    {this.createTextField("comment")}
                                </FormGroup>
                            </Form>
                            <Button className={"address-request-btn"} onClick={this.returnToInitialRow} disabled={this.state.disabled}>Сброс</Button>
                            <Button className={"address-request-btn"} onClick={this.guessAddress} disabled={this.state.disabled}>Заполнить адрес</Button>
                            <Button className={"address-request-btn"} onClick={this.emptyAddress} disabled={this.state.disabled}>Очистить адрес</Button>
                        </Col>

                        <Col sm={18} md={10} xs={16} className={"align-self-center address-previous-text"}>
                            <div className={"address-previous-header"}>Данные пользователя:</div>
                            <ul>
                                <li><strong>Регион:</strong> {row.address.region.text_value}</li>
                                <li><strong>Район:</strong> {row.address.area.text_value}</li>
                                <li><strong>Город:</strong> {row.address.city.text_value}</li>
                                <li><strong>Населённый пункт:</strong> {row.address.settlement.text_value}</li>
                                <li><strong>Улица:</strong> {row.address.street.text_value}</li>
                                <li><strong>Дом:</strong> {row.address.house.text_value}</li>
                                <li><strong>Квартира:</strong> {row.address.flat}</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </ModalBody>
            <ModalFooter>
                <Button className="pull-left" onClick={this.close}>Закрыть</Button>
                <Button className="btn btn-info" onClick={this.handleSaveBtnClick}
                        disabled={this.state.disabled}>Cохранить</Button>
                <Button className="btn btn-danger" onClick={this.handleRejectBtnClick}
                        disabled={this.state.disabled}>Отклонить</Button>
                <Button className="btn btn-success" onClick={this.handleAcceptBtnClick}
                        disabled={this.state.disabled}>Подтвердить</Button>
            </ModalFooter>
        </Modal>);
    }
}

function mapStateToProps(state) {
    let fillAddress = state.fillAddressSuggestions.data || [];
    let isFetching = state.fillAddressSuggestions === null ? false : state.fillAddressSuggestions.isFetching;
    return {
        fetchingSuggestions: isFetching,
        suggestions: state.updateAddressSuggestions.data || [{}],
        fillAddress: fillAddress,
    };
}

export default connect(mapStateToProps)(AddressChangeHandleModal);
