import {DONE_RESETTING_COORDINATES, RESET_COORDINATES} from "../actions/interactive_image";

export function resetImage(state = {}, action) {
    switch (action.type) {
        case RESET_COORDINATES:
            return {
                ...state,
                [action.id]: true
            };
        case DONE_RESETTING_COORDINATES:
            return {
                ...state,
                [action.id]: false
            };
        default:
            return state;
    }
}