import {axios_post} from "../utils/axios";
import {errorPanel, okPanel} from "./panels";
import {logout} from "./bank_person";
import moment from "moment/moment";

export function requestCertificate(data, onFinally) {
    const params = {
        'certificate_type_id': data.certificate_type_id,
        'CRED_ID': data.credit,
        'ACC_ID': data.account,
        'CLIENT': data.CLIENT,
        'destination_point': 'email',
        'email': data.EMAIL,
    };
    if (data.date_beginning && data.date_ending) {
        const dateFormat = (dateString) => moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD');
        params.date = {
            'start': dateFormat(data.date_beginning),
            'end': dateFormat(data.date_ending),
        }
    }
    return dispatch => {
        axios_post('external/certificate/request', params)
            .then(() => {
                dispatch(okPanel("Справка будет сформирована. Статус можно посмотреть в история справок в карточке клиента"));
            })
            .catch(error => {
                if (error.response.status === 403) {
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
            }).finally(onFinally);
    }
}