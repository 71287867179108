import {axios, axios_post} from "../utils/axios";
import {errorPanel, okPanel} from "./panels";
import {trans} from "../utils";
import {logout} from "./bank_person";

export const UPDATE_CHANGES = 'UPDATE_CHANGES';
export const REQUEST_CHANGES = 'REQUEST_CHANGES';
export const ERROR_CHANGES = 'ERROR_CHANGES';
export const CLOSE_CHANGE_HANDLE_MODAL = 'CLOSE_CHANGE_HANDLE_MODAL';
export const SHOW_CHANGE_HANDLE_MODAL = 'SHOW_CHANGE_HANDLE_MODAL';
export const DELIVERED_CHANGES = 'DELIVERED_CHANGES';
export const SEND_CHANGES = 'SEND_CHANGES';
export const UPDATE_INSURANCE_COMPANIES = 'UPDATE_INSURANCE_COMPANIES';
export const ERROR_INSURANCE_COMPANIES = 'ERROR_INSURANCE_COMPANIES';

export function requestChanges(reqType, params) {
    return {
        type: REQUEST_CHANGES,
        reqType: reqType,
        ...params,
    };
}

export function updateChanges(reqType, data) {
    return {
        type: UPDATE_CHANGES,
        reqType: reqType,
        data: {
            requests: data.data.requests,
            info: data.info,
        }
    };
}

export function errorChanges(reqType, error) {
    return {
        type: ERROR_CHANGES,
        reqType: reqType,
        error: error,
    };
}

export function closeChangeHandleModal(reqType) {
    return {
        type: CLOSE_CHANGE_HANDLE_MODAL,
        reqType: reqType,
    };
}

export function showChangeHandleModal(reqType, row) {
    return {
        type: SHOW_CHANGE_HANDLE_MODAL,
        reqType: reqType,
        row: row,
    };
}

export function deliverChanges(reqType) {
    return {
        type: DELIVERED_CHANGES,
        reqType: reqType,
    };
}

export function sendChanges(reqType) {
    return {
        type: SEND_CHANGES,
        reqType: reqType,
    };
}

export function updateInsuranceCompanies(data) {
    return {
        type: UPDATE_INSURANCE_COMPANIES,
        data
    }
}

export function errorInsuranceCompanies(error) {
    return {
        type: ERROR_INSURANCE_COMPANIES,
        error
    }
}

export function loadChanges(reqType, filters = {}, page = null, take = null) {
    return dispatch => {
        let params = {
            ...filters,
            'page': page,
            'take': take,
        };
        dispatch(requestChanges(reqType, params));
        axios('external/' + reqType + '/requests', false, params)
            .then(response => {
                dispatch(updateChanges(reqType, response.data));
            })
            .catch(error => {
                if(error.response && error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorChanges(reqType, error));
                dispatch(errorPanel(trans('document_changes_errors.update_' + reqType + '_changes')));
            })
    }
}

export function rejectChange(reqType, requestId, comment = null, reason = null) {
    return dispatch => {
        dispatch(sendChanges(reqType));
        axios_post('external/' + reqType + '/reject', {
            request_id: requestId,
            comment: comment,
            reason: reason
        })
            .then(() => {
                dispatch(okPanel("Заявка успешно отклонена"));
                dispatch(deliverChanges(reqType));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
                dispatch(deliverChanges(reqType));
            })
    }
}

export function approveChange(reqType, requestId, data) {
    return dispatch => {
        dispatch(sendChanges(reqType));
        axios_post('external/' + reqType + '/approve', {
            request_id: requestId,
            data: data
        })
            .then(() => {
                dispatch(okPanel("Заявка успешно одобрена"));
                dispatch(deliverChanges(reqType));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
                dispatch(deliverChanges(reqType));
            })
    }
}

export function saveChange(reqType, requestId, data) {
    return dispatch => {
        dispatch(sendChanges(reqType));
        axios_post('external/' + reqType + '/save', {
            request_id: requestId,
            data: data
        })
            .then(() => {
                dispatch(okPanel("Заявка сохранена"));
                dispatch(deliverChanges(reqType));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
                dispatch(deliverChanges(reqType));
            })
    }
}

export function loadInsuranceCompanies() {
    return dispatch => {
        axios('external/documents/insurance/companies')
            .then(response => {
                dispatch(updateInsuranceCompanies(response.data));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorInsuranceCompanies(error));
                dispatch(errorPanel("Произошла ошибка при загрузке страховых компаний"));
            })
    }
}

export function createInsuranceChangeManually(reqType, requestId, data) {
    return dispatch => {
        dispatch(sendChanges(reqType));
        axios_post('external/' + reqType + '/approve', {
            request_id: requestId,
            data: data
        })
            .then(() => {
                dispatch(okPanel("Заявка успешно одобрена"));
                dispatch(deliverChanges(reqType));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
                dispatch(deliverChanges(reqType));
            })
    }
}