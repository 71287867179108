import React from "react";
import {Tab, Tabs} from "react-bootstrap";
import Push from "./Push";
import Adv from "./Adv";
import Workday from "./Workday";
import PaymentPoint from "./PaymentPoint";

class OtherTabs extends React.Component{

    render(){
        return <div>
            <Tabs defaultActiveKey={0}>
                <Tab eventKey={0} title={"Реклама"}>
                    <Adv/>
                </Tab>
                <Tab eventKey={1} title={"Push-уведомления"}>
                    <Push/>
                </Tab>
                <Tab eventKey={3} title={"Рабочие дни"}>
                    <Workday/>
                </Tab>
                <Tab eventKey={4} title={"Точки оплаты"}>
                    <PaymentPoint/>
                </Tab>
            </Tabs>
        </div>
    }
}


export default OtherTabs;