import {
    CLOSE_CHANGE_HANDLE_MODAL, DELIVERED_CHANGES,
    ERROR_CHANGES,
    REQUEST_CHANGES, SEND_CHANGES,
    SHOW_CHANGE_HANDLE_MODAL,
    UPDATE_CHANGES,
    ERROR_INSURANCE_COMPANIES,
    UPDATE_INSURANCE_COMPANIES
} from "../actions/document_requests";
import {getRequestTypes} from "../constants/DocumentRequestTypes";

function getInitialState(empty = {}) {
    let types = getRequestTypes();
    let state = {};
    for (let type of types) {
        state[type] = empty;
    }
    return state;
}

function getInitialUpdateState() {
    return getInitialState({
        isFetching: true,
        requests: [],
        info: {},
    });
}

function getInitialShowModalState() {
    return getInitialState({
        show: false,
        row: {},
    });
}

function getInitialDeliverState() {
    return getInitialState({
        isFetching: false,
    });
}

export function updateChanges(state = getInitialUpdateState(), action) {
    let reqType = action.reqType;
    switch (action.type) {
        case REQUEST_CHANGES:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    isFetching: true,
                },
            };
        case ERROR_CHANGES:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    isFetching: false,
                },
            };
        case UPDATE_CHANGES:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    ...action.data,
                    isFetching: false,
                }
            };
        default:
            return state;
    }
}

export function showChangeHandleModal(state = getInitialShowModalState(), action) {
    let reqType = action.reqType;
    switch (action.type) {
        case CLOSE_CHANGE_HANDLE_MODAL:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    show: false,
                    row: {},
                },
            };
        case SHOW_CHANGE_HANDLE_MODAL:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    show: true,
                    row: action.row,
                },
            };
        default:
            return state;
    }
}

export function deliverChanges(state = getInitialDeliverState(), action) {
    let reqType = action.reqType;
    switch (action.type) {
        case DELIVERED_CHANGES:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    isFetching: false,
                },
            };
        case SEND_CHANGES:
            return {
                ...state,
                [reqType]: {
                    ...state[reqType],
                    isFetching: true,
                },
            };
        default:
            return state;
    }
}

export function updateInsuranceCompanies(state = {
    data: {}
}, action) {
    switch (action.type) {
        case UPDATE_INSURANCE_COMPANIES:
            return {
                ...state,
                isFetching: false,
                data: action.data,
            };
        case ERROR_INSURANCE_COMPANIES:
            return {
                ...state,
                isFetching: false,
                data: {},
            };
        default:
            return state;
    }
}

