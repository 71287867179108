/**
 * Created by anton on 5/4/17.
 */
import {errorPanel} from "./panels";
import {axios_post} from "../utils/axios";
import searchHistoryStorage from "../utils/SearchHistoryStorage";
import history from "history/browser";

export const BANK_EMPLOYEE = 'admin';
export const CALL_CENTER_OPERATOR = 'user';

export const PERSON_LOGOUT = 'PERSON_LOGOUT';
export function logout() {
    localStorage.removeItem('user_data');
    searchHistoryStorage.clear();
    return {
        type: PERSON_LOGOUT
    }
}

export const REQUEST_AUTHORIZE = 'REQUEST_AUTHORIZE';
export function requestAuthorize({login}) {
    return {
        type: REQUEST_AUTHORIZE,
        payload: login
    }
}

export const RECEIVE_AUTHORIZE = 'RECEIVE_AUTHORIZE';
export function receiveAuthorize(data) {
    localStorage.setItem('user_data', JSON.stringify(data));

    return {
        type: RECEIVE_AUTHORIZE,
        payload: data
    };
}

// common action for invalid credentials and any network error
export const AUTHORIZE_ERROR = 'AUTHORIZE_ERROR';
export function authorizeError(error) {
    return {
        type: AUTHORIZE_ERROR,
        error
    }
}

export function doLogout() {
    return dispatch => {
        axios_post('logout', {
            token: JSON.parse(localStorage.getItem('user_data')).token
        });
        localStorage.removeItem('user_data');
        searchHistoryStorage.clear();
        dispatch(logout());
    }
}

export function loadFromLocalStorage() {
    return dispatch => {
        dispatch(requestAuthorize({login: 'localStorage'}));

        const user = JSON.parse(localStorage.getItem('user_data'));

        if (user) {
            dispatch(receiveAuthorize(user));
        } else {
            dispatch(authorizeError('no user in local storage'));
        }
    }
}

export function doAuthorize(credentials) {
    return dispatch => {
        dispatch(requestAuthorize(credentials));
        const {login, password} = credentials;

        history.push('/clients');
        axios_post('login', {
            login,
            password
        }).then((response) => {
            dispatch(receiveAuthorize({
                fullName: response.data.name,
                role: getRole(response.data.role),
                token: response.data.token
            }));
        }).catch(err => {
            dispatch(authorizeError(err));
            dispatch(errorPanel(err))
        });
    }
}

function getRole(str) {
    if (str === 'BANK_EMPLOYEE') {
        return BANK_EMPLOYEE
    } else if (str === 'OPERATOR') {
        return CALL_CENTER_OPERATOR
    }
}