/**
 * Created by anton on 5/5/17.
 */

import React from 'react';

import {connect} from 'react-redux';
import {FileUploadContainer} from '../containers/FileUploadContainter';
import {sendPushFile} from '../actions/send_files';

class Push extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null
        };
    }

    render() {
        return <FileUploadContainer
            dispatchHandler={
                content => this.props.dispatch(sendPushFile(content))
            }
            onChangeHandler={
                ref => this.setState({file:ref.target.files[0]})
            }
            file={this.state.file}
            header="Форма добавления push-уведомлений"
        />;
    }
}

export default connect(() => {
    return {}
}) (Push);