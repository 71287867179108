import React, {Component} from 'react';
import {Button, Carousel, CarouselItem} from "react-bootstrap";
import {connect} from "react-redux";
import './styles/carousel.scss';
import {findDOMNode} from "react-dom";
import ZoomableImage from "./InteractiveImage";
import {resetCoordinates} from "../actions/interactive_image";
import {CiZoomIn, CiZoomOut} from "react-icons/ci";
import SliderImage from 'react-zoom-slider';

class PictureCarousel extends Component {

    constructor(props) {
        super(props);
        let count = this.props.pictures.length;
        this.state = {
            pictureAngles: new Array(count).fill(0),
            zoom: new Array(count).fill(1),
            fitZoom: new Array(count).fill(1),
            dimensions: new Array(count).fill({width: 0, height: 0}),
            currentPic: 0,
            carouselWidth: 0,
            carouselHeight: 0
        };

        this.rotateCurrentImage = ::this.rotateCurrentImage;
        this.updateCarouselDimensions = ::this.updateCarouselDimensions;
        this.resize = ::this.resize;
        this.getTransformStyle = ::this.getTransformStyle;
        this.zoom = ::this.zoom;
        this.zoomIn = ::this.zoomIn;
        this.zoomOut = ::this.zoomOut;
        this.reset = ::this.reset;
        this.onImgLoad = ::this.onImgLoad;
        this.updateFitZoom = ::this.updateFitZoom;
    }

    zoom = (coeff) => {
        let zoom = this.state.zoom;
        let curZoom = zoom[this.state.currentPic];
        curZoom = Math.min(curZoom * coeff, 8);
        curZoom = Math.max(0.125, curZoom);
        zoom[this.state.currentPic] = curZoom;
        this.setState({
            zoom: zoom
        });
    };

    zoomIn = () => this.zoom(1.5);
    zoomOut = () => this.zoom(0.75);

    reset = () => {
        let zoom = this.state.zoom;
        zoom[this.state.currentPic] = 1;
        let angles = this.state.pictureAngles;
        angles[this.state.currentPic] = 0;
        this.setState({
            zoom: zoom,
            pictureAngles: angles,
        });
        this.props.dispatch(resetCoordinates(this.state.currentPic))
    };

    resize = () => {
        this.updateCarouselDimensions();
        this.forceUpdate()
    };

    componentDidMount() {
        window.addEventListener('resize', this.resize);
        this.updateCarouselDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    updateFitZoom() {
        let root = this;
        let fitZoom = this.state.dimensions.map(function (dims) {
            let {width, height} = dims;
            let minZoom = Math.min(
                root.state.carouselWidth / width,
                root.state.carouselHeight / height
            );
            return Math.min(1, minZoom);
        });
        this.setState({
            fitZoom: fitZoom
        });
    }

    onImgLoad(img, index) {
        let dimensions = this.state.dimensions;
        dimensions[index] = {
            width: img.naturalWidth,
            height: img.naturalHeight,
        };
        this.setState({
           dimensions: dimensions
        });
        this.updateFitZoom();
    }

    updateCarouselDimensions() {
        const node = findDOMNode(this);
        if (!node) return;
        const {offsetWidth, offsetHeight} = findDOMNode(this);
        this.setState({
            // There are some issues with getting correct sizes so we have to modify border manually
            carouselWidth: offsetWidth - 20,
            carouselHeight: offsetHeight - 20
        });
        this.updateFitZoom();
    }

    rotateCurrentImage = () => {
        let newAngles = Object.assign({}, this.state.pictureAngles);
        const currentPic = this.state.currentPic;
        newAngles[currentPic] = (newAngles[currentPic] + 90) % 360;
        this.setState({pictureAngles: newAngles});
    };

    getTransformStyle(angle) {
        let verticalDimension = this.state.carouselHeight;
        let horizontalDimension = this.state.carouselWidth;

        if (angle % 180 !== 0) {
            verticalDimension = this.state.carouselWidth;
            horizontalDimension = this.state.carouselHeight;
        }
        return {
            "position": "absolute",
            "top": "50%",
            "left": "50%",
            "WebkitTransform": "translate(-50%,-50%) rotate(" + angle + "deg)",
            "msTransform": "translate(-50%, -50%), rotate(" + angle + "deg)",
            "transform": "translate(-50%, -50%), rotate(" + angle + "deg)",
            "maxHeight": verticalDimension,
            "maxWidth": horizontalDimension
        }
    }

    render() {
        const data = this.props.pictures.map((picture) => {
            return {
                image: picture,
                text: ""
            };
        });

        // data.push({
        //     image: "https://raw.githubusercontent.com/alperbayram/react-native-curved-bottom-bar/main/assets/test.png?token=GHSAT0AAAAAABZ6P726CSX4F35Y7CLSPVOOZBR5CEA",
        //     text: ""
        // })
        //
        //

        if (!data || data.length < 1) {
            return null
        }

        return <SliderImage
            data={data}
            width="500px"
            showDescription={true}
            direction="right"
        />;
/*
        const multipleImages = this.props.pictures.length > 1;
        return (<div>
            {
                this.props.pictures.length === 0 ?
                    <div className="pictures-error">
                        Фотографии отсутствуют
                    </div> :
                    <div>
                        <Carousel
                            controls={multipleImages}
                            indicators={multipleImages}
                            interval={null}
                            pauseOnHover={false}
                            onSelect={(index) => {
                                this.setState({currentPic: index})
                            }}
                        >
                            {
                                this.props.pictures.map((picture, index) => (
                                    <CarouselItem key={index}>
                                        <ZoomableImage
                                            id={index}
                                            onLoad={({target: img}) => {this.onImgLoad(img, index) }}
                                            style={this.getTransformStyle(this.state.pictureAngles[index])}
                                            zoom={this.state.fitZoom[index] * this.state.zoom[index]}
                                            angle={this.state.pictureAngles[index]}
                                            src={picture}
                                        />
                                    </CarouselItem>))
                            }
                        </Carousel>
                        <Button className={"btn btn-primary"} onClick={this.reset}>Восстановить</Button>
                        <Button className={"btn btn-primary pull-right"} onClick={this.zoomIn}>
                            <CiZoomIn glyph={"zoom-in"}/>
                        </Button>
                        <Button className={"btn btn-primary pull-right"} onClick={this.zoomOut}>
                            <CiZoomOut glyph={"zoom-out"}/>
                        </Button>
                        <Button className={"btn btn-primary pull-right"}
                                onClick={this.rotateCurrentImage}>Повернуть</Button>
                    </div>
            }
        </div>);

 */
    }
}

export default connect(state => {
    return {}
})(PictureCarousel);
