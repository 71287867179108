/**
 * Created by anton on 4/28/17.
 */

import * as actions from "../actions/clients";
import recentSearchStorage from "../utils/SearchHistoryStorage";

export function clientsByQuery(state = {}, action) {
    switch (action.type) {
        case actions.INVALIDATE_CLIENT_LIST:
        case actions.REQUEST_CLIENTS:
        case actions.RECEIVE_CLIENTS:
        case actions.REQUEST_ERROR: {
            const {didInvalidate, isFetching, items, recentSearches} = clients(state, action);
            return {
                ...state,
                didInvalidate,
                isFetching,
                items,
                recentSearches
            };
        }
        default:
            return state;
    }
}

function clients(state = {
    didInvalidate: false,
    isFetching: false,
    items: [],
}, action) {
    switch (action.type) {
        case actions.INVALIDATE_CLIENT_LIST:
            return {...state, didInvalidate: true};
        case actions.REQUEST_CLIENTS:
            return {
                ...state,
                isFetching: true,
                didInvalidate: false
            };
        case actions.RECEIVE_CLIENTS:
            return {
                ...state,
                isFetching: false,
                didInvalidate: false,
                items: action.data
            };
        case actions.REQUEST_ERROR:

            return {
                ...state,
                isFetching: false,
                didInvalidate: false
            };
        case actions.UPDATE_SEARCH_HISTORY:
            return {
                ...state,
                recentSearches: recentSearchStorage.getRecentSearches()
            };
        default:
            return state;
    }
}