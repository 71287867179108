import React, {Component} from "react";
import {connect} from "react-redux";
import ReportComponent from "./ReportComponent";

class DocumentRequestsReport extends Component {
    render() {
        return <ReportComponent
            route={'external/reports/usage'}
            fileName={'usage'}
        />
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(mapStateToProps)(DocumentRequestsReport);