/**
 * Created by gleb on 5/15/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

import {
    Form,
    Button
} from 'react-bootstrap';
import FieldGroup from '../containers/FieldGroup';

export const FileUploadContainer = ({header,dispatchHandler, onChangeHandler, file, label, buttonText}) => {

    const formLabel = label ?? "Файл для выгрузки";
    const formButtonText = buttonText ?? "Выгрузить";
    return (<div style={{paddingTop: '15px'}}>
            {header &&
                <h2>{header}</h2>
            }
            <Form onSubmit={e => {
                e.preventDefault();
                let reader = new FileReader();
                reader.onload = function () {
                    dispatchHandler(reader.result.replace(/.*,/, ''))
                };
                reader.readAsDataURL(file);
            }}>
                <FieldGroup
                    id="pushes_file"
                    type="file"
                    label={formLabel}
                    onChange={onChangeHandler}
                    required
                />
                <Button type="submit" style={{marginTop: '15px'}}>{formButtonText}</Button>
            </Form>
        </div>
    );
};

FileUploadContainer.propTypes = {
    header: PropTypes.string.isRequired,
    dispatchHandler: PropTypes.func.isRequired,
    onChangeHandler: PropTypes.func.isRequired,
    file: PropTypes.object
};