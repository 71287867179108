import React, {Component} from 'react';
import {connect} from "react-redux";
import ReportComponent from "./ReportComponent";
import {loadCascoStats} from "../actions/stats";

class CascoStats extends Component {
    constructor(props) {
        super(props);
        this.generateTables = this.generateTables.bind(this);
    }

    generateTables() {
        let dataTable = {
            name: 'Статистика статусов расчёта',
            headers: [
                {field: 'insurer', name: 'СК', props: {isKey: true}},
                {field: 'status', name: 'Статус'},
                {field: 'count', name: 'Количество'}
            ],
            data: this.props.data,
            keyField: "insurer"
        };

        return [dataTable];
    }

    render() {
        return (
            <ReportComponent
                withStats={true}
                reportLink={this.props.reportLink}
                tables={this.generateTables()}
                route={'external/reports/casco'}
                fileName={'casco'}
                loadStatsFunction={
                    (date_begin, date_end) => {
                        return loadCascoStats(date_begin, date_end);
                    }
                }
            />
        );
    }
}

function mapStateToProps(state) {
    if (!state.updateCascoStats.data) {
        return {
            data: [],
            reportLink: ""
        }
    }
    return {
        data: state.updateCascoStats.data.data,
        reportLink: state.updateCascoStats.reportLink
    };
}

export default connect(mapStateToProps)(CascoStats);