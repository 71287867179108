import App from "./App";
import {useLocation, useNavigate} from "react-router-dom";

function AppFunctionComponent(){
    const location = useLocation();
    const navigate = useNavigate();
    return (
        <App location={location} navigate={navigate}/>
    );
};

export default AppFunctionComponent;