import React, {Component} from 'react';
import {Button, FormControl, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import {closeChangeHandleModal, rejectChange} from "../actions/document_requests";
import DocumentRequestTypes from "../constants/DocumentRequestTypes";
import {connect} from "react-redux";

class PassportRejectModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reason: '',
        };
        this.type = DocumentRequestTypes.PASSPORT_TYPE;

        this.close = ::this.close;
        this.handleRejectBtnClick = ::this.handleRejectBtnClick;
    }

    close() {
        this.props.onCloseClick()
    }

    handleRejectBtnClick() {
        this.props.dispatch(rejectChange(this.type, this.props.request_id, this.props.comment, this.state.reason));
        this.props.dispatch(closeChangeHandleModal(this.type));
        this.close();
    }

    render() {
        return (
            <Modal show={true} bsSize={'lg'} onHide={this.close} dialogClassName={'reject-passport-modal'}>
                <ModalHeader closeButton>
                    <ModalTitle>Отклонить заявку клиента</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    Причина отклонения заявки:<br/>
                    <FormControl
                        key={'reason'}
                        componentClass="textarea"
                        placeholder={"Причина..."}
                        onChange={(e) => {
                            this.setState({reason: e.target.value});
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.close}>Закрыть</Button>
                    <Button className="btn btn-danger" onClick={this.handleRejectBtnClick}>Подтвердить
                        отклонение</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect()(PassportRejectModal);