/**
 * Created by anton on 5/4/17.
 */

import * as actions from '../actions/bank_person';

export function authorizedPerson(state = {}, action) {
    switch (action.type) {
        case actions.REQUEST_AUTHORIZE:
        case actions.RECEIVE_AUTHORIZE:
        case actions.AUTHORIZE_ERROR:
        case actions.PERSON_LOGOUT:
            return person(state, action);
        default:
            return state;
    }
}

function person(state = {
    isAuthorizing: false,
    person: null
}, action) {
    switch (action.type) {
        case actions.REQUEST_AUTHORIZE:
            return {
                ...state,
                isAuthorizing: true
            };
        case actions.RECEIVE_AUTHORIZE:
            return {
                isAuthorizing: false,
                person: action.payload
            };
        case actions.AUTHORIZE_ERROR:
            return {
                ...state,
                isAuthorizing: false
            };
        case actions.PERSON_LOGOUT:
            return {
                isAuthorizing: false,
                person: null
            };
        default :
            return state;
    }
}