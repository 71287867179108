import React, {Component} from "react";
import {
    Button,
    Col, Form, Container, Modal, ModalBody, ModalFooter, ModalHeader,
    ModalTitle,
    Row
} from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";
import "./styles/changeDocumentModal.scss"
import {errorPanel} from "../actions/panels";
import {formatDateInput} from "../utils/formatters";
import {trans} from "../utils";
import {connect} from "react-redux";
import {addExtraAgreement} from "../actions/extra_agreements";

class AddExtraAgreementModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            extra_agreement_data: {
                name: null,
                file: null,
                is_required_to_sign: false,
                is_expirable: false,
                expires_at: null,
                CLIENT: props.row.CLIENT,
            },
            showRejectModal: false,
            disabled: false,
            onClientDataChanged: props.row.onClientDataChanged,
        };

        this.createDateField = ::this.createDateField;
        this.createFormField = ::this.createFormField;
        this.createFormControl = ::this.createFormControl;
        this.createFormControlFile = ::this.createFormControlFile;
        this.createStringField = ::this.createStringField;
        this.createCheckboxField = ::this.createCheckboxField;
        this.createFileField = ::this.createFileField;
        this.handleAcceptBtnClick = ::this.handleAcceptBtnClick;
        this.close = ::this.close;
    }

    componentDidMount() {
        this.form = this.refs.form;
    }

    close() {
        this.props.onCloseClick()
    }

    handleAcceptBtnClick() {
        const {
            extra_agreement_data
        } = this.state;
        if (!extra_agreement_data.name) {
            this.props.dispatch(errorPanel("Введите название документа"));
            return false;
        }
        if (!extra_agreement_data.file) {
            this.props.dispatch(errorPanel("Выберите файл"));
            return false;
        }
        if (extra_agreement_data.expires_at) {
            const date = moment(extra_agreement_data.expires_at, "DD.MM.YYYY");
            if (!date.isValid() || date.isBefore(moment())) {
                this.props.dispatch(errorPanel("Некорректная дата"));
                return false;
            }
        }

        let modal = this;
        let reader = new FileReader();
        reader.onload = function () {
            extra_agreement_data.file_base64 = reader.result.replace(/.*,/, '');
            modal.props.dispatch(addExtraAgreement(extra_agreement_data, modal.state.onClientDataChanged));
            modal.close();
        };
        reader.readAsDataURL(extra_agreement_data.file);
    }

    createDateField(field) {
        let fieldComponent = (
            <Form.Control type="text" value={this.state.extra_agreement_data[field]} readOnly={true}/>);

        if (!this.state.disabled) {
            fieldComponent = (<Datetime dateFormat="DD.MM.YYYY"
                                        timeFormat={false}
                                        locale={"ru"}
                                        value={this.state.extra_agreement_data[field] ?? 'Не ограничено'}
                                        closeOnSelect={true}
                                        onChange={(e) => {
                                            let date = formatDateInput(e);
                                            this.setState((state) => ({
                                                extra_agreement_data: {
                                                    ...state.extra_agreement_data,
                                                    [field]: date,
                                                },
                                            }));
                                        }
                                        }
                                        readOnly={this.state.disabled}
            />);
        }
        return (
            <div>
                <Container>
                    <Row>
                        <Col md={4} sm={4} xs={4}>
                            <Form.Label>{trans('extra_agreements.' + field)}</Form.Label>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            {fieldComponent}
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createFormField(field, formType, componentClass) {
        return (
            <div>
                <Container style={{backgroundColor: 'white'}}>
                    <Row>
                        <Col md={4} sm={4} xs={4}>
                            <Form.Label>{trans('extra_agreements.' + field)}</Form.Label>
                        </Col>
                        <Col md={8} sm={8} xs={8}>
                            {formType === 'file'
                                ? this.createFormControlFile(field, componentClass)
                                : this.createFormControl(field, formType, componentClass)
                            }
                        </Col>
                    </Row>
                </Container> <br/>
            </div>
        )
    }

    createFormControl(field, formType, componentClass) {
        return (
            <Form.Control
                componentClass={componentClass}
                className={'myclassname'}
                type={formType}
                value={this.state.extra_agreement_data[field]}
                onChange={(e) => {
                    e.persist();
                    this.setState((state) => ({
                        extra_agreement_data: {
                            ...state.extra_agreement_data,
                            [field]: formType === 'checkbox' ? e.target.checked : e.target.value,
                        }
                    }));
                }}
                readOnly={this.state.disabled}
            />
        )
    }

    createFormControlFile(field, componentClass) {
        return (
            <Form.Control
                componentClass={componentClass}
                type='file'
                file={this.state.extra_agreement_data.file}
                onChange={(e) => {
                    e.persist();
                    this.setState((state) => ({
                        extra_agreement_data: {
                            ...state.extra_agreement_data,
                            [field]: e.target.files[0],
                        }
                    }));
                }}
                readOnly={this.state.disabled}
            />
        )
    }

    createStringField(field) {
        return this.createFormField(field, 'text', 'input');
    }

    createFileField(field) { // not sure...
        return this.createFormField(field, 'file', 'input');
    }

    createCheckboxField(field) {
        return this.createFormField(field, 'checkbox', 'input');
    }


    render() {
        const {
            row
        } = this.props;
        return (
            <Modal show={true} onHide={this.close} bsSize={"lg"} dialogClassName={"insurance-modal"}>
                <ModalHeader closeButton>
                    <ModalTitle>Добавление дополнительного соглашения для клиента {row.FIO} ({row.CLIENT})</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Container className="modal-cont" style={{backgroundColor: 'white'}}>
                        <Form horizontal ref="form">
                            <Form.Group bsSize="small">
                                {this.createStringField("name")}
                                {this.createFileField("file")}
                                {/*{this.createCheckboxField("is_required_to_sign")}*/}
                                {this.createDateField("expires_at")}
                            </Form.Group>
                        </Form>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.close}>Закрыть</Button>
                    <Button className="btn btn-success" onClick={this.handleAcceptBtnClick}
                            disabled={this.state.disabled}>
                        Отправить</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default connect()(AddExtraAgreementModal);