import {axios_post} from "../utils/axios";
import {errorPanel, okPanel} from "./panels";
import {logout} from "./bank_person";
import moment from "moment";
import {pick} from "../utils";

export function addExtraAgreement(data, onFinally) {

    const params = {
        ...pick(data, ['name', 'file_base64', 'is_required_to_sign', 'CLIENT']),
        expires_at: data.expires_at? moment(data.expires_at, 'DD.MM.YYYY').format('YYYY-MM-DD'): null,
    };

    return dispatch => {
        axios_post('external/extra_agreement/add', params)
            .then(() => {
                dispatch(okPanel("Дополнительное соглашение добавлено"));
            })
            .catch(error => {
                if (error?.response?.status === 403) {
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
            }).finally(onFinally);
    }
}

export function revokeExtraAgreement(data, onFinally) {

    const params = {
        ...pick(data, ['extra_agreement_id', 'CLIENT']),
    };

    return dispatch => {
        axios_post('external/extra_agreement/revoke', params)
            .then(() => {
                dispatch(okPanel("Дополнительное соглашение отозвано"));
            })
            .catch(error => {
                if (error?.response?.status === 403) {
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
            }).finally(onFinally);
    }
}