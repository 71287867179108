import {axios_post} from "../utils/axios";
import {errorPanel, okPanel} from "../actions/panels";
import {logout} from "../actions/bank_person";


export function addInsuranceManually(reqType, CLIENT, CRED_ID, data) {
    return dispatch => {
        axios_post('external/' + reqType + '/create_insurance', {
            CLIENT: CLIENT,
            CRED_ID,
            data: data
        })
            .then(() => {
                dispatch(okPanel("Полис добавлен в очередь на отправку в ЦФТ"));
            })
            .catch(error => {
                if(error.response.status === 403){
                    dispatch(logout()); //force logout on 403
                }
                dispatch(errorPanel(error));
            })
    }
}