import React, {Component} from 'react';
import PropTypes from 'prop-types';
import DocumentChangeStatus from "../../constants/DocumentChangeStatus";
import {Button, Col, Container, Form, FormControl, FormGroup, FormLabel, ModalHeader} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {trans} from "../../utils";
import Datetime from "react-datetime";
import {formatDateInput} from "../../utils/formatters";
import {findDOMNode} from "react-dom";
import "../styles/requestTab.scss";
import {connect} from "react-redux";
import {loadChanges, showChangeHandleModal} from "../../actions/document_requests";
import {IoRefreshCircleOutline} from "react-icons/io5";
import paginationFactory from 'react-bootstrap-table2-paginator';

class RequestTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filterInputs: {},
            filters: {},
            sortOrder: '',
            sortName: '',
            sizePerPage: 10,
        };

        this.tabHeader = ::this.tabHeader;

        this.filterHeader = ::this.filterHeader;
        this.applyFilters = ::this.applyFilters;
        this.setFilters = ::this.setFilters;
        this.clearFilters = ::this.clearFilters;
        this.createFilterDateForm = ::this.createFilterDateForm;
        this.createFilterSelectForm = ::this.createFilterSelectForm;
        this.getStatusOptions = ::this.getStatusOptions;
        this.createFilterTextForm = ::this.createFilterTextForm;

        this.getHeaderColumn = ::this.getHeaderColumn;
        this.getTableColumns = ::this.getTableColumns;
        this.getTableProps = ::this.getTableProps;
        this.expandComponentType = ::this.expandComponentType;
        this.onSortChange = ::this.onSortChange;
        this.onPageChange = ::this.onPageChange;
        this.onSizePerPageListChange = ::this.onSizePerPageListChange;

        this.dispatchLoad = ::this.dispatchLoad;
        this.doScroll = ::this.doScroll;
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        statusOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        transPath: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                field: PropTypes.string.isRequired,
                props: PropTypes.object
            }).isRequired
        ),
        dataTransformer: PropTypes.func.isRequired,
        modalComponent: PropTypes.element.isRequired,
    };

    /** Getters */

    get changesProp() {
        return this.props.changes[this.props.type];
    }

    get showModalProp() {
        return this.props.showModal[this.props.type];
    }

    get deliverChangesProp() {
        return this.props.deliverChanges[this.props.type];
    }

    get data() {
        return this.changesProp.requests;
    }

    get isFetching() {
        return this.changesProp.isFetching;
    }

    get paginateInfo() {
        return this.changesProp.info;
    }

    get deliverChanges() {
        return this.deliverChangesProp.isFetching;
    }

    get showModal() {
        return this.showModalProp.show;
    }

    get row() {
        return this.showModalProp.row;
    }

    get disabled() {
        return this.isFetching || this.deliverChanges;
    }

    /** Header */

    tabHeader() {
        let dataSize = this.paginateInfo.data_size;
        let page = this.paginateInfo.page;
        let currentRequestsNumber = Math.min(this.state.sizePerPage, dataSize);
        if (page * this.state.sizePerPage > dataSize) {
            currentRequestsNumber = dataSize % this.state.sizePerPage;
        }
        let header = this.isFetching ?
            'Идёт загрузка...' :
            'Показано ' + currentRequestsNumber + ' из ' + dataSize + ' заявок';
        return (
            <div className="request-header">
                <h3>
                    {header}
                    <Button
                        className='refresh_btn btn-info pull-right'
                        bsSize="large"
                        disabled={this.disabled}
                        onClick={() => {
                            this.props.dispatch(loadChanges(this.props.type, this.state.filters, page, this.state.sizePerPage));
                        }}
                    >
                        <IoRefreshCircleOutline glyph="refresh"/>
                    </Button>
                </h3>
            </div>
        );
    }

    /** Filtering **/

    createFilterTextForm(field) {
        return (
            <div>
                <Container>
                    <Col md={1} sm={3} xs={3}>
                        <FormLabel>{trans('requests_filters.' + field)}:</FormLabel>
                    </Col>
                    <Col md={5} sm={9} xs={9}>
                        <FormControl
                            type={"text"}
                            value={this.state.filterInputs[field]}
                            placeholder={trans('requests_filters.' + field)}
                            onChange={
                                (e) => {
                                    e.persist();
                                    this.setState((state) => ({
                                        filterInputs: {
                                            ...state.filterInputs,
                                            [field]: e.target.value
                                        }
                                    }));
                                }
                            }
                        />
                    </Col>
                </Container>
            </div>);
    }

    createFilterSelectForm(field, statusOptions) {
        return (
            <div>
                <Container>
                    <Col md={2} sm={3} xs={3}>
                        <FormLabel>{trans('requests_filters.' + field)}:</FormLabel>{' '}
                    </Col>
                    <Col md={5} sm={9} xs={9}>
                        <Form.Select
                            componentClass={"select"}
                            value={this.state.filterInputs[field] || 'placeholder'}
                            onChange={
                                (e) => {
                                    e.persist();
                                    let value = e.target.value === 'placeholder' ? null : e.target.value;
                                    this.setState((state) => ({
                                        filterInputs: {
                                            ...state.filterInputs,
                                            [field]: value
                                        }
                                    }));
                                }
                            }
                        >
                            <option value={'placeholder'}>Любой</option>
                            {statusOptions}
                        </Form.Select>
                    </Col>
                </Container>
            </div>
        );
    }

    static renderStatusOptions(statuses) {
        if (statuses instanceof Array) {
            return statuses.map((status) => {
                return (<option key={status} value={status}>{trans('document_changes_status.' + status)}</option>);
            });
        }
        return null;
    }

    getStatusOptions() {
        let defaultOptions = [
            DocumentChangeStatus.APPROVED_BY_OPERATOR,
            DocumentChangeStatus.REJECTED_BY_OPERATOR,
            DocumentChangeStatus.PROCESSED_BY_ABS,
            DocumentChangeStatus.REJECTED_BY_ABS,
            DocumentChangeStatus.PROCESSING_BY_OPERATOR,
        ];
        return RequestTab.renderStatusOptions(defaultOptions.concat(this.props.statusOptions));
    }

    createFilterDateForm(field) {
        return (
            <div>
                <Datetime dateFormat="DD.MM.YYYY"
                          timeFormat={false}
                          locale={"ru"}
                          value={this.state.filterInputs[field]}
                          closeOnSelect={true}
                          inputProps={{'placeholder': trans('requests_filters.' + field)}}
                          onChange={(e) => {
                              let date = formatDateInput(e);
                              this.setState((state) => ({
                                  filterInputs: {
                                      ...state.filterInputs,
                                      [field]: date
                                  }
                              }));
                          }}
                />
            </div>
        )
    }

    applyFilters() {
        this.setFilters(
            this.state.filterInputs,
            () => {
                this.dispatchLoad(true);
            });
    }

    setFilters(filterObject, callback = () => {
    }) {
        this.setState({filters: filterObject}, callback);
    }

    clearFilters(callback = () => {
    }) {
        this.setState({
            filters: {},
            filterInputs: {},
        }, callback);
    }

    filterHeader() {
        return (<div className="filter-header">
            <Form inline
                  ref={"filters-" + this.props.type}
                  onSubmit={event => {
                      event.preventDefault();
                      this.applyFilters();
                  }}>
                <Container>
                    <Col md={8} sm={12} xs={12}>
                        <FormGroup controlId={"filters1"}>
                            {this.createFilterTextForm('CLIENT')}
                            {this.createFilterTextForm('FIO')}
                            {this.createFilterSelectForm('status', this.getStatusOptions())}
                        </FormGroup>
                    </Col>
                    <Col md={5} sm={6} xs={12}>
                        <FormGroup controlId={"filters2"}>
                            <FormLabel>Даты заявки:</FormLabel>
                            {this.createFilterDateForm('afterDate')}
                            {this.createFilterDateForm('untilDate')}
                        </FormGroup>
                    </Col>
                    <Col md={3} sm={6} xs={12}>
                        <Button type="submit" className="btn-primary"
                                disabled={this.isFetching || this.deliverChanges}>Применить
                            фильтры</Button>
                        <Button
                            disabled={this.isFetching || this.deliverChanges}
                            onClick={() => {
                                findDOMNode(this.filterRef).reset();
                                let callback = () => {
                                };
                                if (Object.keys(this.state.filters).length !== 0) {
                                    callback = () => {
                                        this.props.dispatch(loadChanges(this.props.type))
                                    };
                                }
                                this.clearFilters(callback);
                            }}>Сбросить фильтры</Button>
                    </Col>
                </Container>
            </Form>
        </div>);
    }

    /** Table */

    getHeaderColumn(field, props) {
        return {
            dataField: field,
            key: field,
            ...props,
            headerAlign: 'center',
            text: trans(this.props.transPath + field)
        };
    }

    getTableColumns() {
        return this.props.columns.map(item => {
            return this.getHeaderColumn(item.field, item.props);
        })
    }

    static isExpandableRow(row) {
        return row.status === DocumentChangeStatus.REJECTED_BY_ABS;
    }

    expandComponentType(row) {
        return (<div className="document-error-row">
            <Container>
                <Col md={9}>
                    <div className="reason-text">{row.reason}</div>
                </Col>
                <Col md={2}>
                    <Button className="btn btn-info"
                            onClick={() => {
                                this.props.dispatch(showChangeHandleModal(this.props.type, row))
                            }}>
                        Внести изменения
                    </Button>
                </Col>
            </Container>
        </div>);
    }

    onPageChange(page) {
        console.log(this.refs);
        console.log(this.props.type);
        let oldPageSize = this.refs['requests-table-' + this.props.type].paginationContext.currSizePerPage;
        let isActualPageChange = oldPageSize === this.state.sizePerPage;

        if (isActualPageChange) {
            this.props.dispatch(loadChanges(this.props.type, this.state.filters, page, this.state.sizePerPage));
        }
    }

    onSizePerPageListChange(sizePerPage) {
        this.setState({
            sizePerPage: sizePerPage,
        }, () => {
            this.dispatchLoad(true);
        })
    }

    onSortChange(sortName, order) {
        if (this.state.sortOrder === 'asc') {
            sortName = '';
            order = '';
        }
        this.setState({
            sortOrder: order,
            sortName: sortName,
        }, () => {
            this.dispatchLoad();
        });
    }

    getTableProps() {
        let id = 0;
        let data = [];
        if (!this.isFetching) {
            data = this.data;
        }
        data = data.map(this.props.dataTransformer);

        let perPageOptions = [5, 10, 15].map(num => ({text: num.toString(10) + ' записей', value: num}));
        perPageOptions.push({text: 'Все записи', value: this.paginateInfo.data_size});

        const pagination = paginationFactory({
            page: parseInt(this.paginateInfo.page, 10),
            sizePerPage: this.state.sizePerPage,
            sizePerPageList: perPageOptions,
            onPageChange: this.onPageChange,
            onSizePerPageChange: this.onSizePerPageListChange,
            totalSize: this.paginateInfo.data_size
        });


        return {
            data: data.map(
                x => Object.assign(x, {
                    '#': (this.paginateInfo.page - 1) * 10 + ++id,
                })
            ),
            options: {
                onRowClick: (row => {
                    console.log(row);
                    if (!(this.disabled) && row.status !== DocumentChangeStatus.REJECTED_BY_ABS) {
                        this.props.dispatch(showChangeHandleModal(this.props.type, row));
                    }
                }),
                rowEvents: {
                    onClick: (e, row, rowIndex) => {
                        console.log(row);
                        if (!(this.disabled) && row.status !== DocumentChangeStatus.REJECTED_BY_ABS) {
                            this.props.dispatch(showChangeHandleModal(this.props.type, row));
                        }
                    }
                },
                pagination: pagination,
                paginationPosition: 'top',
                paginationSize: 3,
                page: parseInt(this.paginateInfo.page, 10),
                onlyOneExpanding: true,
                noDataText: this.isFetching ? 'Идёт загрузка заявок...' : 'Заявок не найдено',
                sortName: this.state.sortName,
                sortOrder: this.state.sortOrder,
                onSortChange: (name, order) => this.onSortChange(name, order),
            }
        }
    }

    static trClassFormat(rowData) {
        switch (rowData.status) {
            case DocumentChangeStatus.REJECTED_BY_OPERATOR:
            case DocumentChangeStatus.REJECTED_BY_OPERATOR_ABS_PROGRAM:
            case DocumentChangeStatus.REJECTED_BY_OPERATOR_UNREADABLE:
            case DocumentChangeStatus.REJECTED_BY_ABS:
                return 'tr-rejected-by-operator';
            case DocumentChangeStatus.APPROVED_BY_OPERATOR:
                return 'tr-approved-by-operator';
            case DocumentChangeStatus.PROCESSED_BY_ABS:
                return 'tr-processed-by-abs';
            case DocumentChangeStatus.PROCESSING_BY_OPERATOR:
                return 'tr-processing-by-operator';
            default:
                return '';
        }
    }

    /** Update changes */

    doScroll() {
        if (this.props.scrollToTop) {
            window.scrollTo(0, 0);
        }
    }

    componentDidMount() {
        this.dispatchLoad();
        this.filterRef = this.refs['filters-' + this.props.type];
    }

    componentDidUpdate(prevProps) {
        this.doScroll();
        let prevDeliverChanges = prevProps.deliverChanges[this.props.type].isFetching;
        if (this.deliverChanges !== prevDeliverChanges && !this.deliverChanges) {
            this.dispatchLoad();
        }
    }

    dispatchLoad(resetPage = false) {
        let params = {
            ...this.state.filters,
            sort_by: this.state.sortName,
            sort_order: this.state.sortOrder,
        };
        this.props.dispatch(loadChanges(this.props.type, params, resetPage ? null : this.paginateInfo.page, this.state.sizePerPage));
    }


    render() {
        let {data, options} = this.getTableProps();

        const expandRow = {
            renderer: (row) => this.expandComponentType(row),
        };


        return (
            <div>
                <ModalHeader>{trans('document_changes.' + this.props.type + 'Header')}</ModalHeader>
                {this.filterHeader()}
                {this.tabHeader()}
                <br/>
                {
                    this.showModal &&
                    this.props.modalComponent
                }
                <BootstrapTable
                    data={data}
                                remote={true}
                                fetchInfo={{dataTotalSize: this.paginateInfo.data_size}}
                                hover={true}
                                keyField={'#'}
                                ref={'requests-table-' + this.props.type}
                                classes={'requests-table' + (this.disabled ? '-blocked' : '')}
                                expandableRow={RequestTab.isExpandableRow}
                                expandComponent={(row) => this.expandComponentType(row)}
                                rowClasses={RequestTab.trClassFormat}
                    expandRow = {expandRow}

                                columns={this.getTableColumns()}
                    onTableChange ={function(type, newState){
                        console.log(type);
                        console.log(newState);
                    }}
                    {...options}

                >
                </BootstrapTable>
            </div>
        );
    }
}

export function mapStateToProps(state) {
    return {
        scrollToTop: state.panels.length !== 0,
        changes: state.updateChanges,
        showModal: state.showChangeHandleModal,
        deliverChanges: state.deliverChanges,
    }
}

export default connect(mapStateToProps)(RequestTab);
