/**
 * Created by anton on 5/5/17.
 */

import React from 'react';
import {
    FormControl,
    FormGroup,
    FormLabel
} from 'react-bootstrap';

export default ({id, label, ...props}) => (
    <FormGroup controlId={id}>
        <FormLabel>{label}</FormLabel>
        <FormControl {...props}/>
    </FormGroup>
);