import React from "react";
import {connect} from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle} from "react-bootstrap";
import {revokeExtraAgreement} from "../actions/extra_agreements";

class RevokeExtraAgreement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CLIENT: props.CLIENT,
            extra_agreement: props.extra_agreement,
            showModal: false,
            onClientDataChanged: props.onClientDataChanged,
        };
        this.getModal = ::this.getModal;
        this.close = ::this.close;
        this.handleRevokeBtnClick = ::this.handleRevokeBtnClick;
    }

    close() {
        this.setState((prevState, props) => {
            return {
                ...prevState,
                showModal: false
            };
        });
    }

    handleRevokeBtnClick() {
        this.props.dispatch(revokeExtraAgreement({
            extra_agreement_id: this.state.extra_agreement.id,
            CLIENT: this.state.CLIENT,
        }, this.state.onClientDataChanged));
        this.close();
    }

    getModal() {
        return (
            <Modal show={true} bsSize={'lg'} onHide={this.close} dialogClassName={'reject-passport-modal'}>
                <ModalHeader closeButton>
                    <ModalTitle>Отозвать дополнительное соглашение</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    Вы уверены, что хотите отозвать дополнительное соглашение "{this.state.extra_agreement.name}"?
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.close}>Отмена</Button>
                    <Button className="btn btn-danger" onClick={this.handleRevokeBtnClick}>Да</Button>
                </ModalFooter>
            </Modal>
        );
    }

    render() {
        const modal = this;

        return <div>
            <a href="#" target='_blank'
                onClick={(e) =>
                {
                    e.preventDefault();
                    modal.setState((prevState, props) => {
                    return {
                        ...prevState,
                        showModal: true
                    };
                })}}>Отозвать</a>

            {this.state.showModal ? this.getModal() : <p/>}
        </div>
            ;
    }
}

function mapStateToProps(state) {
    return {
        row: {},
    };
}

export default connect(mapStateToProps)(RevokeExtraAgreement);