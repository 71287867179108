/**
 * Created by anton on 5/4/17.
 */

import React from 'react';
import {connect} from 'react-redux';
import * as person from '../actions/bank_person';
import {Modal, ModalHeader, ModalTitle, ModalBody, ModalFooter, Button} from 'react-bootstrap';
import {FormGroup, FormLabel, FormControl, Form} from 'react-bootstrap';

class AuthModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal
        };

        this.close = ::this.close;
        this.authorize = ::this.authorize;
    }

    close() {
        this.props.onCloseClick()
    }

    authorize(e) {
        e.preventDefault();
        this.props.dispatch(person.doAuthorize({
            login: this.login,
            password: this.password
        }));
        this.close();
    }

    render() {
        return (
            <Modal show={true} onHide={this.close}>
                <ModalHeader closeButton>
                    <ModalTitle>Введите авторизационные данные</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={this.authorize}>
                        <FormGroup>
                            <FormLabel>Логин</FormLabel>
                            <FormControl
                                type="text"
                                required
                                autoFocus
                                onChange={(e) => {
                                    this.login = e.target.value;
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Пароль</FormLabel>
                            <FormControl
                                type="password"
                                required
                                onChange={(e) => {
                                    this.password = e.target.value
                                }}
                            />
                        </FormGroup>
                        <Button type="submit" bsStyle="primary">Авторизоваться</Button>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.close}>Закрыть</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapStateToProps() {
    return {};
}

export default connect(mapStateToProps)(AuthModal);