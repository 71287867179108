/**
 * Created by gleb on 5/10/17.
 */

import * as actions from '../actions/panels';

export function panels(state = [], action) {
    switch (action.type) {
        case actions.OK_PANEL:
        case actions.INFO_PANEL:
        case actions.ERROR_PANEL: {
            const panel = createPanel(state, action);
            return [panel, ...state];
        }
        case actions.REMOVE_PANEL: {
            return [
                ...state.slice(0, action.payload),
                ...state.slice(action.payload + 1)
            ];
        }
        case actions.REMOVE_ALL_PANELS: {
            return [];
        }
        default:
            return state;
    }
}

function createPanel(state = {
    panelHeader: '',
    panelBody: '',
    panelType: '',
}, action) {
    switch (action.type) {
        case actions.ERROR_PANEL:
        case actions.OK_PANEL:
        case actions.INFO_PANEL:
            return action.payload;

        default:
            return state;
    }
}