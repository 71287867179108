import React from "react";
import Client from "./Client";
import {connect} from "react-redux";
import {fetchClients} from "../actions/clients";
import {RotatingLines} from "react-loader-spinner";
import {BANK_EMPLOYEE} from "../actions/bank_person";


class LoadingClient extends React.Component {

    constructor(props) {
        super(props);
        this.navigate = props.navigate;

        this.isBankEmployee = false;
        const userData = localStorage.getItem('user_data');
        if (userData) {
            const user = JSON.parse(userData);
            if (user && user.role) {
                this.isBankEmployee = user.role === BANK_EMPLOYEE
            }
        }
    }

    render() {

        let self = this;

        if(this.props.data === undefined || this.props.data.client === undefined){
            return <RotatingLines
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                width="192"
                visible={true}
            />
        }
        else {
            return (
                    <Client isBankEmployee={this.isBankEmployee}
                            onClientDataChanged={() => self.loadClientData()}
                            clientData={this.props.data}
                    />
            );
        }
    }

    componentDidMount() {
        if (this.props.data === undefined || this.props.data.client === undefined) {
            this.loadClientData();
        }
    }

    loadClientData(){
        const cftClientId = window.location.pathname.split('/').at(-1);// temporary, replace with react hook useParams
        this.props.dispatch(fetchClients({
            'CLIENT': cftClientId
        },'',this.navigate));
    }
}

export default connect(state => {
    return {
        data: state.clientsByQuery.items
    }
})(LoadingClient);