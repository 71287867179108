/**
 * Created by anton on 5/11/17.
 */

import {connect} from 'react-redux';
import PropTypes from 'prop-types';

const AuthContainer = (props) => {
    return props.isAuthorized
        ? props.component
        : props.login
};

AuthContainer.propTypes = {
    component: PropTypes.element.isRequired,
    login: PropTypes.element.isRequired
};

export default connect(state => {
    return {
        isAuthorized: !!state.authorizedPerson.person
    };
})(AuthContainer);

