import React, {Component} from "react";
import {fetchClients, SOURCE_SEARCH_BOX, SOURCE_SEARCH_HISTORY} from "../actions/clients";
import {connect} from "react-redux";
import {Button, Form, FormControl, ModalHeader} from "react-bootstrap";
import {errorPanel} from "../actions/panels";
import {trans, validateTextField} from "../utils/index";
import Datetime from "react-datetime";


import "./styles/clients.scss";
import BootstrapTable from "react-bootstrap-table-next";

import recentSearchStorage from "../utils/SearchHistoryStorage";
import "./styles/react-datetime.css";

require('moment');
require('moment/locale/ru');

class Clients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: {},
            isEmpty: {
                'CLIENT': true,
                'FIO': true,
                'MOBILE': true,
                'EMAIL': true,
                'CONTRACT_NUMBER': true,
                'ACC_NUMBER': true,
                'BIRTH_DATE': true,
            },
            errors: {
                'CLIENT': "",
                'FIO': "",
                'MOBILE': "",
                'EMAIL': "",
                'CONTRACT_NUMBER': "",
                'ACC_NUMBER': "",
                'BIRTH_DATE': "",
            }
        };
        this.loadClients = ::this.loadClients;
        this.getIsFetchingState = ::this.getIsFetchingState;
        this.__textChangeHandler = ::this.__textChangeHandler;
        this.getAllEmpty = ::this.getAllEmpty;
        this.__onRecentClick = ::this.__onRecentClick;
        this.navigate = props.navigate;
    }

    loadClients() {
        let hasError = false;

        for (const error in this.state.errors) {
            if (this.state.errors.hasOwnProperty(error)) {
                if (this.state.errors[error] !== '') {
                    hasError = true;
                    this.props.dispatch(errorPanel(this.state.errors[error]));
                }
            }
        }

        if (!hasError) {
            this.props.dispatch(fetchClients(this.state.query, SOURCE_SEARCH_BOX, this.navigate));
        }
    }

    getIsFetchingState() {
        return this.props.clients.isFetching;
    }

    getAllEmpty() {
        let result = true;
        for (const prop in this.state.isEmpty) {
            if (this.state.isEmpty.hasOwnProperty(prop)) {
                result &= this.state.isEmpty[prop];
            }
        }
        return result;
    }


    __textChangeHandler(value, field) {
        const textValue = value.target.value;
        this.setState((previousState, currentProps) => {
            let newState = previousState;
            newState.errors[field] = validateTextField(textValue, field);
            newState.query[field] = textValue;
            newState.isEmpty[field] = textValue === '' || value === null;
            return newState;
        });
    }

    __onRecentClick(e, row) {
        this.props.dispatch(fetchClients({
            'CLIENT': row.CLIENT,
        }, SOURCE_SEARCH_HISTORY, this.navigate));
    }


    __dateTimeChangeHandler(moment, field) {
        this.setState((previousState, currentProps) => {
            let newState = previousState;

            if (typeof moment !== "string") {
                newState.errors[field] = '';
                newState.query[field] = moment.format('YYYY-MM-DD');
            } else if (moment === '') {
                newState.errors[field] = '';
                newState.query[field] = '';
            } else {
                newState.errors[field] = trans(`validation.${field}`);
                newState.query[field] = '';
            }
            newState.isEmpty[field] = moment === '';
            return newState;
        });
    }

    render() {
        return (
            <div className="search-block">
                <ModalHeader>Для поиска информации по клиенту заполните форму</ModalHeader>
                <Form inline
                      onSubmit={(event) => {
                          event.preventDefault();
                          this.loadClients();
                      }}
                >
                    <FormControl type="text" onChange={(value) => this.__textChangeHandler(value, 'CLIENT')}
                                 placeholder="Логин"/>
                    <FormControl type="text" onChange={(value) => this.__textChangeHandler(value, 'FIO')}
                                 placeholder="ФИО"/>
                    <FormControl type="text" onChange={(value) => this.__textChangeHandler(value, 'MOBILE')}
                                 placeholder="Телефон"/>
                    <FormControl type="text" onChange={(value) => this.__textChangeHandler(value, 'EMAIL')}
                                 placeholder="E-mail"/>
                    <FormControl type="text" onChange={(value) => this.__textChangeHandler(value, 'CONTRACT_NUMBER')}
                                 placeholder="Номер кред. договора"/>
                    <FormControl type="text" onChange={(value) => this.__textChangeHandler(value, 'ACC_NUMBER')}
                                 placeholder="ID"/>
                    <Datetime dateFormat="DD.MM.YYYY"
                              timeFormat={false}
                              viewMode='years'
                              utc={true}
                              locale="ru" onChange={(value) => this.__dateTimeChangeHandler(value, 'BIRTH_DATE')}
                              inputProps={{
                                  'placeholder': 'Дата рождения',
                              }}
                    />

                    <Button type="submit"
                            disabled={this.getIsFetchingState() || this.getAllEmpty()}
                    >
                        {this.getIsFetchingState() ? 'Загрузка' : 'Загрузить клиента'}
                    </Button>
                </Form>

                <ModalHeader>История поиска</ModalHeader>

                <BootstrapTable data={this.props.recentSearches}
                                options={{
                                    onRowClick: this.__onRecentClick,
                                }}
                                rowEvents={{
                                    onClick: this.__onRecentClick
                                }}
                                bodyContainerClass='recent-searches'
                                keyField='CLIENT'
                                columns={[
                                    {
                                        dataField: 'FIO',
                                        'text': "ФИО"
                                    },
                                    {
                                        dataField: 'CLIENT',
                                        isKey: true,
                                        'text': "Логин"
                                    },
                                ]}
                >
                </BootstrapTable>
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {
        clients: state.clientsByQuery || {
            isFetching: true,
            items: [],
        },
        recentSearches: state.clientsByQuery.recentSearches || recentSearchStorage.getRecentSearches(),
    }
}

export default connect(mapStateToProps)(Clients);