import React, {Component} from "react";
import {connect} from "react-redux";
import DocumentChangeStatus from "../../constants/DocumentChangeStatus";
import RequestTab from "./RequestTab";
import {trans} from "../../utils";
import AddressChangeHandleModal from "../AddressChangeHandleModal";
import {closeChangeHandleModal} from "../../actions/document_requests";
import DocumentRequestTypes from "../../constants/DocumentRequestTypes";

class AddressChangeTab extends Component {
    constructor(props) {
        super(props);
        this.renderModalComponent = ::this.renderModalComponent;
    }

    static getColumns() {
        return [
            {field: '#', props: {isKey: true, width: '40px'}},
            {field: 'CLIENT', props: {width: '120px'}},
            {field: 'FIO', props: {width: '250px'}},
            {field: 'address_text'},
            {field: 'status_text', props: {width: '150px'}},
            {field: 'created_at', props: {width: '170px', dataSort: true}},
        ];
    }

    static getDataTransformer() {
        return item =>
            Object.assign(
                item,
                {
                    'status_text': trans('document_changes_status.' + item.status),
                });
    }

    renderModalComponent() {
        return (
            <AddressChangeHandleModal
                onCloseClick={() => this.props.dispatch(closeChangeHandleModal(DocumentRequestTypes.ADDRESS_TYPE))}
                row={this.props.row}
            />);
    }

    render() {
        return (
            <RequestTab
                type='address'
                statusOptions={DocumentChangeStatus.CREATED}
                transPath={'address_changes.'}
                columns={AddressChangeTab.getColumns()}
                dataTransformer={AddressChangeTab.getDataTransformer()}
                modalComponent={this.renderModalComponent()}
            />
        );
    }
}

function mapStateToProps(state) {
    let addressModal = state.showChangeHandleModal[DocumentRequestTypes.ADDRESS_TYPE] || {row: {}};

    return {
        row: addressModal.row,
    };
}

export default connect(mapStateToProps)(AddressChangeTab);