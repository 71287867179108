import moment from "moment";
import {getCurrencyDescription, trans} from "./index";

export function formatDateInput(date) {
    if (date instanceof moment) {
        date = date.format('DD.MM.YYYY');
    }
    let regex = /([1-9]|0[1-9]|[12][0-9]|3[01])[./]([1-9]|0[1-9]|1[0-2])[./]\d{4}/g;
    let result = date;
    if (date.match(regex)) {
        let delimiter = date.includes('.') ? '.' : '/';
        let [days, months, year] = date.split(delimiter);
        result = dateFormatter({days: days, months: months - 1, year: year});
    }
    return result;
}

export function dateFormatter(cell, row = null,rowIndex,  formatExtraData = null) {
    if(cell === null || cell === undefined || cell === ''){
        return '';
    }
    return moment(cell).format('DD.MM.YYYY');
}

export function moneyFormatter(cell, row, rowIndex, formatExtraData) {
    return Number(cell).toFixed(2) + " " + getCurrencyDescription(formatExtraData?.currency);
}


export function datetimeFormatter(cell, row,rowIndex,  formatExtraData = null) {
    return moment(cell).format('DD.MM.YYYY HH:mm')
}

export function getDatetimeOrNullFormatter(replaceNull) {
    return function (cell, row,rowIndex,  formatExtraData) {
        if (cell === null) {
            return replaceNull;
        }
        return datetimeFormatter(cell, row,rowIndex,  formatExtraData);
    }
}

export function creditStatusFormatter(status) {
    return trans('creditsStatus.' + status);
}

export function yesNoFormatter(boolVal) {
    return boolVal ? 'Да' : 'Нет'
}

