import React from "react";
import {connect} from "react-redux";
import InsuranceChangeHandleModal from "./InsuranceChangeHandleModal";
import {loadInsuranceCompanies} from "../actions/document_requests";
import {Button} from "react-bootstrap";

class AddInsuranceManually extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            showModal: false
        };
        this.policyTypes = [{id: 'KASKO', name: 'КАСКО'}];
    }

    componentDidMount() {
        this.props.dispatch(loadInsuranceCompanies());
    }

    render() {
        const row = {
            'CLIENT' : this.state.user.client.CLIENT,
            'FIO' : this.state.user.client.FIO,
        };

        return <div>
            <h1>Форма добавления полиса по заявке клиента Банка</h1>

            <Button onClick={() => this.setState((prevState, props) => {
                return {
                    ...prevState,
                    showModal: true
                };
            })}>Создать полис КАСКО</Button>

            {this.state.showModal ? <InsuranceChangeHandleModal
                onCloseClick={() => this.setState((prevState, props) => {
                    return {
                        ...prevState,
                        showModal: false
                    };
                })}
                row={{
                    'FIO': this.state.user.client.FIO,
                    'CLIENT': this.state.user.client.CLIENT,
                    'status': 'CREATED',
                    'insurer_id': this.props.insuranceCompanies.data.companies[0].id,
                    'type' : 'KASKO'
                }}
                companies={this.props.insuranceCompanies.data.companies}
                policyTypes={this.policyTypes}
                isManualAdding = {true}
                possibleCredits = {this.state.user.credits}
            /> : <p/>
            }
        </div>
            ;
    }


}

function mapStateToProps(state) {
    let insuranceCompanies = state.updateInsuranceCompanies || {
        isFetching: true,
        data: {
            companies: [],
        },
    };

    return {
        row: {

        },
        insuranceCompanies: insuranceCompanies,
    };
}

export default connect(mapStateToProps)(AddInsuranceManually);